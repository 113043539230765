
.popup3 {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup3-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup3-content {
  position: relative;
  background: rgb(255, 254, 254);
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  height: 300px;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.success-icon {
  color: green;
  font-size: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid green;
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.error-icon {
  color: red;
  font-size: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid red;
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.success-message {
  color: green;
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.error-message {
  color: red;
  font-size: 1.2rem;
  margin-bottom: 20px;
}



@media (max-width: 768px) {
  .popup3-content {
    width: 70%; 
    padding: 15px;
  }

  .icon-container {
    font-size: 2rem; 
  }

  .popup3-content h3 {
    font-size: 18px; 
  }

  .popup3-content h5 {
    font-size: 14px; 
  }
}

@media (max-width: 480px) {
  .popup3-overlay{
    margin-right: 13px;
  }
  .popup3-content {
    width: 67%; 
    padding: 10px;
  }

  .icon-container {
    font-size: 1.5rem; 
  }

  .popup3-content h3 {
    font-size: 16px; 
  }

  .popup3-content h5 {
    font-size: 12px; 
  }
}


.loginpopup{
  margin-top: 41px;
  padding-top: 10px;
  color:white !important;
  background-color: orange;
  height: 50px;
margin-left: 40px;
margin-right: 40px;
}


.loginErrorpopup{
  margin-top: 41px;
  padding-top: 10px;
  color:white !important;
  background-color: red;
  height: 50px;
margin-left: 40px;
margin-right: 40px;
}