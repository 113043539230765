

.notification-item {
    /* display: flex; */
    align-items: flex-start;
    margin-bottom: 10px;
  }

  @media (max-width:767px) {
    .notification-item {
      display: block;
      /* align-items: flex-start; */
      margin-bottom: 10px;
    }
  }
  
  .notification-image {
    /* height: 50px; */
    width: 36px;
    /* margin-right: 5px; */
    /* border-radius: 100%; */
    /* overflow: hidden; */
  }
  
  .notification-image img {
    height: 100%;
    width: 100%;
    object-fit: contain; /* Change from cover to contain */
    /* border-radius: 100%; */
  }
  
  
  .notification-content {
    flex-grow: 1;
  }
  
  .visit-date {
    font-size: 12px;
    color: #888;
    margin-bottom: 5px;
    display: block;
  }
  
  .user-name {
    font-size: 16px;
    color: #000;
  }
  
  .activity-text {
    font-size: 14px;
    color: #555;
  }

  .notificationcontent{
    margin-right: -4px;
    margin-left: -21px;
  }

  @media (max-width:767px) {
    .notificationcontent{
      margin-right: 52px;
    margin-left: -21px;
    }
  }
  
  
  @media (max-width:767px) {
    .notificationusername{
      margin-left: 34px;
    }
  }