/* Service.css */

@media (max-width: 768px) {
  .mim-HomeSideMenu {
    display: none;
    position: static;
    height: auto;
    overflow: visible;
  }

  .mim-HomeSideMenu.mobile-visible {
    display: block;
    background-color: #f8f9fa;
    width: 100%;
    z-index: 995;
  }

  .navbar-brand-btn {
    display: block;
  }

  .category-list .navbar-brand {
    text-align: center; /* Center the button */
    width: 100%;
    margin-bottom: 10px; /* Optional: add space below the button */
    height: fit-content;
    
  }

  

  

  
}
