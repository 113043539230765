/* Add this CSS to your stylesheet */
.btn-custom {
    /* background-color: transparent; */
    background-color: rgb(245, 240, 240);
    color: black;
    border: none;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .btn-custom:hover,
  .btn-custom:focus,
  .btn-custom:active {
    background-color: rgb(197, 193, 193);
    color: black; /* Adjust if you want to change the text color on hover/click */
  }

  .socialimg{
    height: 50px;
    width: 50px;
    margin-right: 40px;
  }


  @media (max-width: 768px) {
    .client_first_letter {
      height: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 80px; /* Adjust as needed for better alignment */
    background-color: #f0f0f0; /* Adjust as needed for better alignment */
    /* padding-top: 10px; */
    }

    .listing-bottom-list{
      position: absolute;
      top:-3px;
      border-top: 0px;
      left: 10px;
    }

    .listing-bottom-list li{
      padding: 0px;
      margin-right: 3px;
    }

    

    

   

    
    
  }

  @media (max-width: 768px) {
    .listing-bottom-list {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      padding: 0;
      margin: 0;
      list-style-type: none;
      background-color: white;
      padding-top: 10px;
    }
  
    .listing-bottom-list > li {
      flex: 1; 
      text-align: center; 
       padding: 5px;
       margin-right: 3px;
       
    }
  
    .listing-bottom-list .reating-list {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex: 1;
    }
  
    .reating-list > li {
      margin-left: 5px;
      margin-right: 5px;
    }
  
    .btn-guotes {
      margin-top: 8px;
      background-color: orange;
    }
  }
  


  /*Bookmark css*/
 
.btn-bookmark {
  background-color: white;
  color: black;
  /* border: 1px solid black; */
}
.btn-bookmark:hover{
color: rgb(254, 144, 13);
}

/* .btn-bookmark.active {
  background-color: green;
  color: white;
  border: 1px solid green;
} */

.btn-bookmark i {
  color: gray; 
}

.btn-bookmark.icon-active i{
  color: orange;
}

.btn-like {
  background-color:white;
  color: black;
}

.btn-like:hover{
color: rgb(254, 144, 13);
}

.btn-like i {
  color: gray; 
}
.btn-like.icon-active i{
  color: orange;
}



.btn-subscribe {
  background-color: white;
  color: black;
}

.btn-subscribe:hover{
color: rgb(254, 144, 13);
}

.btn-subscribe i {
  color: gray; 
}
.btn-subscribe.icon-active i{
  color: orange;
}

.btnshare{
background-color: white !important ;
color:black !important;
}
.btnshare:hover{
  color: rgb(254, 144, 13) !important;
}





/* .btn-bookmark i.icon-active {
  background-color: green  
}  */


/*Pagination*/

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  cursor: pointer;
}

.pagination button.active {
  background-color: #007bff;
  color: #fff;
}

.pagination button:disabled {
  cursor: not-allowed;
  background-color: #e9ecef;
}



/* Limited text with 7 lines and ellipsis */
.limited-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.full-text {
  white-space: normal;
}
