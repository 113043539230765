.search {
    position: relative;
}

@media (max-width:767px) {
    .search {
        position: relative;
        margin-top: 9px;
    }
}

.dropdownsearchbar {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 435px;  
    overflow-y: auto;
    border: 1px solid #ddd;
    background-color: white;
    z-index: 1000;
    box-shadow: 0px 8px 16px rgba(0,0,0,0.2);
    color: white;
    width: 85%;
    margin-left: 25px;
   
}

.dropdownItemsearchbar {
    padding: 5px;
    border-bottom: 1px solid #ddd;
    padding-top: 10px;
}

.dropdownItemsearchbar:hover {
    background-color: orange;
}

.serchtitle:hover{
    color: white !important;
}

.dropdownItemsearchbar a {
    text-decoration: none;
    color: #007bff;
}


.serchbarTitle{
    font-size: 13px;
    color: gray;
}

.highlighted {
    background-color: orange;
    color: white;
  }
  

  .second-line {
    color: gray;
  }
  