.otp-inputs {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.otp-input {
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 1.5rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin: 0 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: black;
}

.otp-input:focus {
    border-color: #007bff;
    outline: none;
}









.pin-group {
    position: relative;
    margin-bottom: 1rem;
    text-align: left;
}

.pin-group label {
    display: left;
    margin-bottom: 0.5rem;
    font-size: 1rem;
}

.pin-input-group {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 10px;
}

.pin-input {
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 1.5rem;
    border: none;
    border-bottom: 2px solid #ccc;
    background: transparent;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    transition: border-color 0.3s;
}

.pin-input:focus {
    border-bottom-color: #007bff;
}

.pin-input::placeholder {
    color: transparent;
}

.pin-input:not(:last-child)::after {
    content: '|';
    font-size: 1.5rem;
    margin-left: 10px;
    color: #ccc;
}