* {
  margin: 0;
  padding: 0;
  border: none;
}
article,
aside,
audio,
canvas,
command,
datalist,
details,
embed,
figcaption,
figure,
footer,
header,
hgroup,
keygen,
meter,
nav,
output,
progress,
section,
source,
video,
main {
  display: block;
}
mark,
rp,
rt,
ruby,
summary,
time {
  display: inline;
}
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
blockquote,
big,
body,
center,
canvas,
caption,
cite,
code,
command,
datalist,
dd,
del,
details,
dfn,
dl,
div,
dt,
em,
embed,
fieldset,
figcaption,
figure,
font,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
keygen,
label,
legend,
li,
meter,
nav,
object,
ol,
output,
p,
pre,
progress,
q,
s,
samp,
section,
small,
span,
source,
strike,
strong,
sub,
sup,
table,
tbody,
tfoot,
thead,
th,
tr,
tdvideo,
tt,
u,
ul,
var {
  background: transparent;
  border: 0 none;
  font-weight: inherit;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: top;
}
a {
  text-decoration: none;
  outline: none !important;
}
b,
strong {
  font-weight: bold !important;
}
ul,
ol {
  list-style: none;
}
q {
  quotes: none;
}
table,
table td {
  padding: 0;
  border: none;
  border-collapse: collapse;
}
img {
  vertical-align: top;
  max-width: 100%;
}
embed {
  vertical-align: top;
}
input,
textarea {
  font-family: inherit;
}
input,
button {
  outline: none;
  background: transparent;
}
button::-moz-focus-inner {
  border: 0;
}
th {
  text-align: left;
}
textarea {
  outline: none !important;
}
.clearfix::after {
  content: "";
  display: block;
  clear: both;
}
button {
  cursor: pointer;
}
#_atssh {
  display: none !important;
}

@font-face {
  font-family: "Poppins";
  src: url(../fonts/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "PoppinsMedium";
  src: url(../fonts/Poppins-Medium.ttf) format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "PoppinsSemiBold";
  src: url(../fonts/Poppins-SemiBold.ttf) format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../fonts/Poppins-Bold.ttf) format("truetype");
  font-weight: 700;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  font-family: "Poppins", sans-serif;
}
