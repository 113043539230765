.dropdown1 {
  position: absolute;
  top: 100%; 
  left: 0;
  transform: translateX(-50%);
  background-color: #fff;
  /* border: 1px solid #ccc; */
  /* border-radius: 4px; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 150px;
  font-size: 20px;
}


.dropdown1 ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown1 li {
  padding: 10px;
  /* color: #333; */
  cursor: pointer;
}

/* .dropdown1 li + li {
  border-top: 1px solid #ccc;
} */

.dropdown1 li:hover {
  background-color: #f4f4f4;
}

.notification {
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  padding: 20px;
  z-index: 999;
 

  
}

.notification1 {
  position: absolute;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 999;
  left: -132px;
  width: 329px;

  
}



.notificationheader{
    display: flex;
    justify-content: space-between;
}

.notificationheading{
    text-align: left;
}

.notifyheader{
    margin-right: 10px;
    text-align: left;
}
.notifyinfo{
    text-align: left;
}



@media (max-width:767px) {
  .notification1{
    position: fixed;
    top: 94px;
    left: 0;
    width: 100vw;
    min-height: 100vh; /* Ensures minimum height of the mobile viewport */
    max-height: calc(100vh - 94px); /* Sets max height to fit within the viewport */
    z-index: 10000;
    border-radius: 0;
    padding: 20px;
    overflow-y: auto; /* Enables scrolling for overflow */
    box-sizing: border-box; /* Ensures padding doesn’t affect the width */
  }
  .notificationheader{
    font-size: 18px;
  }
  
  
}

.login{
  display: flex;
  align-items: center;
}
.forgetpassword{
  margin-left: 10px;
}







.country-option {
  display: flex;
  align-items: center;
}

.country-option img {
  margin-right: 8px;
  width: 24px;
  height: 16px;
}

.form-control {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
}

.icon-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.icon_phone, .icon_mail_alt {
  margin-right: 8px;
}

.alert {
  margin-top: 10px;
}

.btn_1 {
  display: block;
  /* width: 100%; */
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
}

.btn_1:hover {
  background-color:#fb830d;
}

/* Ensure the dropdown has a higher z-index */
.select-container {
  position: relative;
  z-index: 10;
}

/* Adjust the form group containing the dropdown */
.form-group {
  margin-bottom: 20px;
}


.notificationdot{
  position: absolute;
  top: 4px;
    right: 1px;
    height: 9px;
    width: 9px;
  background-color: orange;
  border-radius: 50%;
}
@media (max-width:767px) {
  .notificationdot{
    position: absolute;
    top: 11px;
    right: 11px;
    height: 9px;
    width: 9px;
    background-color: orange;
    border-radius: 50%;
  }
}

.notificationdekstoppage{
  display: block;
}
@media (max-width:767px) {
  .notificationpage{
    display: none;
  }
  
}

.notificationmobilepage{
  display: none;
}
@media (max-width:767px) {
  .notificationmobilepage{
    display: block;
  }
  
}