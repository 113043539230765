/*
Author: Peaceinfotech
[Table of contents] 

1. SITE STRUCTURE and TYPOGRAPHY
- 1.1 Typography
- 1.2 Buttons
- 1.3 Structure

2. CONTENT
- 2.1 Home
- 2.2 About
- 2.3 Listing
- 2.4 Detail page
- 2.5 Login/register
- 2.6 Checkout
- 2.7 Account
- 2.8 Map styles
- 2.9 Contacts
- 2.10 404 page
- 2.11 Media Gallery
- 2.12 Faq
- 2.13 Bookings 

3. COMMON
- 3.1 Misc
- 3.2 Accordion
- 3.3 List
- 3.4 Spacing
- 3.5 Block reveal
- 3.6 Cookie bar
- 3.7 Sing In Modal
- 3.8 Datepicker */

/*============================================================================================*/
/* 1.  SITE STRUCTURE and TYPOGRAPHY */
/*============================================================================================*/
/* MAIN NAVIGATION */
/* All styles for screen size over 992px*/
@media only screen and (min-width: 992px) {
  nav#menu.main-menu {
    display: block !important;
  }

  #mm-menu.main-menu {
    display: none !important;
  }

  header .btn_mobile {
    display: none !important;
  }

  .listing-list {
    margin: 0px -5px;
  }

  /* Menu */
  .main-menu {
    position: relative;
    z-index: 9;
    width: auto;
    top: 8px;
    right: 15px;
    float: right;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    color: #fff;
  }

  .main-menu ul,
  .main-menu ul li {
    position: relative;
    display: inline-block;
    margin: 0;
    padding: 0;
  }

  .main-menu ul a,
  .main-menu ul li a {
    position: relative;
    margin: 0;
    padding: 0;
    display: block;
    padding: 5px 10px;
    white-space: nowrap;
  }

  /*First level styles */
  .main-menu>ul>li span>a {
    color: #fff;
    padding: 0 6px 15px 6px;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 500;
  }

  .main-menu>ul>li span:hover>a {
    opacity: 0.7;
  }

  header.sticky .main-menu>ul>li span>a,
  header.header_in .main-menu>ul>li span>a {
    color: #444;
  }

  header.sticky .main-menu>ul>li span:hover>a,
  header.header_in .main-menu>ul>li span:hover>a {
    opacity: 1;
    color: #444;
  }

  /* Submenu*/
  .main-menu ul ul {
    position: absolute;
    z-index: 1;
    visibility: hidden;
    left: 3px;
    top: 93%;
    margin: 0;
    display: block;
    padding: 0;
    background: #fff;
    min-width: 200px;
    -webkit-box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.175);
    -moz-box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.175);
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.175);
    transform: scale(0.4);
    transform-origin: 10% top;
    transition: 0.15s linear, 0.1s opacity cubic-bezier(0.39, 0.575, 0.565, 1), 0.15s transform cubic-bezier(0.1, 1.26, 0.83, 1);
    opacity: 0;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }

  .main-menu ul ul:before {
    bottom: 100%;
    left: 15%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #fff;
    border-width: 7px;
    margin-left: -7px;
  }

  .main-menu ul ul li {
    display: block;
    height: auto;
    padding: 0;
  }

  .main-menu ul ul li a {
    font-size: 13px;
    font-size: 0.8125rem;
    color: #555555;
    border-bottom: 1px solid #ededed;
    display: block;
    padding: 15px 10px;
    line-height: 1;
  }

  .main-menu ul li:hover>ul {
    padding: 0;
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transition-duration: 0s, 0.2s, 0.2s;
    transition-duration: 0s, 0.2s, 0.2s;
  }

  .main-menu ul ul li:first-child a:hover {
    -webkit-border-radius: 3px 3px 0 0;
    -moz-border-radius: 3px 3px 0 0;
    -ms-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
  }

  .main-menu ul ul li:last-child a {
    border-bottom: none;
  }

  .main-menu ul ul li:last-child a:hover {
    -webkit-border-radius: 0 0 3px 3px;
    -moz-border-radius: 0 0 3px 3px;
    -ms-border-radius: 0 0 3px 3px;
    border-radius: 0 0 3px 3px;
  }

  .main-menu ul ul li:hover>a {
    background-color: #f9f9f9;
    color: #004dda;
    padding-left: 15px;
  }
}

/* Submenu 2nd level right */
.main-menu ul ul.second_level_right {
  left: auto;
  right: 3px;
}

.main-menu ul ul.second_level_right:before {
  right: 15%;
  left: auto;
}

/* Submenu 3rd level */
.main-menu ul ul li span a {
  font-weight: normal !important;
}

.main-menu ul ul li span a:hover {
  background-color: #f9f9f9;
  color: #004dda;
  padding-left: 18px;
  opacity: 1;
}

.main-menu ul ul li span a:after {
  font-family: 'ElegantIcons';
  content: "\35";
  float: right;
  font-size: 16px;
  font-size: 1rem;
  margin-top: -2px;
}

.main-menu ul ul ul {
  position: absolute;
  border-top: 0;
  z-index: 1;
  height: auto;
  left: 100%;
  top: 0;
  margin: 0;
  padding: 0;
  background: #fff;
  min-width: 190px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.main-menu ul ul ul.third_level_left {
  right: 100%;
  left: auto;
}

.main-menu ul ul ul li a {
  border-bottom: 1px solid #ededed !important;
}

/* Arrows top 3rd level*/
.main-menu ul ul ul:before {
  border-width: 0;
  margin-left: 0;
}

/* All styles for screen size under 991px */
@media only screen and (max-width: 991px) {
  nav#menu {
    display: none !important;
  }

  .about_img_sec {
    width: 100%;
    height: 20vh;
  }

  .mm-menu {
    background: #fff;
  }

  .main-menu {
    top: 0;
    right: 0;
    float: none;
  }

  ul.mm-listview {
    line-height: 25px;
  }

  ul.mm-listview li a {
    color: #ccc;
    display: block;
  }
}

#menu.fake_menu {
  display: none !important;
  visibility: hidden !important;
}

/*-------- 1.1 Typography --------*/
/* rem reference
10px = 0.625rem
12px = 0.75rem
14px = 0.875rem
16px = 1rem (base)
18px = 1.125rem
20px = 1.25rem
24px = 1.5rem
30px = 1.875rem
32px = 2rem
*/
html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background: #f8f8f8;
  font-size: 0.875rem;
  line-height: 1.6;
  font-family: "Poppins", Helvetica, sans-serif;
  color: #555555;
  height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
}

p {
  margin-bottom: 30px;
}

strong {
  font-weight: 600;
}

label {
  font-weight: 500;
  margin-bottom: 3px;
}

hr {
  margin: 25px 0 25px 0;
  border-color: #dddddd;
}

ul,
ol {
  list-style: none;
  margin: 0 0 25px 0;
  padding: 0;
}

.main_title {
  text-align: center;
  margin-bottom: 45px;
  position: relative;
}

h1.main_title_in {
  font-size: 26px;
  font-size: 1.625rem;
  margin: 0 0 25px 0;
}

@media (max-width: 575px) {
  h1.main_title_in {
    font-size: 24px;
    font-size: 1.5rem;
  }
}

.main_title span {
  content: "";
  display: block;
  width: 60px;
  height: 3px;
  margin: auto;
  margin-bottom: 20px;
  opacity: 1;
  background-color: #999;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
}

.main_title.animated span {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.main_title_2 {
  text-align: center;
  margin-bottom: 45px;
  position: relative;
}

.main_title_2 span {
  width: 120px;
  height: 2px;
  background-color: #e1e1e1;
  display: block;
  margin: auto;
}

.main_title_2 span em {
  width: 60px;
  height: 2px;
  background-color: orange;
  display: block;
  margin: auto;
}

@media (max-width: 767px) {
  .main_title_2 {
    margin-bottom: 10px;
  }
}

.main_title_2 h2 {
  margin: 20px 0 0 0;
  font-size: 28px;
}

@media (max-width: 767px) {
  .main_title_2 h2 {
    font-size: 26px;
    font-size: 1.625rem;
  }
}

.main_title_2 p {
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.4;
}

@media (max-width: 767px) {
  .main_title_2 p {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.main_title_3 {
  position: relative;
  border-left: 2px solid #e1e1e1;
  padding-left: 15px;
}

@media (max-width: 767px) {
  .main_title_3 {
    border-left: none;
    padding-left: 0;
  }
}

.main_title_3 span {
  width: 2px;
  height: 30px;
  background-color: #004dda;
  position: absolute;
  left: -2px;
  top: 0;
  display: block;
}

@media (max-width: 767px) {
  .main_title_3 span {
    display: none;
  }
}

.main_title_3 h2,
.main_title_3 h3 {
  font-size: 26px;
  font-size: 1.625rem;
  margin: 0;
  display: inline-block;
}

@media (max-width: 767px) {

  .main_title_3 h2,
  .main_title_3 h3 {
    font-size: 21px;
    font-size: 1.3125rem;
  }
}

.main_title_3 p {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.4;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .main_title_3 p {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

.main_title_3 a {
  display: inline-block;
  right: 0;
  bottom: 0;
  position: absolute;
}

@media (max-width: 767px) {
  .main_title_3 a {
    top: -10px;
  }
}

.main_title_3 a:after {
  font-family: 'ElegantIcons';
  content: "\24";
  font-size: 21px;
  font-size: 1.3125rem;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
  top: 5px;
  margin-left: 4px;
}

/*General links color*/
a {
  color: #fe900d;
  text-decoration: none;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  outline: none;
}

a:hover,
a:focus {
  color: #fff;
  text-decoration: none;
  outline: none;
}

/*-------- 1.2 Buttons --------*/
a.btn_1,
.btn_1 {
  border: none;
  color: #fff;
  background: #fb830d;
  outline: none;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  padding: 15px 30px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  line-height: 1;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  font-family: "PoppinsSemiBold";
  font-size: 16px;
}

/* a.btn_1:hover,
.btn_1:hover {
  background-color: #ffc107;
  color: #222222 !important;
} */

a.btn_1.full-width,
.btn_1.full-width {
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
}

a.btn_1.full-width.purchase,
.btn_1.full-width.purchase {
  background-color: #004dda;
}

a.btn_1.full-width.purchase:hover,
.btn_1.full-width.purchase:hover {
  background-color: #32a067;
  color: white !important;
}

a.btn_1.full-width.wishlist,
.btn_1.full-width.wishlist {
  border-color: #555;
  color: #555;
}

a.btn_1.full-width.wishlist:hover,
.btn_1.full-width.wishlist:hover {
  color: white !important;
  background-color: #555;
  border-color: #555;
}

a.btn_1.medium,
.btn_1.medium {
  font-size: 16px;
  font-size: 1rem;
  padding: 18px 40px;
}

a.btn_1.rounded,
.btn_1.rounded {
  -webkit-border-radius: 25px !important;
  -moz-border-radius: 25px !important;
  -ms-border-radius: 25px !important;
  border-radius: 25px !important;
}

a.btn_1.outline,
.btn_1.outline {
  border: 2px solid #004dda;
  color: #004dda;
  padding: 11px 40px;
  background: none;
}

a.btn_1.outline:hover,
.btn_1.outline:hover {
  background: #0054a6;
  color: #fff;
  border-color: #0054a6;
}

a.btn_1.outline:focus,
.btn_1.outline:focus {
  outline: none;
}

a.btn_add,
.btn_add {
  border: none;
  color: #fff;
  background: #E55923;
  outline: none;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  color: white !important;
  font-weight: 500;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  line-height: 1 !important;
  padding: 10px 15px;
  position: relative;
  top: 2px;
}

/* @media (max-width: 991px) {
  a.btn_add,
  .btn_add {
    display: none;
  }
} */
a.btn_add:hover,
.btn_add:hover {
  background-color: #ff0000;
  color: #fff !important;
  opacity: 1 !important;
}

.closebt {
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  position: absolute;
  font-size: 24px;
  font-size: 1.5rem;
  top: 10px;
  right: 15px;
  color: #fff;
  opacity: 0.6;
  width: 30px;
  height: 30px;
}

.closebt:hover {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  opacity: 1;
}

.btn_home_align {
  text-align: right;
}

@media (max-width: 767px) {
  .btn_home_align {
    text-align: center;
    margin-bottom: 15px;
  }
}

a.btn_map {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  padding: 8px 12px 8px 10px;
  line-height: 1;
  margin-bottom: 5px;
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.08);
  font-weight: 600;
  font-size: 12px;
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.5);
}

a.btn_map:hover {
  color: #004dda;
}

a.btn_map:before {
  font-family: 'ElegantIcons';
  content: "\e081";
  display: inline-block;
  margin-right: 5px;
}

a.btn_filt,
a.btn_filt_map {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  padding: 8px 12px 8px 10px;
  line-height: 1;
  margin-bottom: 5px;
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.08);
  font-weight: 600;
  font-size: 12px;
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.5);
  position: relative;
}

@media (max-width: 767px) {

  a.btn_filt,
  a.btn_filt_map {
    text-indent: -999px;
    display: block;
    width: 32px;
    height: 32px;
    padding: 0;
  }
}

a.btn_filt:hover,
a.btn_filt_map:hover {
  color: #004dda;
}

a.btn_filt:before,
a.btn_filt_map:before {
  font-family: 'ElegantIcons';
  content: "\67";
  display: inline-block;
  margin-right: 5px;
  font-size: 13px;
  font-size: 0.8125rem;
}

@media (max-width: 767px) {

  a.btn_filt:before,
  a.btn_filt_map:before {
    text-indent: 1px;
    position: absolute;
    left: 3px;
    top: 4px;
    font-size: 21px;
    font-size: 1.3125rem;
    font-weight: normal;
  }
}

a.btn_map_in,
.btn_map_in {
  border: none;
  color: #fff;
  background: #004dda;
  outline: none;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  padding: 15px 30px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
  display: inline-block;
  line-height: 1;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

a.btn_map_in:hover,
.btn_map_in:hover {
  background: #0054a6;
  color: #fff;
}

/*-------- 1.3 Structure --------*/
/* Header */
header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  padding: 20px 30px;
  z-index: 99999999;
  border-bottom: 1px solid rgba(255, 255, 255, 0);
}

@media (max-width: 767px) {
  header.header {
    padding: 15px 15px 5px 15px;
  }
}

header.header .logo_sticky {
  display: none;
}

header.header.sticky {
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-bottom: 1px solid #ededed;
  background-color: #fff;
  padding: 15px 20px;
}

@media (max-width: 991px) {
  header.header.sticky {
    padding: 15px 15px 5px 15px;
  }


}

header.header.sticky .logo_normal {
  display: none;
}

header.header.sticky .logo_sticky {
  display: inline-block;
}

header.header_in {
  padding: 10px 0;
  background-color: #fff;
  position: relative;
  border-bottom: 1.5px solid #fb830d;
}

header.header_in.is_sticky {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
}

header.header_in.map_view {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
}

header.header_in ul#top_menu li a {
  color: #444;
}

@media (max-width: 991px) {
  header.header_in {
    padding: 0px 0 5px 0;
  }

  header.header_in ul#top_menu {
    position: absolute;
    right: 30px;
    float: none;
  }

  header.header_in #logo a {
    z-index: 9;
    position: relative;
  }
}

.sub_header_in {
  background-color: #004dda;
  padding: 20px 0;
}

.sub_header_in.sticky_header {
  margin-top: 58px;
}

@media (max-width: 991px) {
  .sub_header_in.sticky_header {
    margin-top: 48px;
  }
}

.sub_header_in h1 {
  color: #fff;
  margin: 0;
  font-size: 26px;
  font-size: 1.625rem;
}

@media (max-width: 575px) {
  .sub_header_in h1 {
    font-size: 18px;
    font-size: 1.125rem;
  }

  ul#top_menu>li {
    margin: 1px 0 0 5px !important
  }
}

ul#top_menu {
  float: right;
  margin: 0 0 0 10px;
  padding: 0;
  font-size: 13px;
  font-size: 0.8125rem;
}

@media (max-width: 991px) {
  ul#top_menu {
    margin: -8px 0 0 10px;
  }

}

ul#top_menu li {
  float: left;
  margin: 0;
  line-height: 1;
  margin-right: 15px;
}

ul#top_menu li:last-child {
  margin-right: 0;
  position: relative;
  top: 0;
}

@media (max-width: 991px) {
  ul#top_menu li {
    margin: 2px 0 0 10px;
  }
}

ul#top_menu li a {
  color: #fff;
}

ul#top_menu li a:hover {
  color: #fff;
  opacity: 0.7;
}

ul#top_menu li a.login,
ul#top_menu li a.wishlist_bt_top {
  display: block;
  width: 22px;
  height: 23px;
  position: relative;
  top: 8px;
  -moz-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
  -webkit-transition: opacity 0.5s ease;
  -ms-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
}

@media (max-width: 991px) {

  ul#top_menu li a.login,
  ul#top_menu li a.wishlist_bt_top {
    top: 7px;
    display: block !important;
  }

  .notification {
    display: block !important;
  }
}

ul#top_menu li a.login:before,
ul#top_menu li a.wishlist_bt_top:before {
  font-family: 'Glyphter';
  font-size: 21px;
  font-size: 1.3125rem;
  text-indent: 0;
  position: absolute;
  left: 0;
  top: 0;
  font-weight: normal;
  line-height: 1;
}

.sticky ul#top_menu li a.login:before,
.sticky ul#top_menu li a.wishlist_bt_top:before {
  color: #444;
}

ul#top_menu li a.wishlist_bt_top {
  text-indent: -9999px;
  overflow: hidden;
}

ul#top_menu li a.wishlist_bt_top:before {
  content: '\0043';
}

ul#top_menu li a.login {
  text-indent: -9999px;
  overflow: hidden;
}

ul#top_menu li a.login:before {
  content: '\0042';
}

header.sticky .hamburger-inner,
header.sticky .hamburger-inner::before,
header.sticky .hamburger-inner::after,
header.header_in .hamburger-inner,
header.header_in .hamburger-inner::before,
header.header_in .hamburger-inner::after {
  background-color: #888;
}

#logo {
  float: left;
}

@media (max-width: 991px) {
  #logo {
    float: none;
    position: absolute;
    top: 21px;
    /* left: -10px; */
    /* width: 100%; */
    text-align: center;
    left:11px;        /*add space left hand side of logo*/
    bottom: -16px;
  }

  #logo img {
    width: auto;
    height: 28px;
    margin: 12px 0 0 0;
  }

  .header_in #logo img {
    margin: -16px;
  }


}

/* Main */
/* main {
  background-color: #f3f3f3;
  position: relative;
  z-index: 1;
  font-family:Arial;
} */
main.pattern {
  background: #f8f8f8 url(../img/pattern_3.svg) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  z-index: 1;
}

/* Footer */
@media (min-width: 576px) {
  .collapse.dont-collapse-sm {
    display: block !important;
    height: auto !important;
    visibility: visible !important;
  }
}

footer {
  border-top: 1px solid #ededed;
  background-color: #fb830d;
  padding: 20px 0px 10px 0px;
  
}

footer h3 {
  margin: 0 0 15px 0;
  font-size: 18px;
  font-size: 1.125rem;
}

@media (max-width: 575px) {
  footer h3 {
    font-size: 13px;
    font-size: 0.8125rem;
    cursor: pointer;
    border-bottom: 1px solid #ededed;
    padding: 12px 0;
    display: block;
  }

  footer h3:after {
    font-family: 'themify';
    content: "\e61a";
    width: 20px;
    display: block;
    float: right;
  }

  footer h3.opened:after {
    content: "\e622";
  }
}

@media (max-width: 575px) {
  footer h3 {
    font-size: 16px;
    font-size: 1rem;
    margin: 0;
  }
}

@media (max-width: 575px) {
  footer hr {
    margin: 5px 0 5px 0;
  }
}

@media (max-width: 575px) {
  footer ul {
    padding-top: 15px;
    text-align: center;
  }
}

footer ul li {
  margin-bottom: 5px;
}

footer ul li a {
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  position: relative;
  color: #555555;
}

footer ul li a:hover {
  color: #004dda;
  opacity: 1;
}

footer ul li a i {
  margin-right: 10px;
  color: #fff;
}

footer ul.links li a:hover {
  -webkit-transform: translate(5px, 0);
  -moz-transform: translate(5px, 0);
  -ms-transform: translate(5px, 0);
  -o-transform: translate(5px, 0);
  transform: translate(5px, 0);
}

footer ul.links li a:hover:after {
  opacity: 1;
  color: #004dda;
}

/* footer ul.links li a:after {
  font-family: 'ElegantIcons';
  content: "\24";
  position: absolute;
  margin-left: 5px;
  top: 1px;
  opacity: 0;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
} */
footer ul.contacts li {
  padding-left: 30px;
  position: relative;
  margin-bottom: 20px;
}

footer ul.contacts li:last-child {
  margin-bottom: 0;
}

footer ul.contacts li i {
  position: absolute;
  top: 0;
  left: 0;
  color: #E55923;
  line-height: 1;
  font-size: 18px;
  font-size: 1.125rem;
}

@media (max-width: 575px) {
  footer #newsletter {
    padding-top: 15px;
  }
}

/* Newsletter footer*/
#newsletter .form-group {
  position: relative;
}

#newsletter .loader {
  position: absolute;
  right: -20px;
  top: 11px;
  color: #444;
  font-size: 12px;
  font-size: 0.75rem;
}

#newsletter h6 {
  margin: 15px 0 15px 0;
}

#newsletter .form-group {
  position: relative;
}

#newsletter input[type='email'] {
  border: 0;
  height: 40px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  padding-left: 15px;
  font-size: 14px;
  font-size: 0.875rem;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.06);
}

#newsletter input[type='email']:focus {
  border: 0;
  box-shadow: none;
}

#newsletter input[type='submit'] {
  position: absolute;
  right: 0;
  color: #fff;
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 600;
  top: 0;
  border: 0;
  padding: 0 12px;
  height: 40px;
  line-height: 42px;
  cursor: pointer;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  -ms-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
  background-color: #E55923;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  outline: none;
}

#newsletter input[type='submit']:hover {
  background-color: #ff0000;
  color: #fff;
}

.follow_us {
  animation-delay: 1.1s;
  -webkit-animation-delay: 1.1s;
  -moz-animation-delay: 1.1s;
}

.follow_us h5 {
  font-size: 16px;
  font-size: 1rem;
  margin-top: 35px;
}

.follow_us ul li {
  display: inline-block;
  margin-right: 5px;
  font-size: 16px;
  /* font-size: 1.25rem; */
}

.follow_us ul li a i {
  color: #fff;
}

.follow_us ul li a i:hover {
  opacity: 1;
  color: #004dda;
}

.follow_us ul {
  float: right;
  margin-top: 5px;
}

ul#footer-selector {
  margin: 0 0 0 0;
  padding: 0;
  list-style: none;
}

@media (max-width: 575px) {
  ul#footer-selector {
    margin-top: 30px;
  }
}

ul#footer-selector li {
  float: left;
  margin-right: 10px;
}

@media (max-width: 575px) {
  ul#footer-selector li:last-child {
    margin-top: 5px;
  }
}

/* Addtional links Footer */
ul#additional_links {
  margin: 0;
  padding: 8px 0 0 0;
  color: #fff;
  font-size: 13px;
  font-size: 0.8125rem;
}

@media (max-width: 991px) {
  ul#additional_links {
    float: none;
    margin-top: 10px;
  }
}

ul#additional_links li {
  display: inline-block;
  margin-right: 15px;
  font-size: 15px;
}

ul#additional_links li:first-child {
  margin-right: 20px;
}

ul#additional_links li:last-child:after {
  content: "";
}

ul#additional_links li span {
  color: #fff;
}

ul#additional_links li a {
  color: #fff;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

ul#additional_links li a:hover {
  opacity: 1;
}

ul#additional_links li:after {
  content: "|";
  font-weight: 300;
  position: relative;
  left: 10px;
}

.styled-select {
  width: 100%;
  overflow: hidden;
  height: 30px;
  line-height: 30px;
  border: none;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  padding-right: 35px;
  position: relative;
}

.styled-select#lang-selector {
  color: #555;
}

.styled-select#lang-selector:after {
  font-family: 'ElegantIcons';
  content: "\e0e2";
  color: #555;
  position: absolute;
  right: 10px;
  top: 0;
}

.styled-select#currency-selector:after {
  font-family: 'ElegantIcons';
  content: "\33";
  color: #555;
  position: absolute;
  right: 10px;
  top: 0;
}

.styled-select select {
  background: transparent;
  width: 110%;
  padding-left: 10px;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  height: 30px;
  color: #555;
  font-size: 12px;
  font-size: 0.75rem;
  margin: 0;
  font-weight: 500;
  -moz-appearance: window;
  -webkit-appearance: none;
  cursor: pointer;
  outline: none;
}

.styled-select select:focus {
  color: #555;
  outline: none;
  box-shadow: none;
}

.styled-select select::-ms-expand {
  display: none;
}

/* Wow on scroll event */
.wow {
  visibility: hidden;
}

/*============================================================================================*/
/* 2.  CONTENT */
/*============================================================================================*/
/*-------- 2.1 Home/front page--------*/
/* Home single hero */
.hero_single {
  width: 100%;
  position: relative;
  text-align: center;
  margin: 0;
  color: #fff;
}

.hero_single .wrapper {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.8);
}

.hero_single .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.hero_single .wrapper h3 {
  color: #fff;
  font-size: 52px;
  font-size: 3.25rem;
  margin: 0;
  text-transform: uppercase;
  font-weight: 700;
}

@media (max-width: 767px) {
  .hero_single .wrapper h3 {
    font-size: 21px;
    font-size: 1.3125rem;
  }
}

@media (max-width: 575px) {
  .hero_single .wrapper h3 {
    font-size: 23px;
    font-size: 1.4375rem;
  }
}

.hero_single .wrapper p {
  font-weight: 300;
  margin: 10px 0 0 0;
  padding: 0 20%;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.4;
}

.hero_single .wrapper p strong {
  font-weight: 600;
}

@media (max-width: 767px) {
  .hero_single .wrapper p {
    padding: 0;
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.hero_single.short {
  height: 600px;
}

.hero_single.version_2 {
  height: 620px;
  background: #222222 url(../img/home_section_1.jpg) center center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.hero_single.version_2 .wrapper {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}

.hero_single.version_2 .wrapper h3 {
  font-size: 42px;
  font-size: 2.625rem;
  text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
}

@media (max-width: 767px) {
  .hero_single.version_2 .wrapper h3 {
    font-size: 26px;
    font-size: 1.625rem;
    margin-top: 30px;
  }
}

@media (max-width: 575px) {
  .hero_single.version_2 .wrapper h3 {
    font-size: 22px;
    font-size: 1.375rem;
  }
}

.hero_single.version_2 .wrapper p {
  font-weight: 400;
  margin: 5px 0 20px 0;
  padding: 0;
  font-size: 21px;
  font-size: 1.3125rem;
  text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
}

.hero_single.version_2 .wrapper p strong {
  font-weight: 600;
}

@media (max-width: 767px) {
  .hero_single.version_2 .wrapper p {
    padding: 0;
    font-size: 14px;
    font-size: 0.875rem;
  }
}

.hero_single.version_3 {
  height: 450px;
  background: #222222 url(../img/home_section_1.jpg) center center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.hero_single.version_3 .wrapper {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}

.hero_single.version_4 {
  height: 620px;
  background: #004dda url(../img/pattern_1.svg) center bottom repeat-x;
}

.hero_single.version_4 .wrapper {
  background: rgba(0, 0, 0, 0.6);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.6), transparent);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), transparent);
}

.hero_single.version_4 .wrapper h3 {
  font-size: 42px;
  font-size: 2.625rem;
  text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
}

@media (max-width: 767px) {
  .hero_single.version_4 .wrapper h3 {
    font-size: 26px;
    font-size: 1.625rem;
    margin-top: 30px;
  }
}

@media (max-width: 575px) {
  .hero_single.version_4 .wrapper h3 {
    font-size: 22px;
    font-size: 1.375rem;
  }
}

.hero_single.version_4 .wrapper p {
  font-weight: 400;
  margin: 5px 0 20px 0;
  padding: 0;
  font-size: 21px;
  font-size: 1.3125rem;
  text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
}

.hero_single.version_4 .wrapper p strong {
  font-weight: 600;
}

@media (max-width: 767px) {
  .hero_single.version_4 .wrapper p {
    padding: 0;
    font-size: 14px;
    font-size: 0.875rem;
  }
}

.hero_single.version_4 .wrapper input[type='submit'] {
  color: #222;
  background-color: #ffc107;
}

@media (max-width: 991px) {
  .hero_single.version_4 .wrapper input[type='submit'] {
    margin: 20px 0 0 0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }
}

.hero_single.version_4 .wrapper input[type='submit']:hover {
  background-color: #32a067;
  color: #fff;
}

.hero_single.version_5 {
  height: 640px;
  background: #004dda url(../img/pattern_2.svg) center center repeat fixed;
}

@media (max-width: 767px) {
  .hero_single.version_5 {
    height: 550px;
  }
}

.hero_single.version_5 .wrapper {
  background: rgba(0, 0, 0, 0.6);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.6), transparent);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), transparent);
  text-align: left;
}

.hero_single.version_5 .wrapper h3 {
  font-size: 36px;
  font-size: 2.25rem;
  text-shadow: none;
}

@media (max-width: 767px) {
  .hero_single.version_5 .wrapper h3 {
    font-size: 26px;
    font-size: 1.625rem;
    margin-top: 20px;
  }
}

@media (max-width: 575px) {
  .hero_single.version_5 .wrapper h3 {
    font-size: 22px;
    font-size: 1.375rem;
  }
}

.hero_single.version_5 .wrapper p {
  font-weight: 400;
  margin: 5px 0 20px 0;
  padding: 0;
  font-size: 21px;
  font-size: 1.3125rem;
  text-shadow: none;
}

.hero_single.version_5 .wrapper p strong {
  font-weight: 600;
}

@media (max-width: 767px) {
  .hero_single.version_5 .wrapper p {
    padding: 0;
    font-size: 14px;
    font-size: 0.875rem;
  }
}

.hero_single.version_5 .wrapper .custom-search-input-2 {
  background: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.hero_single.version_5 .wrapper .form-group {
  margin-bottom: 10px;
}

.hero_single.version_5 .wrapper input[type='submit'] {
  color: #222;
  margin-top: 10px;
  background-color: #ffc107;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

@media (max-width: 991px) {
  .hero_single.version_5 .wrapper input[type='submit'] {
    margin: 20px 0 0 0;
  }
}

.hero_single.version_5 .wrapper input[type='submit']:hover {
  background-color: #32a067;
  color: #fff;
}

#custom-search-input {
  padding: 0;
  width: 600px;
  margin: 20px auto 0;
  position: relative;
}

@media (max-width: 991px) {
  #custom-search-input {
    width: auto;
  }
}

#custom-search-input .search-query {
  width: 100%;
  height: 50px;
  padding-left: 20px;
  border: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  font-size: 16px;
  font-size: 1rem;
  color: #333;
}

#custom-search-input .search-query:focus {
  outline: none;
}

@media (max-width: 575px) {
  #custom-search-input .search-query {
    padding-left: 15px;
  }
}

#custom-search-input input[type='submit'] {
  position: absolute;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  right: -1px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  font-size: 0.875rem;
  top: 0;
  border: 0;
  padding: 0 25px;
  height: 50px;
  cursor: pointer;
  outline: none;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  -ms-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
  background-color: #004dda;
}

#custom-search-input input[type='submit']:hover {
  background-color: #ffc107;
  color: #222;
}

@media (max-width: 575px) {
  #custom-search-input input[type='submit'] {
    text-indent: -999px;
    background: #004dda url(../img/search.svg) no-repeat center center;
  }
}

.custom-search-input-2 {
  background-color: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  margin-top: 10px;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
}

@media (max-width: 991px) {
  .custom-search-input-2 {
    background: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}

.custom-search-input-2 input {
  border: 0;
  height: 50px;
  padding-left: 15px;
  border-right: 1px solid #d2d8dd;
  font-weight: 500;
}

@media (max-width: 991px) {
  .custom-search-input-2 input {
    border: none;
  }
}

.custom-search-input-2 input:focus {
  box-shadow: none;
  border-right: 1px solid #d2d8dd;
}

@media (max-width: 991px) {
  .custom-search-input-2 input:focus {
    border-right: none;
  }
}

.custom-search-input-2 select {
  display: none;
}

.custom-search-input-2 .nice-select .current {
  font-weight: 500;
  color: #6f787f;
}

.custom-search-input-2 .form-group {
  margin: 0;
}

@media (max-width: 991px) {
  .custom-search-input-2 .form-group {
    margin-bottom: 5px;
  }
}

.custom-search-input-2 i {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  font-size: 18px;
  font-size: 1.125rem;
  position: absolute;
  background-color: #fff;
  line-height: 50px;
  top: 1px;
  right: 1px;
  padding-right: 15px;
  display: block;
  width: 20px;
  box-sizing: content-box;
  height: 48px;
  z-index: 9;
  color: #999;
}

@media (max-width: 991px) {
  .custom-search-input-2 i {
    padding-right: 10px;
  }
}

.custom-search-input-2 input[type='submit'] {
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  font-size: 0.875rem;
  border: 0;
  padding: 0 25px;
  height: 50px;
  cursor: pointer;
  outline: none;
  width: 100%;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  -ms-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
  background-color: #004dda;
  margin-right: -1px;
}

@media (max-width: 991px) {
  .custom-search-input-2 input[type='submit'] {
    margin: 20px 0 0 0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }
}

.custom-search-input-2 input[type='submit']:hover {
  background-color: #ffc107;
  color: #222;
}

.custom-search-input-2.map_view {
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.custom-search-input-2.map_view input {
  border: none;
}

.custom-search-input-2.map_view input[type='submit'] {
  margin: 20px 0 0 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

.custom-search-input-2.inner {
  margin-top: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-radius: 30px 30px 30px 30px;
}

.custom-search-input-2.inner input {
  height: 40px;
  padding-left: 15px;
  font-weight: 500;
  border-radius: 30px 0px 0px 30px;
  border: 1px solid #eaeaea;
}


.custom-search-input-2.inner input:focus {
  box-shadow: none;
  border-right: 1px solid #d2d8dd;
}

@media (max-width: 991px) {
  .custom-search-input-2.inner input:focus {
    border-right: none;
  }
}

.custom-search-input-2.inner .nice-select {
  height: 40px;
  line-height: 38px;
}

.custom-search-input-2.inner .nice-select:after {
  right: 15px;
}

.custom-search-input-2.inner i {
  height: 38px;
  line-height: 38px;
  padding-right: 10px;
}

.custom-search-input-2.inner input[type='submit'] {
  position: absolute;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  right: 3px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  font-size: 0.875rem;
  top: 0;
  border: 0;
  height: 40px;
  cursor: pointer;
  outline: none;
  -webkit-border-radius: 0px 30px 30px 0px;
  -moz-border-radius: 0px 30px 30px 0px;
  -ms-border-radius: 0px 30px 30px 0px;
  border-radius: 0px 30px 30px 0px;
  text-indent: -999px;
  background: #fd820c url(../img/search.svg) no-repeat center center;
}

.custom-search-input-2.inner input[type='submit']:hover {
  background-color: #32a067;
}

@media (max-width: 575px) {
  .custom-search-input-2.inner input[type='submit'] {
    text-indent: -999px;
    background: #004dda url(../img/search.svg) no-repeat center center;
  }
}

@media (max-width: 991px) {
  .custom-search-input-2.inner {
    margin: 0 0 20px 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}

.custom-search-input-2.inner-2 {
  margin: 0 0 20px 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background: none;
}

.custom-search-input-2.inner-2 .form-group {
  margin-bottom: 10px;
}

.custom-search-input-2.inner-2 input {
  border: 1px solid #ededed;
}

.custom-search-input-2.inner-2 input[type='submit'] {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  margin-top: 10px;
}

.custom-search-input-2.inner-2 i {
  padding-right: 10px;
  line-height: 48px;
  height: 48px;
  top: 1px;
}

.custom-search-input-2.inner-2 .nice-select {
  border: 1px solid #ededed;
}

ul.counter {
  margin: 30px 0 0 0;
  padding: 0;
  text-align: center;
}

@media (max-width: 767px) {
  ul.counter {
    display: none;
  }
}

ul.counter li {
  padding: 0 10px;
  display: inline-block;
  font-size: 18px;
  font-size: 1.125rem;
  border-right: 1px solid #fff;
  text-align: right;
}

ul.counter li:last-child {
  border-right: none;
  text-align: left;
}

.main_categories {
  position: relative;
  margin-top: -30px;
  margin-bottom: 25px;
  position: relative;
  z-index: 99;
}

.main_categories ul {
  background-color: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 15px 0;
  width: 90%;
  margin: auto;
}

@media (max-width: 767px) {
  .main_categories ul {
    width: 100%;
  }
}

.main_categories ul li {
  display: inline-block;
  width: 20%;
  float: left;
  border-right: 1px solid #d2d8dd;
}

.main_categories ul li:last-child {
  border-right: none;
}

.main_categories ul li a {
  display: block;
}

.main_categories ul li a h3 {
  font-size: 16px;
  font-size: 1rem;
}

@media (max-width: 767px) {
  .main_categories ul li a h3 {
    display: none;
  }
}

.main_categories ul li a i {
  font-size: 26px;
  font-size: 1.625rem;
  display: inline-block;
}

/* Home video background*/
.header-video {
  position: relative;
  overflow: hidden;
  background: #000;
  height: 600px !important;
}

@media (max-width: 991px) {
  .header-video {
    height: 620px !important;
  }
}

#hero_video {
  position: relative;
  background-size: cover;
  color: #fff;
  width: 100%;
  font-size: 16px;
  font-size: 1rem;
  height: 100%;
  z-index: 99;
  text-align: center;
  display: table;
}

#hero_video .wrapper {
  display: table-cell;
  vertical-align: middle;
}

#hero_video .wrapper h3 {
  color: #fff;
  font-size: 42px;
  font-size: 2.625rem;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 0;
  text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
}

@media (max-width: 767px) {
  #hero_video .wrapper h3 {
    font-size: 26px;
    font-size: 1.625rem;
    margin-top: 60px;
  }
}

@media (max-width: 575px) {
  #hero_video .wrapper h3 {
    font-size: 22px;
    font-size: 1.375rem;
  }
}

#hero_video .wrapper p {
  font-weight: 300;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.4;
  text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
}

#hero_video .wrapper p strong {
  font-weight: 600;
}

@media (max-width: 767px) {
  #hero_video .wrapper p {
    padding: 0;
    font-size: 14px;
    font-size: 0.875rem;
  }
}

video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.3;
}

#hero_video iframe {
  height: 100%;
  width: 100%;
}

video {
  width: 100%;
}

.teaser-video {
  width: 100%;
  height: auto;
}

.header-video--media {
  width: 100%;
  height: auto;
}

@media (max-width: 1024px) {
  #hero_video {
    background: #cccccc url(../img/home_section_1.jpg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  #hero_video>div {
    background-color: black;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .header-video {
    background: none;
  }
}

a.grid_item {
  display: block;
  margin-bottom: 5px;
  overflow: hidden;
  height: 250px;
}

a.grid_item img {
  width: 100%;
  height: 100%;
}

@media (max-width: 991px) {
  a.grid_item {
    height: 180px;
  }
}

@media (max-width: 767px) {
  a.grid_item {
    height: 100px;
  }
}

@media (max-width: 575px) {
  a.grid_item {
    /* height: 180px; */
  }
}

a.grid_item .info {
  position: absolute;
  width: 100%;
  z-index: 9;
  display: block;
  padding: 25px 25px 10px 25px;
  color: #fff;
  left: 0;
  bottom: 0;
  background: transparent;
  background: -webkit-linear-gradient(top, transparent, black);
  background: linear-gradient(to bottom, transparent, #000000);
  box-sizing: border-box;
}

@media (max-width: 767px) {
  a.grid_item .info {
    padding: 15px 15px 0 15px;
  }
}

a.grid_item .info small {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 11px;
  font-size: 0.6875rem;
  background-color: #004dda;
  line-height: 1;
  padding: 3px 5px 2px 5px;
}

a.grid_item .info h3 {
  color: #fff;
  font-size: 21px;
  font-size: 1.3125rem;
}

a.grid_item .info p {
  color: #fff;
  margin-bottom: 0;
  font-size: 15px;
  font-size: 0.9375rem;
}

a.grid_item figure {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

@media (max-width: 767px) {
  a.grid_item figure {
    height: 150px;
  }
}

@media (max-width: 575px) {
  a.grid_item figure {
    height: 180px;
  }
}

a.grid_item figure img {
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale(1);
  -moz-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -o-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}

@media (max-width: 767px) {
  a.grid_item figure img {
    width: 100%;
  }
}

a.grid_item:hover figure img {
  -webkit-transform: translate(-50%, -50%) scale(1.1);
  -moz-transform: translate(-50%, -50%) scale(1.1);
  -ms-transform: translate(-50%, -50%) scale(1.1);
  -o-transform: translate(-50%, -50%) scale(1.1);
  transform: translate(-50%, -50%) scale(1.1);
}

a.grid_item.small {
  height: 120px;
}

@media (max-width: 1199px) {
  a.grid_item.small {
    height: 130px;
  }
}

@media (max-width: 991px) {
  a.grid_item.small {
    height: 180px;
  }
}

@media (max-width: 767px) {
  a.grid_item.small {
    height: 150px;
  }
}

@media (max-width: 575px) {
  a.grid_item.small {
    height: 100px;
  }
}

a.grid_item.small .info {
  padding: 35px 15px 0 15px;
}

a.grid_item.small .info h3 {
  font-size: 16px;
  font-size: 1rem;
}

@media (max-width: 767px) {
  a.grid_item.small figure {
    height: 150px !important;
  }
}

@media (max-width: 575px) {
  a.grid_item.small figure {
    height: 100% !important;
  }
}

a.grid_item.small figure img {
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 100%;
}

/*Carousel home page*/
#reccomended {
  margin-top: 40px;
}

@media (max-width: 767px) {
  #reccomended {
    margin-top: 0;
  }
}

#reccomended .item {
  margin: 0 15px;
}

#reccomended .owl-item {
  opacity: 0.5;
  transform: scale(0.85);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(0.85, 0.85);
  transition: all 0.3s ease-in-out 0s;
  overflow: hidden;
}

#reccomended .owl-item.active.center {
  opacity: 1;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
  transform: scale(1);
}

#reccomended .owl-item.active.center .item .title h4,
#reccomended .owl-item.active.center .item .views {
  opacity: 1;
}

.owl-theme .owl-dots {
  margin-top: 25px !important;
  outline: none;
}

@media (max-width: 767px) {
  .owl-theme .owl-dots {
    margin-top: 0 !important;
    margin-bottom: 20px;
  }
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #004dda !important;
  outline: none !important;
}

.owl-theme .owl-dots .owl-dot span {
  width: 13px !important;
  height: 13px !important;
}

.app_icons {
  margin-bottom: 10px;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.app_icons a img {
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.app_icons a img:hover {
  -webkit-transform: translateY(-5px);
  -moz-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  -o-transform: translateY(-5px);
  transform: translateY(-5px);
}

a.box_news {
  position: relative;
  display: block;
  padding-left: 230px;
  color: #555555;
  margin-bottom: 30px;
  min-height: 150px;
}

@media (max-width: 767px) {
  a.box_news {
    min-height: inherit;
    padding-left: 0;
  }
}

a.box_news figure {
  width: 200px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

@media (max-width: 767px) {
  a.box_news figure {
    position: relative;
    width: auto;
  }
}

a.box_news figure img {
  width: 250px;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale(1.1);
  -moz-transform: translate(-50%, -50%) scale(1.1);
  -ms-transform: translate(-50%, -50%) scale(1.1);
  -o-transform: translate(-50%, -50%) scale(1.1);
  transform: translate(-50%, -50%) scale(1.1);
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}

@media (max-width: 767px) {
  a.box_news figure img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}

a.box_news figure figcaption {
  background-color: #ffc107;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
  display: inline-block;
  color: #fff;
  width: 60px;
  height: 60px;
  padding: 15px;
  line-height: 1;
}

a.box_news figure figcaption strong {
  display: block;
  font-size: 21px;
  font-size: 1.3125rem;
}

a.box_news h4 {
  font-size: 18px;
  font-size: 1.125rem;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  a.box_news h4 {
    margin-bottom: 5px;
  }
}

a.box_news:hover img {
  -webkit-transform: translate(-50%, -50%) scale(1);
  -moz-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -o-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}

a.box_news:hover h4 {
  color: #004dda;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

a.box_news ul {
  margin: 0;
  padding: 10px 0 0 0;
}

@media (max-width: 767px) {
  a.box_news ul {
    padding: 0;
  }
}

a.box_news ul li {
  display: inline-block;
  font-weight: 600;
  text-transform: uppercase;
  color: #999;
  font-size: 12px;
  font-size: 0.75rem;
  margin-right: 5px;
}

a.box_news ul li:last-child {
  margin-right: 0;
}

a.box_news ul li:last-child:after {
  content: '';
  margin: 0;
}

a.box_news ul li:after {
  content: '-';
  margin: 0 0 0 10px;
}

a.box_cat_home {
  background-color: #fff;
  display: block;
  padding: 25px 15px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  text-align: center;
  margin-bottom: 25px;
  position: relative;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  color: #999;
}

a.box_cat_home i {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 18px;
  font-size: 1.125rem;
  color: #0054a6;
  opacity: 0;
  -moz-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  -webkit-transition: all 0.6s ease-in-out;
  -ms-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}

a.box_cat_home h3 {
  font-size: 18px;
  font-size: 1.125rem;
  margin-top: 16px;
  color: #fe900d;
  font-weight: 500;
  font-family: "PoppinsMedium";
}

a.box_cat_home:hover {
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  -webkit-transform: translateY(-5px);
  -moz-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  -o-transform: translateY(-5px);
  transform: translateY(-5px);
}

a.box_cat_home:hover i {
  opacity: 1;
}

a.box_cat_home ul {
  margin: 20px -15px -25px -15px;
  padding: 10px;
  border-top: 1px solid #ededed;
  text-align: center;
}

a.box_cat_home ul li {
  display: inline-block;
}

a.box_cat_home ul li strong {
  margin-right: 5px;
  font-weight: 600;
  color: #777;
}

/*-------- 2.2 About --------*/
a.box_feat {
  text-align: center;
  background: #fff;
  padding: 40px 30px 30px 30px;
  display: block;
  -moz-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  -webkit-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
  -webkit-box-shadow: 0px 3px 0px 0px #f0f2f4;
  -moz-box-shadow: 0px 3px 0px 0px #f0f2f4;
  box-shadow: 0px 3px 0px 0px #f0f2f4;
  color: #555555;
  margin-bottom: 30px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  overflow: hidden;
}

@media (max-width: 767px) {
  a.box_feat {
    padding: 20px 20px 0 20px;
  }
}

a.box_feat:hover {
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
  transform: translateY(-10px);
  -webkit-box-shadow: 0px 3px 0px 0px #ebedef;
  -moz-box-shadow: 0px 3px 0px 0px #ebedef;
  box-shadow: 0px 3px 0px 0px #ebedef;
}

a.box_feat i {
  font-size: 70px;
  font-size: 4.375rem;
  color: #32a067;
  margin-bottom: 10px;
}

a.box_feat h3 {
  font-size: 18px;
  font-size: 1.125rem;
}

#carousel {
  margin-top: 40px;
}

@media (max-width: 767px) {
  #carousel {
    margin-bottom: 30px;
  }
}

#carousel .item .title {
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  width: 100%;
  z-index: 9;
}

#carousel .item .title h4 {
  background-color: #0054a6;
  background-color: #0054a6;
  display: inline-block;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  -ms-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
  color: #fff;
  padding: 10px 15px;
  margin: 0;
  font-size: 16px;
  font-size: 1rem;
  opacity: 0;
}

#carousel .item .title h4 em {
  display: block;
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 300;
  margin-top: 3px;
}

#carousel .item a {
  position: relative;
  display: block;
}

#carousel .owl-item {
  opacity: 0.5;
  transform: scale(0.85);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(0.85, 0.85);
  transition: all 0.3s ease-in-out 0s;
  overflow: hidden;
}

#carousel .owl-item.active.center {
  opacity: 1;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
  transform: scale(1);
}

#carousel .owl-item.active.center .item .title h4,
#carousel .owl-item.active.center .item .views {
  opacity: 1;
}

/*-------- 2.3 Listing --------*/
#results {
  padding: 39px 0;
  color: #fff;
  background: #fff;
  position: relative;
  z-index: 999 !important;
}

@media (max-width: 767px) {
  #results {
    padding: 13px 15px;
  }
}

#results.is_stuck {
  z-index: 99;
  padding: 10px 0;
}

#results h4 {
  color: #fff;
  margin: 12px 0 0 0;
  padding: 0;
  line-height: 1;
  font-size: 16px;
  font-size: 1rem;
}

@media (max-width: 991px) {
  #results h4 {
    margin: 5px 0 0 0;
  }
}

@media (max-width: 767px) {
  #results h4 {
    margin: 3px 0 0 0;
  }
}

#results.map_view {
  padding: 10px 0;
  margin: 0 -15px;
}

#results.map_view h4 {
  margin: 3px 0 0 0;
}

#results_map_view {
  padding: 10px 0;
  margin: 0 -15px;
  color: #fff;
  background: #004dda;
  position: relative;
  z-index: 9999999999999999 !important;
}

#results_map_view h4 {
  color: #fff;
  margin: 3px 0 0 0;
  padding: 0;
  line-height: 1;
  font-size: 16px;
  font-size: 1rem;
}

@media (max-width: 991px) {
  #results_map_view h4 {
    margin: 5px 0 0 0;
  }
}

@media (max-width: 767px) {
  #results_map_view h4 {
    margin: 3px 0 0 0;
  }
}

#filters {
  background-color: #fff;
  border-bottom: 1px solid #ededed;
}

#filters h6 {
  margin-bottom: 15px;
  font-size: 16px;
  font-size: 1rem;
}

#filters ul li small {
  font-weight: 600;
  float: right;
  position: relative;
  top: 4px;
}

.map_view#filters {
  margin: 0 -15px;
}

.distance {
  margin-bottom: 15px;
}

.distance span {
  font-weight: 600;
  color: #004dda;
}

.rangeslider__handle {
  border: 2px solid #004dda !important;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
}

.rangeslider__fill {
  background: #004dda !important;
}

.filters_listing {
  padding: 10px 0 5px 0;
  background: #fff;
  border-bottom: 1px solid #ededed;
  z-index: 99 !important;
  position: relative;
}

.filters_listing ul {
  margin: 0;
}

.filters_listing ul li {
  margin-right: 10px;
}

.filters_listing ul li:first-child {
  float: left;
}

.filters_listing ul li:nth-child(2) {
  float: left;
  margin-right: 0;
}

.filters_listing ul li:nth-child(3) {
  float: right;
  margin-right: 0;
}

@media (max-width: 767px) {
  .filters_listing ul li:nth-child(3) {
    display: none;
  }
}

.filters_listing ul li:nth-child(4) {
  float: right;
}

@media (max-width: 767px) {
  .filters_listing ul li:nth-child(4) {
    margin-right: 0;
  }
}

.filters_listing.version_2 ul {
  margin: 0;
}

.filters_listing.version_2 ul li {
  margin-right: 10px;
}

.filters_listing.version_2 ul li:first-child {
  float: left;
}

.filters_listing.version_2 ul li:nth-child(2) {
  float: right;
  margin-right: 0;
}

@media (max-width: 767px) {
  .filters_listing.version_2 ul li:nth-child(2) {
    display: none;
  }
}

.filters_listing.version_2 ul li:nth-child(3) {
  float: right;
  margin-right: 10px;
}

@media (max-width: 767px) {
  .filters_listing.version_2 ul li:nth-child(3) {
    display: block;
    margin-right: 0;
  }
}

.filters_listing.version_3 {
  margin: 0 -15px 0 -15px;
}

.filters_listing.version_3 ul {
  margin: 2px 0 0 0;
}

.filters_listing.version_3 ul li {
  margin-right: 10px;
}

.filters_listing.version_3 ul li:first-child {
  float: left;
}

.filters_listing.version_3 ul li:nth-child(2) {
  float: right;
  margin-right: 0;
}

.tools_map {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  font-size: 23px;
  font-size: 1.4375rem;
}

.tools_map a {
  color: rgba(0, 0, 0, 0.5);
  margin: 0 3px;
}

.tools_map a:hover {
  color: #004dda;
}

.tools_map i {
  font-weight: 500;
  line-height: 1;
}

.score strong {
  background-color: #32a067;
  color: #fff;
  line-height: 1;
  -webkit-border-radius: 5px 5px 5px 0;
  -moz-border-radius: 5px 5px 5px 0;
  -ms-border-radius: 5px 5px 5px 0;
  border-radius: 5px 5px 5px 0;
  padding: 10px;
  display: inline-block;
}

.score span {
  display: inline-block;
  position: relative;
  top: 7px;
  margin-right: 8px;
  font-size: 12px;
  font-size: 0.75rem;
  text-align: right;
  line-height: 1.1;
  font-weight: 500;
}

.score span em {
  display: block;
  font-weight: normal;
  font-size: 11px;
  font-size: 0.6875rem;
}

.strip {
  background-color: #fff;
  display: block;
  position: relative;
  margin-bottom: 15px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}

.strip a.wish_bt {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 1;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 9px 10px;
  display: inline-block;
  color: #fff;
  line-height: 1;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

.strip a.wish_bt:after {
  font-family: 'ElegantIcons';
  content: "\e012";
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.strip a.wish_bt.liked:after {
  content: "\e078";
  color: #fff;
}

.strip a.wish_bt:hover.liked:after {
  color: #ffc107;
}

.strip a.wish_bt:hover:after {
  content: "\e078";
  color: #fff;
}

.strip figure {
  margin-bottom: 0;
  overflow: hidden;
  position: relative;
  height: 150px;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  -ms-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
}

.strip figure small {
  position: absolute;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
  left: 20px;
  top: 22px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  padding: 7px 10px 4px 10px;
  line-height: 1;
}

.strip figure .read_more {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -12px;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
  text-align: center;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  z-index: 2;
}

.strip figure .read_more span {
  background-color: #fcfcfc;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  border-radius: 20px;
  display: inline-block;
  color: #222;
  font-size: 12px;
  font-size: 0.75rem;
  padding: 5px 10px;
}

.strip figure:hover .read_more {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.strip figure a img {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale(1);
  -moz-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -o-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 100%;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}


.strip .wrapper {
  padding: 20px 25px 15px 25px;
}

.strip .wrapper h3 {
  font-size: 20px;
  font-size: 1.25rem;
  margin: 0;
  color: #fe900d;
}

.strip .wrapper h3 a {
  color: #000;
  font-size: 22px;
  line-height: 24px;
  margin-bottom: 5px;
  display: block;
}

.strip .wrapper small {
  font-weight: 600;
  margin-bottom: 10px;
  display: inline-block;
  font-size: 13px;
  font-size: 0.8125rem;
}

.strip .wrapper p {
  margin-bottom: 15px;
}

.strip ul {
  padding: 20px 15px 17px 20px;
  /* border-top: 1px solid #ededed; */
}

.strip ul li {
  display: inline-block;
  margin-right: 10px;
}

.strip ul li .score {
  margin-top: -10px;
}

.strip ul li:first-child {
  font-size: 13px;
  font-size: 0.8125rem;
  margin-top: -3px;
}

.strip ul li:first-child i {
  font-size: 20px;
  font-size: 1.25rem;
  position: relative;
  top: 4px;
}

.strip ul li:last-child {
  margin-right: 18px;
  float: right;
}

.strip.list_view {
  min-height: 150px;
}

.strip.list_view a.wish_bt {
  background-color: black;
  background-color: #32a067;
  color: #fff;
  top: 25px;
  right: 25px;
}

.strip.list_view a.wish_bt.liked:after {
  color: #004dda;
}

.strip.list_view a.wish_bt:hover.liked:after {
  color: #004dda;
}

.strip.list_view a.wish_bt:hover:after {
  color: #444;
}

.strip.list_view .score {
  margin-right: 10px;
}

.strip.list_view figure {
  margin-bottom: 0;
  overflow: hidden;
  position: relative;
  min-height: 130px;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  -ms-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}

@media (max-width: 991px) {
  .strip.list_view figure {
    min-height: 220px;
    max-height: 220px;
    height: 220px;
    -webkit-border-radius: 3px 3px 0 0;
    -moz-border-radius: 3px 3px 0 0;
    -ms-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
  }
}

.strip.list_view figure small {
  right: 30px;
  top: 30px;
  left: inherit;
}

.strip.list_view figure a img {
  height: 150px;
  width: auto;
  max-width: none;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 991px) {
  .strip.list_view figure a img {
    max-width: inherit;
    height: 460px;
    width: auto;
  }
}

@media (max-width: 767px) {
  .strip.list_view figure a img {
    height: 350px;
  }
}

.strip.list_view figure a:hover img {
  -webkit-transform: translate(-50%, -50%) scale(1.1);
  -moz-transform: translate(-50%, -50%) scale(1.1);
  -ms-transform: translate(-50%, -50%) scale(1.1);
  -o-transform: translate(-50%, -50%) scale(1.1);
  transform: translate(-50%, -50%) scale(1.1);
}

.strip.list_view .wrapper {
  padding: 15px 15px 10px 15px;
  min-height: 150px;
}

@media (max-width: 991px) {
  .strip.list_view .wrapper {
    min-height: inherit;
  }
}

.strip.map_view .listing-bottom-list {
  padding: 12px 12px;
}

.strip.map_view .listing-bottom-list ul {
  padding: 0;
  border-top: 0px;
  margin-top: 0px;
}

.strip.map_view .listing-bottom-list ul li p {
  margin-bottom: 0px;
}

.strip .reating-list li:last-child {
  margin-right: 119px;
}

.strip .reating-list li {
  margin-right: 5px;
  vertical-align: middle;
}

.loc-time b {
  color: #32a067;
}

.reating-star .icon_star {
  font-size: 16px;
  line-height: 20px;
}

.reating-list .reating-number {
  font-weight: 700;
  margin: 0px;
  color: #444 !important;
}

.strip.list_view ul {
  padding: 20px 15px 5px 30px;
  margin-bottom: 0;
}

.strip.map_view {
  min-height: 120px;
  margin-bottom: 10px;
  position: relative;
}

.strip.map_view a.wish_bt {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.1);
  color: #555;
  top: 15px;
  right: 15px;
}

.strip.map_view a.wish_bt.liked:after {
  color: #004dda;
}

.strip.map_view a.wish_bt:hover.liked:after {
  color: #004dda;
}

.strip.map_view a.wish_bt:hover:after {
  color: #444;
}

.strip.map_view .score {
  margin-right: 10px;
}

@media (max-width: 767px) {


  .reating-list .reating-number {
    font-weight: 700;
    margin: 0px;
    color: #444 !important;
    font-size: 10px;
  }

  .strip.map_view .score {
    position: relative;
    top: 3px;
  }

  .listing-bottom-list .business-year {
    font-size: 5px !important;
    display: block;
    width: 100%;
    margin-right: 20px;
  }

  .listing-bottom-list .reating-list {
    display: none;
  }

  .strip.map_view .listing-bottom-list {
    position: absolute;
    top: -40px;
    padding: 1px 4px;
    border-top: 0px;
    left: 29%;
    margin-top: 39px;
    margin-left: -141px;
    padding-top: 5px;
  }

  .strip.map_view .wrapper {
    padding-bottom: 40px;
  }

  .strip.map_view .listing-bottom {
    background-color: #f4f4f4;
    padding-top: 60px;
  }

  .strip.map_view {
    margin: 1px 10px;
  }

  .strip.map_view .listing-bottom-list ul {
    padding: 0;
    border-top: 0px;
    margin-top: -32px;
    margin-left: -5px;
  }
}
/*today*/

@media (max-width:'564px') {
  
  .strip.map_view .score {
    position: relative;
    top: 3px;
  }

  .listing-bottom-list .business-year {
    font-size: 5px !important;
    display: block;
    width: 100%;
    margin-right: 20px;
  }

  .listing-bottom-list .reating-list {
    display: none;
  }

  .strip.map_view .listing-bottom-list {
    position: absolute;
    top: -40px;
    padding: 1px 4px;
    border-top: 0px;
    left: 29%;
    margin-top: 39px;
    margin-left: -141px;
    padding-top: 5px;
  }

  .strip.map_view .wrapper {
    padding-bottom: 40px;
  }

  .strip.map_view .listing-bottom {
    background-color: #f4f4f4;
    padding-top: 60px;
  }

  .strip.map_view {
    margin: 5px 10px;
  }

  .strip.map_view .listing-bottom-list ul {
    padding: 0;
    border-top: 0px;
    margin-top: -32px;
    margin-left: -5px;
  }
}

@media (max-width: 767px) {
  .strip.map_view .score span {
    display: none;
  }
}

.strip.map_view a.address:before {
  content: "\e081";
}

@media (max-width: 991px) {
  /* .loc-time{
    display: none;
  } */
}

.strip.map_view figure {
  margin-bottom: 0;
  overflow: hidden;
  position: relative;
  min-height: inherit;
  height: 150px;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  -ms-border-radius: 3px 0 0 3px;
  border-radius: 0px;
}

@media (max-width: 991px) {
  .strip.map_view figure {
    height: 150px;
  }
}

.strip.map_view figure small {
  right: 20px;
  top: 20px;
  left: inherit;
}

.strip.map_view figure a img {
  height: 200px;
  width: auto;
  max-width: none;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 991px) {
  .strip.map_view figure a img {
    max-width: inherit;
    height: 160px;
    width: auto;
  }
}

/* .strip.map_view figure a:hover img {
  -webkit-transform: translate(-50%, -50%) scale(1.1);
  -moz-transform: translate(-50%, -50%) scale(1.1);
  -ms-transform: translate(-50%, -50%) scale(1.1);
  -o-transform: translate(-50%, -50%) scale(1.1);
  transform: translate(-50%, -50%) scale(1.1);
} */
.strip.map_view .wrapper {
  padding: 15px 15px 0px 15px;
  min-height: 110px;
  position: relative;
}

.strip.map_view .wrapper>.loc_open {
  position: absolute;
  right: 23px;
  top: 50%;
}

.strip.map_view .wrapper small {
  line-height: 1.3;
  margin-top: 5px;
  font-size: 14px;
}

.strip.map_view .wrapper p a.address {
  font-size: 14px;
}

.strip.map_view .wrapper p {
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .strip.map_view .wrapper h3 {
    font-size: 16px;
    font-size: 1rem;
  }
}

@media (max-width: 991px) {
  .strip.map_view .wrapper {
    min-height: 90px;
    padding-right: 15px;
  }
}

.strip.map_view ul {
  padding: 20px 5px 15px 15px;
  margin-bottom: 0;
}

@media (max-width:767px) {
  .strip.map_view ul {
    padding: 0px 6px 7px 2px;
    margin-bottom: -25px;
  }
}
@media (max-width:767px) {
  .listing-bottom-list ul {
    padding: 0;
    border-top: 0px;
    margin-top: 0px;
    margin-top: -131px;
    margin-left: -32px;
  }
}

a.address {
  display: inline-block;
  font-weight: 500;
  color: #444;
  line-height: 1;
}

a.address:before {
  font-family: 'ElegantIcons';
  content: "\e01c";
  margin-right: 5px;
  display: inline-block;
}

a.address:hover {
  color: #004dda;
}

.loc_open,
.loc_closed {
  position: relative;
  top: -2px;
  font-size: 11px;
  font-size: 0.6875rem;
  font-weight: 600;
  padding: 2px 8px;
  line-height: 1;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

.loc_open {
  color: #fff;
  /* background-image: linear-gradient(to right, #fe900d, #f6a816); */
  border: 1px solid #fe900d;
  font-size: 14px;
  line-height: 23px;
  font-weight: 400;
  display: block;
  min-width: 97px;
  text-align: center;
}

.loc_open.call-now {
  /* background-image: linear-gradient(to right, #ffffff, #ffffff); */
  background-color: orange !important;
    color: white !important;
  height: 32px;
}

@media (max-width:767px) {
  .loc_open.call-now {
    /* background-image: linear-gradient(to right, #ffffff, #ffffff); */
    background-color: orange !important;
    color: white !important;
    height: 32px;
  }
}

/* .loc_open.call-now:hover,
.loc_open.call-now:active {
  background-image: linear-gradient(to right, #fe900d, #f6a816);
  color: #fff;
} */



.loc_open.call-now:before {
  font-family: 'ElegantIcons';
  content: "\e090";
  margin-right: 5px;
  display: inline-block;
}

.loc_closed {
  color: #eb5c65;
  border: 1px solid #eb5c65;
}

.cat_star i {
  margin-right: 2px;
  color: #ffc107;
}

.rating {
  color: #ccc;
}

.rating .voted {
  color: #ffc107;
}

.rating i {
  margin-right: 2px;
}

.box_list {
  background-color: #fff;
  display: block;
  position: relative;
  margin: 0 0 30px 0;
  min-height: 310px;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}

.box_list a.wish_bt {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 1;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 9px 10px;
  display: inline-block;
  color: #555;
  line-height: 1;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

.box_list a.wish_bt:after {
  font-family: 'ElegantIcons';
  content: "\e030";
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.box_list a.wish_bt.liked:after {
  content: "\e089";
  color: #004dda;
}

.box_list a.wish_bt:hover.liked:after {
  color: #004dda;
}

.box_list a.wish_bt:hover:after {
  content: "\e089";
  color: #999;
}

.box_list figure {
  margin-bottom: 0;
  overflow: hidden;
  position: relative;
  min-height: 310px;
}

.box_list figure small {
  position: absolute;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
  right: 20px;
  top: 20px;
  text-transform: uppercase;
  color: #ccc;
  font-weight: 600;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  padding: 7px 10px 4px 10px;
  line-height: 1;
  z-index: 9;
}

@media (max-width: 991px) {
  .box_list figure small {
    right: 15px;
    top: 15px;
  }
}

@media (max-width: 991px) {
  .box_list figure {
    min-height: 220px;
    max-height: 220px;
    height: 220px;
  }
}

.box_list figure .read_more {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -12px;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
  text-align: center;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  z-index: 2;
}

.box_list figure .read_more span {
  background-color: #fcfcfc;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  border-radius: 20px;
  display: inline-block;
  color: #222;
  font-size: 12px;
  font-size: 0.75rem;
  padding: 5px 10px;
}

.box_list figure:hover .read_more {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.box_list figure a img {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale(1);
  -moz-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -o-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  height: 350px;
  width: auto;
  max-width: none;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 991px) {
  .box_list figure a img {
    max-width: inherit;
    height: 450px;
    width: auto;
  }
}

@media (max-width: 767px) {
  .box_list figure a img {
    height: 350px;
  }
}

.box_list figure a:hover img {
  -webkit-transform: translate(-50%, -50%) scale(1.1);
  -moz-transform: translate(-50%, -50%) scale(1.1);
  -ms-transform: translate(-50%, -50%) scale(1.1);
  -o-transform: translate(-50%, -50%) scale(1.1);
  transform: translate(-50%, -50%) scale(1.1);
}

.box_list .wrapper {
  padding: 30px 30px 20px 30px;
  min-height: 245px;
}

@media (max-width: 991px) {
  .box_list .wrapper {
    min-height: inherit;
  }
}

.box_list .wrapper h3 {
  font-size: 20px;
  font-size: 1.25rem;
  margin-top: 0;
}

.box_list small {
  text-transform: uppercase;
  color: #999;
  font-weight: 600;
}

.box_list ul {
  padding: 20px 15px 20px 30px;
  border-top: 1px solid #ededed;
  margin-bottom: 0;
}

.box_list ul li {
  display: inline-block;
  margin-right: 15px;
}

.box_list ul li .score {
  margin-top: -10px;
}

.box_list ul li:last-child {
  margin-right: 0;
  float: right;
}

#list_sidebar .box_list {
  min-height: 330px;
}

#list_sidebar .box_list figure {
  min-height: 330px;
}

@media (max-width: 991px) {
  #list_sidebar .box_list figure {
    min-height: 200px;
    max-height: 200px;
    height: 200px;
  }
}

#list_sidebar .box_list figure a img {
  height: 350px;
}

@media (max-width: 991px) {
  #list_sidebar .box_list figure a img {
    max-width: 100%;
    height: auto;
    width: inherit;
  }
}

#list_sidebar .box_list .wrapper {
  min-height: 265px;
}

@media (max-width: 991px) {
  #list_sidebar .box_list .wrapper {
    min-height: inherit;
  }
}

.layout_view {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  padding: 4px 8px 3px 8px;
}

.layout_view a {
  color: rgba(0, 0, 0, 0.5);
}

.layout_view a.active {
  color: rgba(0, 0, 0, 0.8);
}

.layout_view a:hover {
  color: #004dda;
}

.switch-field {
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.08);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-field input:checked+label {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.9);
}

.switch-field label {
  float: left;
  display: inline-block;
  min-width: 65px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 600;
  font-size: 12px;
  font-size: 0.75rem;
  text-align: center;
  text-shadow: none;
  padding: 10px 8px 8px 8px;
  line-height: 1;
  border-right: 1px solid rgba(0, 0, 0, 0.08);
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  margin: 0;
}

@media (max-width: 575px) {
  .switch-field label {
    min-width: inherit;
  }
}

.switch-field label:first-of-type {
  border-left: 0;
}

.switch-field label:last-of-type {
  border-right: 0;
}

.switch-field label:hover {
  cursor: pointer;
  color: #004dda;
}

#filters_col {
  background-color: #fff;
  padding: 20px 20px 15px 20px;
  margin-bottom: 25px;
  border: 1px solid #ededed;
}

#filters_col label {
  color: #333333;
  font-weight: normal;
}

a#filters_col_bt {
  display: block;
  color: #333333;
  position: relative;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 600;
}

a#filters_col_bt:before {
  font-family: "ElegantIcons";
  content: "\66";
  font-size: 26px;
  font-size: 1.625rem;
  position: absolute;
  right: 0;
  top: 0;
  line-height: 1;
  font-weight: normal;
}

.filter_type h6 {
  border-top: 1px solid #ededed;
  margin: 15px 0;
  padding: 15px 0 0 0;
  font-size: 13px;
  font-size: 0.8125rem;
}

.filter_type ul {
  list-style: none;
  padding: 0;
  margin: 0 0 15px 0;
}

.filter_type ul li {
  margin-bottom: 5px;
}

.filter_type ul li small {
  float: right;
  position: relative;
  top: 4px;
}

.filter_type ul li:last-child {
  margin-bottom: 0;
}

.category_filter {
  border-bottom: 1px solid #ddd;
  margin-bottom: 25px;
  padding-bottom: 10px;
}

.category_filter .container_radio {
  display: inline-block;
  margin-right: 15px;
}

a.boxed_list {
  text-align: center;
  padding: 30px;
  border: 1px solid #ededed;
  display: block;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-bottom: 25px;
}

a.boxed_list i {
  font-size: 60px;
  font-size: 3.75rem;
  margin-bottom: 15px;
}

a.boxed_list h4 {
  font-size: 18px;
  font-size: 1.125rem;
  color: #333333;
}

a.boxed_list p {
  color: #777;
  margin-bottom: 0;
}

a.boxed_list:hover i {
  color: #32a067;
}

a.btn_search_mobile {
  background: url(../img/search.svg) no-repeat center center;
  width: 21px;
  height: 21px;
  float: right;
  display: none;
}

@media (max-width: 991px) {
  a.btn_search_mobile {
    display: block;
  }
}

a.btn_search_mobile.map_view {
  display: block;
}

#search_mobile {
  overflow: auto;
  transform: translateX(105%);
  top: 0;
  right: 0;
  bottom: 0;
  width: 50%;
  height: 100%;
  position: fixed;
  background: white url(../img/bg_aside.svg) no-repeat center right;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 60px 30px 30px 30px;
  z-index: 9999999999999999 !important;
  -webkit-box-shadow: 1px 0px 5px 0px rgba(50, 50, 50, 0.55);
  -moz-box-shadow: 1px 0px 5px 0px rgba(50, 50, 50, 0.55);
  box-shadow: 1px 0px 5px 0px rgba(50, 50, 50, 0.55);
  -webkit-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
}

#search_mobile.show {
  transform: translateX(0);
}

@media (max-width: 767px) {
  #search_mobile {
    width: 100%;
    padding: 45px 30px 30px 30px;
  }
}

#search_mobile input.form-control {
  border: 1px solid #d2d8dd;
  box-shadow: none;
}

#search_mobile .custom-search-input-2 {
  box-shadow: none;
}

#search_mobile .custom-search-input-2 input[type='submit'] {
  margin: 20px 0 0 0;
}

#search_mobile .form-group {
  margin-bottom: 5px;
}

#search_mobile .nice-select {
  border: 1px solid #d2d8dd;
}

#search_mobile a.side_panel {
  font-size: 32px;
  font-size: 2rem;
  display: block;
  position: absolute;
  right: 10px;
  top: 0;
  color: #999;
}

#search_mobile a.side_panel:hover {
  color: #004dda;
}

.search_mob a {
  display: none;
  width: 22px;
  height: 24px;
  text-indent: -9999px;
  position: relative;
  color: #0054a6;
}

@media (max-width: 991px) {
  .search_mob a {
    display: block;
  }
}

.search_mob a:hover {
  color: #004dda;
}

.search_mob a:before {
  font-family: 'themify';
  content: "\e610";
  text-indent: 0;
  position: absolute;
  left: 0;
  top: 0;
  line-height: 1;
  font-weight: 600;
  font-size: 21px;
  font-size: 1.3125rem;
}

.search_mob_wp {
  padding: 10px 0 10px 0;
  display: none;
  width: 100%;
}

.search_mob_wp .nice-select {
  margin-bottom: 5px;
  border: none;
}

.search_mob_wp .form-control {
  margin-bottom: 10px;
}

.search_mob_wp input[type='submit'] {
  background-color: #ffc107;
  color: #222;
}

.search_mob_wp input[type='submit']:hover {
  background-color: #32a067;
  color: #fff;
}

/* Opacity mask when left open */
.layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 100%;
  z-index: 100;
  min-height: 100%;
  background-color: #000;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, visibility 0s ease 0.3s;
  -moz-transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, visibility 0s ease 0.3s;
  -ms-transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, visibility 0s ease 0.3s;
  -o-transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, visibility 0s ease 0.3s;
  transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, visibility 0s ease 0.3s;
  opacity: 0;
  visibility: hidden;
}

.layer-is-visible {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
  -moz-transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
  -ms-transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
  transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
}

/*-------- 2.4 Detail page --------*/
.hero_in {
  width: 100%;
  height: 450px;
  position: relative;
  overflow: hidden;
}

@media (max-width: 767px) {
  .hero_in {
    height: 350px;
  }
}

.hero_in.shop_detail {
  height: 550px;
}

@media (max-width: 767px) {
  .hero_in.shop_detail {
    height: 350px;
  }
}

.hero_in.shop_detail:before {
  background: url(../img/hero_in_shop_detail.jpg) center center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.hero_in.shop_detail .wrapper {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.2);
}

.hero_in.hotels_detail {
  height: 550px;
}

@media (max-width: 767px) {
  .hero_in.hotels_detail {
    height: 350px;
  }
}

.hero_in.hotels_detail:before {
  background: url(../img/hero_in_hotels_detail.jpg) center center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.hero_in.hotels_detail .wrapper {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.2);
}

.hero_in.restaurant_detail {
  height: 550px;
}

@media (max-width: 767px) {
  .hero_in.restaurant_detail {
    height: 350px;
  }
}

.hero_in.restaurant_detail:before {
  background: url(../img/hero_in_restaurants_detail.jpg) center center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.hero_in.restaurant_detail .wrapper {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.2);
}

.hero_in:before {
  animation: pop-in 5s 0.1s cubic-bezier(0, 0.5, 0, 1) forwards;
  content: "";
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.hero_in .wrapper {
  height: 100%;
}

.hero_in a.btn_photos {
  position: absolute;
  left: 20px;
  bottom: 20px;
  background-color: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
  line-height: 1;
  padding: 10px 15px;
  color: #444;
  font-weight: 500;
}

.hero_in a.btn_photos:hover {
  color: #004dda;
}

/* Animations */
@keyframes pop-in {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
}

.secondary_nav {
  background: #004dda;
  padding: 15px 0;
}

/* .secondary_nav.is_stuck {
  z-index: 99;
  width: 100% !important;
  left: 0;
} */
.secondary_nav ul {
  margin-bottom: 0;
}

.secondary_nav ul li {
  display: inline-block;
  margin-right: 20px;
  font-weight: 500;
  font-size: 16px;
  font-size: 1rem;
}

.secondary_nav ul li a {
  color: rgba(255, 255, 255, 0.5);
}

.secondary_nav ul li a:hover {
  color: #fff;
  opacity: 1;
}

.secondary_nav ul li a.active {
  color: white;
}

.secondary_nav ul li:last-child {
  display: none;
}

@media (max-width: 575px) {
  .secondary_nav ul li:last-child {
    display: inline-block;
  }
}

.carousel_detail {
  margin-bottom: 40px;
}

.carousel_detail .item {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  overflow: hidden;
}

.carousel_detail .owl-nav {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.carousel_detail .owl-nav [class*='owl-'] {
  -webkit-border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  -ms-border-radius: 3px !important;
  border-radius: 3px !important;
  padding: 4px 5px 2px 5px !important;
  background: #222222 !important;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.carousel_detail .owl-nav [class*='owl-'] i {
  font-size: 24px;
  line-height: 1;
  margin: 0;
}

.carousel_detail .owl-nav [class*='owl-']:hover {
  background: #004dda !important;
}

.detail_title_1 {
  margin-bottom: 25px;
}

.detail_title_1 h1 {
  font-size: 32px;
  font-size: 2rem;
  margin: 0;
}

.detail_title_1 ul {
  float: right;
  margin: 10px 0 0 0;
}

.detail_title_1 ul li {
  display: inline-block;
  margin-right: 20px;
  font-weight: 500;
}

section#description,
section#reviews {
  border-bottom: 3px solid #d2d8dd;
  margin-bottom: 30px;
}

section#description h2,
section#reviews h2 {
  font-size: 24px;
  font-size: 1.5rem;
}

section#description h3,
section#reviews h3 {
  font-size: 21px;
  font-size: 1.3125rem;
}

section#description h4,
section#reviews h4 {
  font-size: 18px;
  font-size: 1.125rem;
}

section#description hr,
section#reviews hr {
  border-color: #d2d8dd;
}

section#reviews {
  border-bottom: none;
}

#review_summary {
  text-align: center;
  background-color: #32a067;
  color: #fff;
  padding: 20px 10px;
  -webkit-border-radius: 3px 3px 3px 0;
  -moz-border-radius: 3px 3px 3px 0;
  -ms-border-radius: 3px 3px 3px 0;
  border-radius: 3px 3px 3px 0;
}

@media (max-width: 991px) {
  #review_summary {
    margin-bottom: 15px;
  }
}

#review_summary strong {
  font-size: 42px;
  font-size: 2.625rem;
  display: block;
  line-height: 1;
}

#review_summary em {
  font-style: normal;
  font-weight: 500;
  display: block;
}

.reviews-container .progress {
  margin-bottom: 12px;
}

.reviews-container .progress-bar {
  background-color: #32a067;
}

.reviews-container .review-box {
  position: relative;
  margin-bottom: 25px;
  padding-left: 100px;
  min-height: 100px;
}

@media (max-width: 767px) {
  .reviews-container .review-box {
    padding-left: 0;
  }
}

.reviews-container .rev-thumb {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 80px;
  height: 80px;
  background: #ffffff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  overflow: hidden;
}

.reviews-container .rev-thumb img {
  width: 80px;
  height: auto;
}

@media (max-width: 767px) {
  .reviews-container .rev-thumb {
    position: static;
    margin-bottom: 10px;
  }
}

.reviews-container .rev-content {
  position: relative;
  padding: 25px 25px 1px 25px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  background-color: #fff;
}

.reviews-container .rev-info {
  font-size: 12px;
  font-size: 0.75rem;
  font-style: italic;
  color: #777;
  margin-bottom: 10px;
}

.box_detail {
  background-color: #fff;
  padding: 25px 25px 15px 25px;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  margin-bottom: 30px;
}

.box_detail .price {
  line-height: 1;
  border-bottom: 1px solid #ededed;
  margin: 0 -25px 25px -25px;
  padding: 0 25px 15px 25px;
}

.box_detail .price>span {
  font-size: 31px;
  font-size: 1.9375rem;
  font-weight: 600;
}

.box_detail .price>span>small {
  font-size: 11px;
  font-size: 0.6875rem;
  font-weight: 500;
}

.box_detail .price .score {
  float: right;
  margin-top: -5px;
}

.box_detail h3 {
  font-size: 20px;
  font-size: 1.25rem;
  margin: 25px 0 10px 0;
}

.box_detail ul {
  margin-bottom: 0;
}

.box_detail ul li {
  margin-bottom: 5px;
}

.box_detail ul li i {
  margin-right: 8px;
}

.box_detail figure {
  position: relative;
  background-color: #000;
}

.box_detail figure img {
  opacity: 0.8;
}

.box_detail figure span {
  position: absolute;
  display: block;
  left: 0;
  bottom: 10px;
  text-align: center;
  width: 100%;
  color: #fff;
  font-weight: 600;
}

.box_detail figure a i {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
  display: block;
  width: 60px;
  height: 60px;
  color: #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  line-height: 60px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -30px 0 0 -30px;
  font-size: 42px;
  font-size: 2.625rem;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: 9;
}

.box_detail figure:hover i {
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  transform: scale(1.3);
}

/* Media Queries */
@media screen and (min-width: 564px) {
  .daterangepicker {
    width: 500px !important;
  }
}

@media screen and (min-width: 564px) {
  .daterangepicker.single {
    width: auto !important;
  }
}

/* Booking form */
.booking input {
  font-weight: 500;
  height: 45px;
}

.booking input:focus {
  box-shadow: none;
  border: 1px solid #d2d8dd;
}

.booking select {
  display: none;
}

.booking .dropdown {
  background-color: #fff;
  font-size: 14px;
  font-size: 0.875rem;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #d2d8dd;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0 0 10px 0;
}

@media (max-width: 991px) {
  .booking .dropdown {
    height: inherit;
  }
}

.booking .nice-select .current {
  font-weight: 500;
  color: #6f787f;
}

.booking .custom-select-form .nice-select:active,
.booking .custom-select-form .nice-select.open,
.booking .custom-select-form .nice-select:focus {
  border-color: #d2d8dd;
  outline: 0;
  box-shadow: none;
}

.booking .custom-select-form select {
  display: none;
}

.booking .form-group {
  margin: 0 0 10px 0;
}

.booking .form-group i {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  font-size: 18px;
  font-size: 1.125rem;
  position: absolute;
  background-color: #fff;
  height: 43px;
  top: 1px;
  right: 1px;
  padding-right: 10px;
  display: block;
  width: 20px;
  box-sizing: content-box;
  line-height: 43px;
  z-index: 9;
  color: #999;
}

.booking .form-group i.loader {
  background-color: transparent;
  color: #fff;
}

.booking .form-group i.icon_lock_alt {
  top: 36px;
}

.booking .form-group i.ti-pencil {
  top: 1px;
  height: 20px;
}

.booking textarea {
  font-weight: 500;
  height: 100px;
  padding-right: 25px;
}

.booking textarea:focus {
  box-shadow: none;
  border: 1px solid #d2d8dd;
}

.dropdown {
  position: relative;
  text-align: left;
  padding: 6px 0px 0 0px;
}

@media (max-width: 991px) {
  .dropdown {
    background-color: #fff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    /* height: 50px; */
  }
}

.dropdown a {
  color: #727b82;
  font-weight: 500;
  transition: all 0.3s;
  display: block;
  position: relative;
}

.dropdown a:after {
  font-family: 'ElegantIcons';
  content: "\33";
  font-size: 24px;
  font-size: 1.5rem;
  color: #999;
  font-weight: 500;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: absolute;
  right: 0;
  top: -8px;
}

.dropdown.show a:after {
  transform: rotate(180deg);
}

.dropdown .dropdown-menu {
  font-size: 14px;
  font-size: 0.875rem;
  -moz-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
  -webkit-transition: opacity 0.5s ease;
  -ms-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  margin-top: 16px;
  background: #fff;
  border: 1px solid #d2d8dd;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  box-shadow: 0 12px 35px 2px rgba(0, 0, 0, 0.12);
  padding: 15px 15px 0 15px;
  white-space: normal;
  width: 280px;
}

.dropdown .dropdown-menu input:focus {
  outline: none !important;
  border: none !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

/*Quantity incrementer input*/
.dropdown-menu-content {
  display: flex;
  margin-bottom: 10px;
}

.dropdown-menu-content label {
  display: block;
  flex: 1;
  line-height: 40px;
  color: #727b82;
}

.qty-buttons {
  position: relative;
  height: 38px;
  display: inline-block;
  width: 140px;
}

input.qty {
  width: 100%;
  text-align: center;
  border: none !important;
  color: #495057;
  font-size: 16px;
  font-size: 1rem;
  height: 40px;
}

input.qtyminus,
input.qtyplus {
  position: absolute;
  width: 36px;
  height: 36px;
  border: 0;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  border-radius: 50px;
}

input.qtyplus {
  background: #f2f2f2 url(../img/plus.svg) no-repeat center center;
  right: 3px;
  top: 1px;
  text-indent: -9999px;
  box-shadow: none;
}

input.qtyplus:focus {
  border: none;
}

input.qtyminus {
  background: #f2f2f2 url(../img/minus.svg) no-repeat center center;
  left: 3px;
  top: 1px;
  text-indent: -9999px;
  box-shadow: none;
}

input.qtyminus:focus {
  border: none;
}

#qty_total {
  display: inline-block;
  font-size: 11px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  position: relative;
  top: 0;
  left: 2px;
  height: 18px;
  width: 18px;
  background-color: #66676b;
  border-radius: 50%;
  color: #fff;
}

.rotate-x {
  animation-duration: .5s;
  animation-name: rotate-x;
}

@keyframes rotate-x {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(360deg);
  }
}

ul.hotel_facilities {
  list-style: none;
  margin: 0;
  padding: 0;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}

ul.hotel_facilities li {
  margin-bottom: 10px;
  display: inline-block;
  width: 100%;
}

ul.hotel_facilities li img {
  margin-right: 10px;
}

.room_type {
  padding: 10px 0 25px 0;
}

.room_type.last {
  padding-bottom: 0;
}

@media (max-width: 767px) {
  .room_type h4 {
    margin-top: 25px;
  }
}

.opening {
  background-color: #0044c2;
  background-color: rgba(0, 77, 218, 0.05);
  padding: 25px 25px 25px 80px;
  position: relative;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

@media (max-width: 767px) {
  .opening {
    padding: 15px;
  }
}

.opening i {
  position: absolute;
  left: 25px;
  top: 25px;
  font-size: 36px;
  font-size: 2.25rem;
  color: #555;
}

@media (max-width: 767px) {
  .opening i {
    display: none;
  }
}

.opening h4 {
  margin-top: 10px;
}

@media (max-width: 767px) {
  .opening h4 {
    margin-top: 0;
  }
}

.opening ul {
  margin: 0;
  padding: 0;
}

.opening ul li span {
  display: inline-block;
  float: right;
}

ul.menu_list {
  list-style: none;
  padding: 0;
  margin: 0 0 0 0;
}

ul.menu_list li {
  position: relative;
  padding: 5px 0 25px 120px;
  clear: both;
  font-size: 13px;
  font-size: 0.8125rem;
}

ul.menu_list li h6 {
  font-size: 14px;
  font-size: 0.875rem;
  border-bottom: 1px dashed #ddd;
  padding-bottom: 5px;
}

ul.menu_list li h6 span {
  float: right;
}

ul.menu_list li .thumb {
  width: 100px;
  height: 100px;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  border: 1px solid #ddd;
  padding: 5px;
}

ul.menu_list li .thumb img {
  width: 88px;
  height: auto;
  text-align: center;
}

.ribbon {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}

.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  position: absolute;
  top: 19px;
  right: -21px;
}

.ribbon span.open {
  background: #79A70A;
}

.ribbon span.open::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #5d8108;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #5d8108;
}

.ribbon span.open::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #5d8108;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #5d8108;
}

.ribbon span.closed {
  background: #ca3b41;
}

.ribbon span.closed::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #7d2528;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #7d2528;
}

.ribbon span.closed::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #7d2528;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #7d2528;
}

/*Map lisiting --------------- */
.full-height {
  height: 100%;
}

@media (max-width: 991px) {
  .full-height {
    height: auto;
  }
}

.row-height {
  height: 100%;
  min-height: 100%;
  padding-top: 58px;
}

@media (max-width: 991px) {
  .row-height {
    height: auto;
    padding-top: 48px;
  }
}

.map-right {
  height: 100%;
  min-height: 100%;
  padding: 0px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 0;
  z-index: 9999;
}

@media (max-width: 991px) {
  .map-right {
    height: 400px;
    position: static;
    min-height: inherit;
  }
}

#map_right_listing {
  height: 100%;
  width: 100%;
}

.content-left {
  height: 100%;
  min-height: 100%;
  overflow-y: scroll;
  background-color: #f8f8f8;
}

@media (max-width: 991px) {
  .content-left {
    padding-top: 0;
  }
}

/*-------- 2.5 Login/register --------*/
#login_bg,
#register_bg {
  background: #444444 url(../img/access_bg.jpg) center center no-repeat fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
}

#register_bg {
  background: #444444 url(../img/access_bg.jpg) center center no-repeat fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#login figure,
#register figure {
  text-align: center;
  border-bottom: 1px solid #ededed;
  margin: -45px -60px 25px -60px;
  padding: 20px 60px 25px 60px;
}

@media (max-width: 767px) {

  #login figure,
  #register figure {
    margin: -30px -30px 20px -30px;
    padding: 15px 60px 20px 60px;
  }
}

#login aside,
#register aside {
  width: 430px;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.5);
  padding: 45px 60px 60px 60px;
  margin-left: -215px;
  position: absolute;
  left: 50%;
  top: 0;
  overflow-y: auto;
  background-color: #fff;
  min-height: 100vh;
}

@media (max-width: 767px) {

  #login aside,
  #register aside {
    width: 100%;
    padding: 30px;
    left: 0;
    margin: 0;
  }
}

#login aside form,
#register aside form {
  margin-bottom: 60px;
  display: block;
}

#login aside form .form-group input,
#register aside form .form-group input {
  padding-left: 40px;
}

#login aside form .form-group i,
#register aside form .form-group i {
  font-size: 21px;
  font-size: 1.3125rem;
  position: absolute;
  left: 12px;
  top: 34px;
  color: #ccc;
  width: 25px;
  height: 25px;
  display: block;
  font-weight: 400 !important;
}

#login aside .copy,
#register aside .copy {
  text-align: center;
  position: absolute;
  height: 30px;
  left: 0;
  bottom: 30px;
  width: 100%;
  color: #999;
  font-weight: 500;
}

.access_social {
  margin-top: 45px;
}

@media (max-width: 767px) {
  .access_social {
    margin-top: 30px;
  }
}

.divider {
  text-align: center;
  height: 1px;
  margin: 30px 0 15px 0;
  background-color: #ededed;
}

.divider span {
  position: relative;
  top: -20px;
  background-color: #fff;
  display: inline-block;
  padding: 10px;
  font-style: italic;
}

a.social_bt {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  text-align: center;
  color: #fff;
  min-width: 200px;
  margin-bottom: 10px;
  display: block;
  padding: 12px;
  line-height: 1;
  position: relative;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

a.social_bt:hover {
  -webkit-filter: brightness(115%);
  filter: brightness(115%);
}

a.social_bt.facebook:before,
a.social_bt.google:before,
a.social_bt.linkedin:before {
  font-family: 'ElegantIcons';
  position: absolute;
  left: 12px;
  top: 10px;
  font-size: 16px;
  font-size: 1rem;
  color: #fff;
}

a.social_bt.facebook {
  background-color: #3B5998;
}

a.social_bt.facebook:before {
  content: "\e093";
}

a.social_bt.google {
  background-color: #DC4E41;
}

a.social_bt.google:before {
  content: "\e096";
  top: 12px;
}

a.social_bt.linkedin {
  background-color: #0077B5;
}

a.social_bt.linkedin:before {
  content: "\e09d";
}

/*Password strength */
#pass-info {
  width: 100%;
  margin-bottom: 15px;
  color: #555;
  text-align: center;
  font-size: 12px;
  font-size: 0.75rem;
  padding: 5px 3px 3px 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

#pass-info.weakpass {
  border: 1px solid #FF9191;
  background: #FFC7C7;
  color: #94546E;
}

#pass-info.stillweakpass {
  border: 1px solid #FBB;
  background: #FDD;
  color: #945870;
}

#pass-info.goodpass {
  border: 1px solid #C4EEC8;
  background: #E4FFE4;
  color: #51926E;
}

#pass-info.strongpass {
  border: 1px solid #6ED66E;
  background: #79F079;
  color: #348F34;
}

#pass-info.vrystrongpass {
  border: 1px solid #379137;
  background: #48B448;
  color: #CDFFCD;
}

/* Checkbox style */
.container_check {
  display: block;
  position: relative;
  font-size: 14px;
  font-size: 0.875rem;
  padding-left: 30px;
  line-height: 1.4;
  margin-bottom: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container_check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.container_check input:checked~.checkmark {
  background-color: #fb830d;
  border: 1px solid transparent;
}

.container_check .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #d2d8dd;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.container_check .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.container_check input:checked~.checkmark:after {
  display: block;
}

/* Radio buttons */
.container_radio {
  display: block;
  position: relative;
  font-size: 15px;
  font-size: 0.9375rem;
  padding-left: 30px;
  line-height: 1.3;
  margin-bottom: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container_radio input {
  position: absolute;
  opacity: 0;
}

.container_radio input:checked~.checkmark:after {
  opacity: 1;
}

.container_radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #cccccc;
  border-radius: 50%;
}

.container_radio .checkmark:after {
  display: block;
  content: "";
  position: absolute;
  opacity: 0;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #004dda;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

/*-------- 2.6 Checkout --------*/
.step .nav-link {
  display: block;
  padding: 0 15px;
  height: 30px;
  font-size: 15px;
  font-size: 0.9375rem;
  color: #444;
}

.step .nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.step .nav-link.disabled {
  color: #6c757d;
}

.step .nav-tabs {
  border: none;
  border-bottom: 2px solid #dee2e6;
}

.step .nav-tabs .nav-item {
  margin-bottom: -2px;
}

.step .nav-tabs .nav-link {
  border: none;
}

.step .nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.step .nav-tabs .nav-link.active,
.step .nav-tabs .nav-item.show .nav-link {
  border-bottom: 2px solid #004dda;
  color: #004dda;
  background-color: transparent;
}

.step .tab-content.checkout {
  padding: 15px 0 0 0;
}

.step .tab-content.checkout hr {
  margin: 10px 0;
}

.step .tab-content.checkout .form-group {
  margin-bottom: 10px;
}

.step .tab-content.checkout .nice-select {
  margin-bottom: 0;
}

.step .tab-content.checkout .social_bt {
  margin-bottom: 10px;
}

#other_addr_c {
  display: none;
}

.step {
  margin-bottom: 30px;
}

@media (max-width: 991px) {
  .step {
    margin-bottom: 35px;
  }
}

.step h3:before {
  width: 0;
  height: 0;
  border-top: 20px inset transparent;
  border-bottom: 20px inset transparent;
  border-left: 10px solid #f8f8f8;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
}

@media (max-width: 767px) {
  .step h3:before {
    border: none;
  }
}

.step h3:after {
  width: 0;
  height: 0;
  border-top: 20px inset transparent;
  border-bottom: 20px inset transparent;
  border-left: 10px solid #333;
  position: absolute;
  content: "";
  top: 0;
  right: -10px;
  z-index: 2;
}

@media (max-width: 767px) {
  .step h3:after {
    border: none;
  }
}

.step.first h3:before,
.step.last h3:after {
  border: none;
}

.step h3 {
  padding: 10px 12px 10px 20px;
  background: #333;
  position: relative;
  margin-bottom: 15px;
  color: #fff;
  font-size: 16px;
  font-size: 1rem;
}

@media (max-width: 767px) {
  .step h3 {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
  }
}

.step #forgot_pw {
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}

.payments ul {
  list-style: none;
  margin: 0 0 30px 0;
  padding: 0;
}

.payments ul li {
  border-bottom: 1px solid #ededed;
  margin-bottom: 8px;
}

.payments ul li a.info {
  display: inline-block;
  float: right;
  color: #444;
}

.payments ul li a.info:hover {
  color: #004dda;
}

.payments ul li a.info:after {
  font-family: 'themify';
  content: "\e718";
  position: relative;
  right: 0;
  top: 0;
  font-size: 15px;
  font-size: 0.9375rem;
}

.payment_info figure img {
  height: 20px;
  width: auto;
}

.payment_info p {
  font-size: 13px;
  font-size: 0.8125rem;
}

.box_general.summary {
  background-color: #fff;
  padding-bottom: 20px;
}

.box_general.summary ul {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0;
}

.box_general.summary ul li {
  border-bottom: 1px solid #ededed;
  margin-bottom: 8px;
  padding-bottom: 6px;
  font-weight: 500;
}

.box_general.summary ul li:last-child {
  font-weight: 700;
  border-bottom: none;
  color: red;
}

.box_general.summary label.container_check {
  font-size: 12px;
  font-size: 0.75rem;
  padding-top: 4px;
}

#confirm {
  text-align: center;
  background-color: #f8f8f8;
  padding: 60px 15px;
}

@-webkit-keyframes checkmark {
  0% {
    stroke-dashoffset: 50px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@-ms-keyframes checkmark {
  0% {
    stroke-dashoffset: 50px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes checkmark {
  0% {
    stroke-dashoffset: 50px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 240px;
  }

  100% {
    stroke-dashoffset: 480px;
  }
}

@-ms-keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 240px;
  }

  100% {
    stroke-dashoffset: 480px;
  }
}

@keyframes checkmark-circle {
  0% {
    stroke-dashoffset: 240px;
  }

  100% {
    stroke-dashoffset: 480px;
  }
}

.inlinesvg .svg svg {
  display: inline;
}

.icon--order-success svg path {
  -webkit-animation: checkmark 0.25s ease-in-out 0.7s backwards;
  animation: checkmark 0.25s ease-in-out 0.7s backwards;
}

.icon--order-success svg circle {
  -webkit-animation: checkmark-circle 0.6s ease-in-out backwards;
  animation: checkmark-circle 0.6s ease-in-out backwards;
}

/*-------- 2.7 Account --------*/
.box_account {
  margin-bottom: 25px;
}

.box_account h3 {
  font-size: 21px;
  font-size: 1.3125rem;
  padding-left: 30px;
  height: 30px;
  padding-top: 5px;
  display: inline-block;
  margin-bottom: 15px;
}

.box_account h3.client {
  background: url(../img/user.svg) center left no-repeat;
}

.box_account h3.new_client {
  background: url(../img/new_user.svg) center left no-repeat;
}

.box_account .form_container {
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 25px;
  position: relative;
}

.box_account .form_container hr {
  margin: 0 0 10px 0;
}

.box_account .form_container a.social_bt {
  margin-bottom: 0;
  min-width: inherit;
}

@media (max-width: 991px) {
  .box_account .form_container a.social_bt {
    margin-bottom: 5px;
  }
}

.box_account .form_container .divider {
  margin-top: 20px;
}

.box_account .form_container .divider span {
  background-color: #fff;
}

.box_account .form_container .form-group {
  margin-bottom: 10px;
}

.box_account .form_container #forgot_pw {
  height: 100% !important;
  min-height: inherit;
  background-color: #fff;
  padding: 25px;
}

/*-------- 2.8 Map styles --------*/
/* Cluster styles */
.cluster img {
  display: none !important;
}

.cluster-visible {
  text-align: center;
  font-size: 15px !important;
  color: #fff !important;
  font-weight: 500;
  border-radius: 50%;
  width: 36px !important;
  height: 36px !important;
  line-height: 36px !important;
  background-color: #004dda !important;
  background-image: none !important;
}

.cluster-visible:before {
  border: 7px solid #004dda;
  opacity: 0.2;
  box-shadow: inset 0 0 0 4px #004dda;
  content: '';
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: cluster-animation 2.5s infinite;
}

@keyframes cluster-animation {

  0%,
  100% {
    transform: scale(1.3) rotate(0deg);
  }

  50% {
    transform: scale(1.5) rotate(90deg);
  }
}

.map {
  width: 100%;
  height: 500px;
}

.map.map_single {
  height: 400px;
}

.infoBox {
  -webkit-animation: fadeIn 0.9s;
  animation: fadeIn 0.9s;
  padding-right: 50px;
}

.infoBox>img {
  position: absolute !important;
  right: 60px !important;
  top: 10px !important;
  z-index: 9999;
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.marker_info {
  width: 240px;
  height: 270px;
  border-radius: 5px;
  text-align: left;
  background: #000;
  background: white;
  position: relative;
  z-index: 999;
  font-family: "Poppins", Helvetica, sans-serif;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
}

.marker_info img {
  display: block;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  -ms-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
}

.marker_info h3 {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.1;
  font-weight: 500;
  margin-top: 3px;
  color: #444;
}

.marker_info em {
  display: inline-block;
  font-size: 12px;
  font-size: 0.75rem;
  color: #999;
  font-style: normal;
}

.marker_info span {
  display: block;
  padding: 15px 20px 0 20px;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.2;
  color: #fff;
  position: relative;
}

.marker_info span strong {
  display: block;
  font-weight: 500;
}

.marker_info:after {
  right: 100%;
  top: 56%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(0, 0, 0, 0);
  border-right-color: white;
  border-width: 12px;
  margin-top: -12px;
}

a.btn_infobox_detail {
  position: absolute;
  top: 20px;
  right: 15px;
  width: 20px;
  height: 20px;
}

a.btn_infobox_detail:before,
.btn_infobox_get_directions:before,
a.btn_infobox_phone:before {
  font-style: normal;
  font-weight: normal;
  font-family: "ElegantIcons";
  font-size: 20px;
  font-size: 1.25rem;
  cursor: pointer;
}

a.btn_infobox_detail:before {
  content: "\70";
  color: #ccc;
}

a.btn_infobox_detail:before:hover {
  color: #004dda;
}

.btn_infobox_get_directions,
a.btn_infobox_phone {
  border: none;
  display: inline-block;
  font-weight: 500;
  color: #004dda;
  background: none;
  cursor: pointer;
  font-size: 13px;
  font-size: 0.8125rem;
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
  outline: none;
}

.btn_infobox_get_directions:focus,
a.btn_infobox_phone:focus {
  outline: none;
}

.btn_infobox_get_directions:hover,
a.btn_infobox_phone:hover {
  color: #333;
}

.btn_infobox_get_directions:before {
  font-size: 14px;
  font-size: 0.875rem;
  content: "\e080";
  position: absolute;
  left: 0;
  top: 0;
}

a.btn_infobox_phone:before {
  font-size: 14px;
  content: "\e090";
  position: absolute;
  left: 0;
  top: -2px;
}

span.infobox_rate {
  display: inline-block;
  margin: -44px 0 0 -20px;
  float: left;
  background-color: #0054a6;
  padding: 5px 8px;
  font-weight: 500;
  text-align: left;
  font-size: 12px;
  font-size: 0.75rem;
}

/*-------- 2.9 Contacts--------*/
#map_contact {
  width: 100%;
  height: 450px;
}

@media (max-width: 991px) {
  #map_contact {
    height: 350px;
  }
}

iframe#map_iframe {
  width: 100%;
  height: 450px;
  border: 0;
}

@media (max-width: 991px) {
  iframe#map_iframe {
    height: 400px;
  }
}

.box_contacts {
  background-color: #fff;
  padding: 25px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  margin-bottom: 15px;
  text-align: center;
  font-size: 15px;
  font-size: 0.9375rem;
}

.box_contacts h2 {
  font-weight: 500;
  margin: 10px 0 10px 0;
  font-size: 21px;
  font-size: 1.3125rem;
}

.box_contacts i {
  font-size: 46px;
  font-size: 2.875rem;
  color: #004dda;
}

.box_contacts a {
  color: #555555;
}

.box_contacts a:hover {
  color: #004dda;
  text-decoration: underline;
}

.error_message {
  font-weight: 500;
  color: #e3464c;
}

/*-------- 2.10 404 page --------*/
#error_page {
  background: #0054a6 url(../img/pattern_1.svg) center bottom repeat-x;
  color: #fff;
  height: 600px;
}

@media (max-width: 767px) {
  #error_page {
    background-size: auto 300px;
  }
}

#error_page .wrapper {
  background: rgba(0, 0, 0, 0.6);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.6), transparent);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), transparent);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#error_page h2 {
  margin-top: 30px;
  font-size: 140px;
  font-size: 8.75rem;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1;
}

#error_page h2 i {
  color: #ffc107;
}

@media (max-width: 767px) {
  #error_page h2 {
    font-size: 72px;
    font-size: 4.5rem;
  }
}

#error_page p {
  font-size: 21px;
  font-size: 1.3125rem;
}

@media (max-width: 767px) {
  #error_page p {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

@media (max-width: 767px) {
  #error_page {
    padding: 0;
    height: 500px;
  }
}

.search_bar_error {
  position: relative;
  margin-bottom: 60px;
}

.search_bar_error input[type='text'] {
  border: 0;
  height: 50px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  padding-left: 15px;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.15);
}

.search_bar_error input[type='submit'] {
  position: absolute;
  right: -1px;
  color: #222;
  font-weight: 600;
  top: 0;
  border: 0;
  padding: 0 25px;
  height: 50px;
  cursor: pointer;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  -ms-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
  background-color: #ffc107;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.search_bar_error input[type='submit']:hover {
  background-color: #32a067;
  color: #fff;
}

/*-------- 2.11 Media Gallery --------*/
.grid-gallery ul {
  margin: 0 0 25px 0;
  padding: 0;
  width: 100%;
  text-align: center;
}

.grid-gallery ul li {
  display: inline-block;
  margin: 0;
  padding: 0;
  margin: -3px;
  min-height: 100%;
  width: 25%;
  background-color: #000;
  list-style: none;
}

@media (max-width: 991px) {
  .grid-gallery ul li {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .grid-gallery ul li {
    width: 100%;
    min-height: 100%;
    float: none;
  }
}

.grid-gallery ul li figure {
  position: relative;
  overflow: hidden;
  margin: -3px;
}

.grid-gallery ul li figure img {
  width: 100%;
  height: 100%;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.grid-gallery ul li figure:hover img,
.grid-gallery ul li figure:focus img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

.grid-gallery ul li figure:hover figcaption,
.grid-gallery ul li figure:focus figcaption {
  opacity: 1;
}

.grid-gallery ul li figure:hover figcaption .caption-content,
.grid-gallery ul li figure:focus figcaption .caption-content {
  -webkit-transform: translate(0px, 0px);
  -moz-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  -o-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.grid-gallery ul li figcaption {
  position: absolute;
  top: 0;
  left: 0;
  padding: 15% 0;
  width: 100%;
  height: 100%;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
  text-align: center;
  font-size: 14px;
  font-size: 0.875rem;
  opacity: 0;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.grid-gallery ul li figcaption .caption-content {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -100px;
  width: 200px;
  -webkit-transform: translate(0px, 15px);
  -moz-transform: translate(0px, 15px);
  -ms-transform: translate(0px, 15px);
  -o-transform: translate(0px, 15px);
  transform: translate(0px, 15px);
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.grid-gallery ul li figcaption a {
  color: #fff;
}

.grid-gallery ul li figcaption a:hover,
.grid-gallery ul li figcaption a:focus {
  color: #ffc107;
}

.grid-gallery ul li figcaption i {
  font-size: 30px;
  font-size: 1.875rem;
}

.grid-gallery ul li figcaption p {
  margin-bottom: 0;
  margin-top: 10px;
  text-transform: uppercase;
  font-weight: 400;
}

.mfp-zoom-in .mfp-with-anim {
  opacity: 0;
  transition: all 0.2s ease-in-out;
  transform: scale(0.8);
}

.mfp-zoom-in.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.mfp-zoom-in.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: scale(1);
}

.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-zoom-in.mfp-removing .mfp-with-anim {
  transform: scale(0.8);
  opacity: 0;
}

.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

/*-------- 2.12 Faq --------*/
.box_style_cat {
  background: #fff;
  border: 1px solid #ededed;
  margin-bottom: 25px;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
}

@media (max-width: 991px) {
  .box_style_cat {
    display: none;
  }
}

ul#cat_nav {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul#cat_nav li {
  border-bottom: 1px solid #ededed;
}

ul#cat_nav li i {
  font-size: 16px;
  font-size: 1rem;
  margin: 2px 10px 0 0;
  float: left;
}

ul#cat_nav li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

ul#cat_nav li a {
  position: relative;
  color: #555555;
  display: block;
  padding: 15px 10px;
}

ul#cat_nav li a:after {
  font-family: "fontello";
  content: "\e89b";
  position: absolute;
  right: 15px;
  top: 15px;
}

ul#cat_nav li a span {
  font-size: 11px;
  font-size: 0.6875rem;
  color: #999;
}

ul#cat_nav li a:hover,
ul#cat_nav li a#active,
ul#cat_nav li a.active {
  color: #004dda;
}

.hero_in form {
  animation-delay: 1.3s;
  -webkit-animation-delay: 1.3s;
  -moz-animation-delay: 1.3s;
}

a.box_topic {
  text-align: center;
  background: #fff;
  padding: 40px 30px 15px 30px;
  display: block;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  color: #555555;
  margin-bottom: 30px;
  overflow: hidden;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

@media (max-width: 767px) {
  a.box_topic {
    padding: 20px 20px 0 20px;
  }
}

a.box_topic:hover {
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
  transform: translateY(-10px);
}

a.box_topic i {
  font-size: 60px;
  font-size: 3.75rem;
  color: #32a067;
  margin-bottom: 10px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  border-radius: 50px;
  background-color: #2c8e5b;
  background-color: rgba(50, 160, 103, 0.05);
  width: 100px;
  height: 100px;
  line-height: 100px;
  margin-bottom: 15px;
}

a.box_topic h3 {
  font-size: 18px;
  font-size: 1.125rem;
}

.list_articles ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list_articles ul li {
  float: left;
  width: 45%;
  margin-right: 45px;
  padding: 15px 0;
  border-bottom: 1px solid #ededed;
}

.list_articles ul li a {
  color: #555555;
  display: block;
}

.list_articles ul li a:hover {
  color: #004dda;
  -webkit-transform: translateX(5px);
  -moz-transform: translateX(5px);
  -ms-transform: translateX(5px);
  -o-transform: translateX(5px);
  transform: translateX(5px);
}

.list_articles ul li i {
  margin-right: 10px;
  font-size: 16px;
  font-size: 1rem;
  position: relative;
  top: 2px;
}

@media (max-width: 991px) {
  .list_articles ul li {
    float: none;
    width: 100%;
    margin-right: 0;
  }
}

ul.list_3 {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.list_3 li {
  margin-bottom: 0;
  position: relative;
  padding-left: 20px;
}

ul.list_3 li strong {
  font-weight: 500;
}

ul.list_3 li:before {
  font-family: "fontello";
  content: "\ea3e";
  position: absolute;
  left: 0;
  top: 0;
}

.box_general {
  border: 1px solid #ededed;
  padding: 25px 25px 5px 25px;
  margin-bottom: 25px;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
}

/*-------- 2.13 Bookings --------*/
.box_booking {
  background-color: #fff;
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.strip_booking {
  border-bottom: 1px solid #ededed;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.strip_booking.last {
  border-bottom: none;
}

.strip_booking h3 {
  font-size: 16px;
  font-size: 1rem;
  text-transform: uppercase;
  position: relative;
  top: 10px;
  padding-left: 65px;
}

@media (max-width: 767px) {
  .strip_booking h3 {
    margin: 15px 0;
  }
}

.strip_booking h3 span {
  color: #999;
  display: block;
  font-size: 11px !important;
}

.strip_booking .date {
  background-color: #f8f8f8;
  text-align: center;
  line-height: 1;
}

.strip_booking .date .month {
  background-color: #32a067;
  display: block;
  padding: 3px 0;
  color: #fff;
  text-transform: uppercase;
}

.strip_booking .date .day {
  padding: 15px;
  margin-top: 1px;
  color: #999;
  text-transform: uppercase;
  display: block;
  font-size: 12px;
  font-size: 0.75rem;
}

.strip_booking .date .day strong {
  font-size: 32px;
  font-size: 2rem;
  font-weight: normal;
  display: block;
  color: #32a067;
  margin-bottom: 3px;
}

.strip_booking h3.hotel_booking:before,
.strip_booking h3.restaurant_booking:before,
.strip_booking h3.bars_booking:before {
  font-style: normal;
  font-weight: normal;
  font-family: "fontello";
  font-size: 24px;
  font-size: 1.5rem;
  color: #999;
  position: absolute;
  border: 1px solid #ededed;
  width: 50px;
  height: 50px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  left: 0;
  top: -10px;
  text-align: center;
  line-height: 50px;
}

.strip_booking h3.hotel_booking:before {
  content: "\ed72";
}

.strip_booking h3.restaurant_booking:before {
  content: "\ed80";
}

.strip_booking h3.bars_booking:before {
  content: "\ed54";
}

ul.info_booking {
  list-style: none;
  margin: 0;
  padding: 15px 25px 15px 0;
  font-size: 11px;
  font-size: 0.6875rem;
  color: #999;
  line-height: 12px;
  border-right: 1px solid #ededed;
  text-align: right;
}

ul.info_booking li {
  margin-bottom: 5px;
}

ul.info_booking li strong {
  text-transform: uppercase;
  display: block;
  color: #555;
}

.booking_buttons {
  margin-top: 20px;
}

a.btn_2,
.btn_2,
a.btn_3,
.btn_3 {
  border: none;
  font-family: inherit;
  font-size: inherit;
  color: #fff;
  background: #004dda;
  cursor: pointer;
  padding: 7px 8px;
  font-size: 11px;
  font-size: 0.6875rem;
  line-height: 9px;
  font-weight: 500;
  display: block;
  outline: none;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  text-align: center;
}

a.btn_2:hover,
.btn_2:hover,
a.btn_3:hover,
.btn_3:hover {
  background: #ffc107;
  color: #444;
}

a.btn_3,
.btn_3 {
  color: #999;
  background: #eee;
  min-width: 60px;
  text-align: center;
  margin-top: 3px;
}

/*============================================================================================*/
/* 3.  COMMON */
/*============================================================================================*/
/*-------- 3.1 Misc --------*/
.closed .vertical {
  transition: all 0.5s ease-in-out;
  transform: rotate(-90deg);
}

.closed .horizontal {
  transition: all 0.5s ease-in-out;
  transform: rotate(-90deg);
  opacity: 1;
}

.opened {
  opacity: 1;
}

.opened .vertical {
  transition: all 0.5s ease-in-out;
  transform: rotate(90deg);
}

.opened .horizontal {
  transition: all 0.5s ease-in-out;
  transform: rotate(90deg);
  opacity: 0;
}

.circle-plus {
  height: 14px;
  width: 14px;
  display: block;
  position: relative;
  float: right;
  margin-top: 5px;
}

.circle-plus .horizontal {
  position: absolute;
  background-color: #999;
  width: 14px;
  height: 2px;
  left: 50%;
  margin-left: -8px;
  top: 50%;
  margin-top: 0;
}

.circle-plus .vertical {
  position: absolute;
  background-color: #999;
  width: 2px;
  height: 14px;
  left: 50%;
  margin-left: -2px;
  top: 50%;
  margin-top: -6px;
}

.form-control {
  /* height: calc(2.55rem + 2px); */
  font-size: 14px;
  font-size: 0.875rem;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #d2d8dd;
}

.form-group {
  position: relative;
}

.bg_color_1 {
  background-color: #fff;
}

.box_1 {
  background-color: #121921;
  color: #fff;
  padding: 45px;
  opacity: 0;
}

.box_1 p {
  opacity: 0.8;
}

.box_1 h3 {
  color: #fff;
}

.animated .box_1 {
  animation: color 0.3s ease-in-out;
  animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}

.call_section {
  background-color: #004dda;
  position: relative;
  color: #fff;
}

.call_section h2 {
  color: #fff;
}

.call_section .wrapper {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.7);
  position: relative;
  z-index: 22;
}

.call_section .box_how {
  text-align: center;
  position: relative;
}

.call_section .box_how h3 {
  color: #fff;
  font-size: 18px;
  font-size: 1.125rem;
}

.call_section .box_how i {
  color: #fff;
  font-size: 60px;
  font-size: 3.75rem;
  margin-bottom: 10px;
  color: #ffc107;
}

.call_section.pattern {
  background: #004dda url(../img/pattern.svg) center center repeat fixed;
}

.call_section.pattern .wrapper {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}

.call_section.image_bg {
  background: #004dda url(../img/bg_call_section.jpg) center center no-repeat fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.call_section.image_bg .wrapper {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.8);
}

#hero-canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.clear:after,
.pagination:after,
.clear:before,
.pagination:before {
  content: "";
  display: table;
}

.clear:after,
.pagination:after {
  clear: both;
}

.pagination__wrapper {
  height: 50px;
  padding-top: 10px;
  margin-bottom: 0;
  position: relative;
  text-align: center;
  width: 100%;
}

.pagination__wrapper:before {
  top: -1px;
}

.pagination__wrapper:after {
  bottom: -1px;
}

@keyframes hoverAnimation {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.pagination {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
}

.pagination li {
  display: block;
  float: left;
  padding: 5px;
}

.pagination li:first-child {
  border: none;
}

.pagination a,
.pagination span {
  /* background: none;
  border: none;
  border-radius: 50%;
  box-sizing: border-box; */
  color: #fe900d;
  display: block;
  /* font-size: 16px;
  height: 40px;
  line-height: 40px;
  min-width: 40px;
  padding: 0; */
}

.page-item.active .page-link {
  background-color: #fe900d;
  color: #fff;
  border: none;
}

.pagination a {
  outline: none;
  position: relative;
  transition: all 170ms linear;
}

.pagination a:before {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  content: "";
  cursor: pointer;
  height: 0;
  left: 50%;
  opacity: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  transition: all 170ms linear;
  top: 50%;
  width: 0;
}

.pagination a:hover:not(.active) {
  color: black;
}

.pagination a:hover:not(.active):before {
  animation: hoverAnimation 510ms linear forwards;
  height: 40px;
  width: 40px;
}

.pagination a.active {
  background: #004dda;
  color: white;
  cursor: default;
}

.pagination .prev,
.pagination .next {
  font-size: 14px;
}

.box_faq {
  position: relative;
  padding-left: 50px;
}

.box_faq h4 {
  font-size: 18px;
  font-size: 1.125rem;
}

.box_faq i {
  font-size: 36px;
  font-size: 2.25rem;
  position: absolute;
  left: 0;
  top: 0;
  color: #999;
}

.custom-select-form .nice-select {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #d2d8dd;
  height: 45px;
  line-height: 42px;
}

.custom-select-form .nice-select:hover {
  border-color: #d2d8dd;
}

.custom-select-form .nice-select:active,
.custom-select-form .nice-select.open,
.custom-select-form .nice-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-select-form select {
  display: none;
}

ul.share-buttons {
  padding: 0;
  list-style: none;
  text-align: center;
  margin: 0 0 25px 0;
}

ul.share-buttons li {
  display: inline-block;
  margin: 0 5px 5px 5px;
}

ul.share-buttons li a {
  background: #fff;
  border: 1px solid #ededed;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  font-weight: 500;
  font-size: 13px;
  font-size: 0.8125rem;
  padding: 7px 20px;
  transition: 0.3s;
  display: inline-block;
  line-height: 17px;
  font-weight: 500;
}

ul.share-buttons li a:hover {
  color: #fff;
}

ul.share-buttons li a.fb-share {
  color: #3b5998;
}

ul.share-buttons li a.fb-share:hover {
  background: #3b5998;
  color: #fff;
  border-color: #3b5998;
}

ul.share-buttons li a.gplus-share {
  color: #dd4b39;
}

ul.share-buttons li a.gplus-share:hover {
  background: #dd4b39;
  color: #fff;
  border-color: #dd4b39;
}

ul.share-buttons li a.twitter-share {
  color: #1da1f2;
}

ul.share-buttons li a.twitter-share:hover {
  background: #1da1f2;
  color: #fff;
  border-color: #1da1f2;
}

ul.share-buttons li i {
  font-size: 16px;
  font-size: 1rem;
  position: relative;
  right: 3px;
  top: 2px;
}

/* Jquery select */
.nice-select {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
  border-radius: 3px;
  border: none;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  height: 50px;
  line-height: 48px;
  outline: none;
  padding-left: 15px;
  padding-right: 27px;
  position: relative;
  text-align: left !important;
  transition: all 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
  width: auto;
  color: #555555;
  padding-top: 2px;
}

.nice-select:hover {
  border-color: #8c8c8c;
}

.nice-select:active,
.nice-select.open,
.nice-select:focus {
  border-color: #999999;
}

.nice-select:after {
  border-bottom: 2px solid #999999;
  border-right: 2px solid #999999;
  content: '';
  display: block;
  height: 8px;
  margin-top: -5px;
  pointer-events: none;
  position: absolute;
  right: 20px;
  top: 50%;
  transform-origin: 66% 66%;
  transform: rotate(45deg);
  transition: all 0.15s ease-in-out;
  width: 8px;
}

.nice-select.open:after {
  transform: rotate(-135deg);
}

.nice-select.open .list {
  opacity: 1;
  pointer-events: auto;
  transform: scale(1) translateY(0);
}

.nice-select.disabled {
  border-color: #9e9e9e;
  color: #999999;
  pointer-events: none;
}

.nice-select.disabled:after {
  border-color: #cccccc;
}

.nice-select.wide {
  width: 100%;
}

.nice-select.wide .list {
  left: -1px !important;
  right: -1px !important;
}

.nice-select.right {
  float: right;
}

.nice-select.right .list {
  left: auto;
  right: 0;
}

.nice-select.small {
  font-size: 12px;
  height: 36px;
  line-height: 34px;
}

.nice-select.small:after {
  height: 4px;
  width: 4px;
}

.nice-select.small .option {
  line-height: 34px;
  min-height: 34px;
}

.nice-select .list {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  transform-origin: 50% 0;
  transform: scale(0.75) translateY(-25px);
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 9999;
  height: 23vh;
  overflow: auto;
}

.nice-select .list:hover .option:not(:hover) {
  background-color: transparent !important;
}

.nice-select .list::-webkit-scrollbar {
  width: 14px;
  height: 18px;
}

.nice-select .list::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

.nice-select .list::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

.nice-select .list::-webkit-scrollbar-corner {
  background-color: transparent;
}

.nice-select .option {
  cursor: pointer;
  font-weight: 400;
  line-height: 38px;
  list-style: none;
  min-height: 38px;
  outline: none;
  padding-left: 15px;
  padding-right: 26px;
  text-align: left;
  transition: all 0.2s;
}

.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
  background-color: #f6f6f6;
}

.nice-select .option.selected {
  font-weight: 500;
}

.nice-select .option.disabled {
  background-color: transparent;
  color: #999999;
  cursor: default;
}

.no-csspointerevents .nice-select .list {
  display: none;
}

.no-csspointerevents .nice-select.open .list {
  display: block;
}

/* Scroll top button */
#toTop {
  position: fixed;
  right: 25px;
  bottom: 25px;
  z-index: 9999;
  width: 46px;
  height: 46px;
  background-color: #fe900d;
  opacity: 1;
  border-radius: 50%;
  text-align: center;
  font-size: 21px;
  color: #fff;
  cursor: pointer;
}

@media (max-width: 767px) {
  #toTop {
    display: none !important;
  }
}

#toTop:after {
  content: "\e899";
  font-family: "fontello";
  position: relative;
  display: block;
  top: 50%;
  -webkit-transform: translateY(-55%);
  transform: translateY(-55%);
}

/* #toTop:hover {
  background-color: #004dda;
} */

/*-------- 3.2 Accordion --------*/
.accordion_2 .card {
  border: 0;
  margin-bottom: 5px;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
}

.accordion_2 .card .card-header {
  background-color: #fff;
  border-bottom: 1px solid #ededed;
  padding: 20px;
}

.accordion_2 .card-body {
  padding-bottom: 0;
}

.accordion_2 .card-header h5 {
  font-size: 16px;
  font-size: 1rem;
}

.accordion_2 .card-header h5 a {
  border: 0;
  display: block;
  color: #999;
}

.accordion_2 .card-header h5 a i.indicator {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: normal;
  float: right;
  color: #004dda;
}

.card {
  border-radius: 0;
  border: 0;
  margin-bottom: 5px;
}

/*-------- 3.3 List --------*/
ul.list_ok {
  list-style: none;
  margin: 0 0 30px 0;
  padding: 0;
}

ul.list_ok li {
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
}

ul.list_ok li:before {
  font-family: 'ElegantIcons';
  content: "\52";
  position: absolute;
  left: 0;
  top: -5px;
  font-size: 18px;
  font-size: 1.125rem;
  color: #32a067;
}

ul.bullets {
  line-height: 1.8;
  margin: 0;
  padding: 0;
}

ul.bullets li {
  position: relative;
  padding-left: 23px;
}

ul.bullets li:before {
  font-style: normal;
  font-weight: normal;
  font-family: 'ElegantIcons';
  font-size: 14px;
  font-size: 0.875rem;
  content: "\e052";
  color: #32a067;
  position: absolute;
  left: 0;
  top: 0;
}

/*-------- 3.4 Spacing --------*/
.add_bottom_10 {
  margin-bottom: 10px;
}

.add_bottom_15 {
  margin-bottom: 15px;
}

.add_bottom_30 {
  margin-bottom: 30px;
}

.add_bottom_45 {
  margin-bottom: 45px;
}

.add_bottom_60 {
  margin-bottom: 60px;
}

.add_bottom_75 {
  margin-bottom: 75px;
}

.add_top_10 {
  margin-top: 10px;
}

.add_top_15 {
  margin-top: 15px;
}

.add_top_20 {
  margin-top: 20px;
}

.add_top_30 {
  margin-top: 30px;
}

.add_top_60 {
  margin-top: 60px;
}

.more_padding_left {
  padding-left: 40px;
}

.nomargin_top {
  margin-top: 0;
}

.nopadding {
  margin: 0 !important;
  padding: 0 !important;
}

.nomargin {
  margin: 0 !important;
}

.margin_30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.margin_30_5 {
  padding-top: 30px;
  padding-bottom: 5px;
}

.margin_60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (max-width: 767px) {
  .margin_60 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.margin_60_35 {
  padding-top: 60px;
  padding-bottom: 35px;
}

@media (max-width: 575px) {
  .margin_60_35 {
    padding-top: 30px;
    padding-bottom: 5px;
  }
}

.margin_80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

@media (max-width: 991px) {
  .margin_80 {
    padding-bottom: 60px;
    padding-top: 60px;
  }
}

@media (max-width: 575px) {
  .margin_80 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.margin_80_55 {
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (max-width: 991px) {
  .margin_80_55 {
    padding-top: 60px;
    padding-bottom: 35px;
  }
}

@media (max-width: 575px) {
  .margin_80_55 {
    padding-top: 45px;
    padding-bottom: 15px;
  }
}

.margin_80_0 {
  padding-top: 80px;
}

@media (max-width: 991px) {
  .margin_80_0 {
    padding-top: 60px;
  }
}

@media (max-width: 575px) {
  .margin_80_0 {
    padding-top: 30px;
  }
}

.margin_30_95 {
  padding-top: 30px;
  padding-bottom: 95px;
}

@media (max-width: 991px) {
  .margin_30_95 {
    padding-top: 15px;
    padding-bottom: 35px;
  }
}

@media (max-width: 575px) {
  .margin_30_95 {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

@media (max-width: 991px) {
  .hidden_tablet {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden_mobile {
    display: none !important;
  }
}

/*-------- 3.5 Block reveal --------*/
.animated .block-reveal {
  height: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.block-horizzontal,
.block-vertical {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 10;
}

.animated .block-horizzontal {
  animation: reveal_left_to_right 1s ease-in-out;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  background: #0054a6;
}

.animated .block-vertical {
  animation: reveal_top_to_bottom 1s ease-in-out;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  background: #0054a6;
}

.block-reveal img {
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  opacity: 0;
}

.animated .block-reveal img {
  animation: color 0.5s ease-in-out;
  animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}

@keyframes reveal_left_to_right {
  0% {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  50% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@keyframes reveal_top_to_bottom {
  0% {
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  50% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -o-transform: translateY(100%);
    transform: translateY(100%);
  }
}

@keyframes color {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/*-------- 3.6 Cookie bar --------*/
#cookie-bar {
  background: rgba(0, 0, 0, 0.8);
  height: auto;
  line-height: 24px;
  color: #eeeeee;
  text-align: center;
  padding: 15px 0;
  font-weight: 400;
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 500;
}

#cookie-bar p {
  margin: 0;
  padding: 0;
}

#cookie-bar a {
  color: #fff;
  display: inline-block;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  text-decoration: none;
  padding: 2px 10px;
  margin-left: 8px;
}

@media (max-width: 767px) {
  #cookie-bar a {
    display: block;
    max-width: 150px;
    margin: auto;
    margin-bottom: 5px;
  }
}

#cookie-bar .cb-enable {
  background: #32a067;
}

#cookie-bar .cb-enable:hover {
  background: #fff;
  color: #0054a6;
}

#cookie-bar .cb-disable {
  background: #990000;
}

#cookie-bar .cb-disable:hover {
  background: #bb0000;
}

#cookie-bar .cb-policy {
  background: #004dda;
}

#cookie-bar .cb-policy:hover {
  background: #fff;
  color: #0054a6;
}

#cookie-bar.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999999;
}

#cookie-bar.fixed.bottom {
  bottom: 0;
  top: auto;
}

@media (max-width: 767px) {
  #cookie-bar {
    padding: 5px 0;
  }
}

/*-------- 3.7 Sing In Modal --------*/
.dialog-mfp {
  background: #fff;
  padding: 15px;
  padding-top: 0;
  text-align: left;
  max-width: 400px;
  margin: 60px auto;
  position: relative;
  box-sizing: border-box;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}

/* Popup close button*/
.dialog-mfp .mfp-close,
#lang-dialog .mfp-close {
  color: #666;
  background-color: #e4e4e4;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  top: 12px;
  right: 20px;
  width: 32px;
  height: 32px;
  line-height: 32px;
}

.dialog-mfp .mfp-close:hover,
#lang-dialog .mfp-close:hover {
  color: #fff;
  background-color: #66676b;
}

.dialog-mfp .mfp-close:before {
  font-size: 24px;
  font-family: 'ElegantIcons';
  content: "\4d";
}

.small-dialog-header {
  font-size: 18px;
  width: calc(100% + 30px);
  position: relative;
  left: -15px;
  top: 0;
  -webkit-border-radius: 4px 4px;
  -moz-border-radius: 4px 4px;
  -ms-border-radius: 4px 4px;
  border-radius: 4px 4px;
  display: inline-block;
  background-color: #f6f6f6;
  padding: 10px 20px;
  margin-bottom: 30px;
}

.small-dialog-header h3 {
  padding: 0;
  margin: 0;
  font-size: 30px;
  /* font-size: 1.125rem; */
  font-weight: 500;
}

.dialog-mfp .form-group {
  position: relative;
}

.dialog-mfp .form-group input.form-control {
   padding-left: 40px;                         /*from this line we add extra space between icon and textbox name*/
}

.dialog-mfp .form-group i {
  font-size: 21px;
  font-size: 1.3125rem;
  position: absolute;
  /* left: 12px;
  top: 9px !important; */
  color: #ccc;
  width: 25px;
  height: 25px;
  display: block;
  font-weight: 400 !important;
  top:-6px;
}

.dialog-mfp .form-group i.icon_lock_alt {
  /* top: 36px; */
}

.my-toggle {
  background: transparent;
  border: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  color: #888;
  cursor: pointer;
  font-size: 10px;
  font-size: 10px;
  font-size: 0.625rem;
  font-weight: bold;
  margin-right: 5px;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  text-transform: uppercase;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: #fff;
}

.my-toggle:hover,
.my-toggle:focus {
  background-color: #eee;
  color: #555;
  outline: transparent;
}

.hideShowPassword-wrapper {
  width: 100% !important;
}

.checkboxes label {
  color: #8b7979;
  margin-top: 5px;
  font-size: 17px;
}

a#forgot {
  color: #999;
  font-weight: 500;
  font-size: 13px;
  font-size: 0.8125rem;
}

#forgot_pw {
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;
  min-height: 430px;
  display: none;
  padding: 25px;
}

@media (max-width: 767px) {
  #forgot_pw {
    padding: 0;
  }
}

#forgot_pw label {
  font-weight: 500;
}

.sign-in-wrapper {
  position: relative;
  height: 100%;
}

a.social_bt {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  text-align: center;
  color: #fff;
  min-width: 200px;
  margin-bottom: 15px;
  display: block;
  padding: 12px;
  line-height: 1;
  position: relative;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

a.social_bt:hover {
  -webkit-filter: brightness(115%);
  filter: brightness(115%);
}

a.social_bt.facebook:before,
a.social_bt.google:before,
a.social_bt.linkedin:before {
  font-family: 'ElegantIcons';
  position: absolute;
  left: 12px;
  top: 10px;
  font-size: 1rem;
  color: #fff;
}

a.social_bt.facebook {
  background-color: #3B5998;
}

a.social_bt.facebook:before {
  content: "\e093";
}

a.social_bt.google {
  background-color: #DC4E41;
}

a.social_bt.google:before {
  content: "\e096";
  top: 12px;
}

.divider {
  text-align: center;
  height: 1px;
  margin: 30px 0 20px 0;
  background-color: #e1e8ed;
}

.divider span {
  position: relative;
  top: -20px;
  background-color: #fff;
  display: inline-block;
  padding: 10px;
  font-style: italic;
}

/* Magnific popup */
.mfp-bottom-bar {
  padding: 0 10px !important;
}

.mfp-counter {
  right: 10px !important;
  color: white !important;
}

.mfp-bg {
  opacity: 0.8 !important;
}

/*-------- 3.8 Datepicker --------*/
.applyBtn.btn.btn-sm.btn-primary {
  background-color: #004dda;
  border: 0;
}

.drp-selected {
  display: none !important;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #0054a6 !important;
}

/*-------- Styles Switcher --------*/
#style-switcher {
  background: #fff;
  width: 205px;
  position: fixed;
  top: 205px;
  z-index: 99;
  left: -205px;
  border-radius: 0 0 4px 0;
  -webkit-box-shadow: 0 6px 15px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.175);
}

#style-switcher h3 {
  color: #fff;
  font-size: 13px;
  margin: 10px 0;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 0;
}

#style-switcher ul {
  list-style: none;
  padding: 0;
  margin-bottom: 22px;
}

#style-switcher div {
  padding: 6px 18px;
}

#style-switcher h6 {
  background: #f8f8f8;
  color: #333;
  font-size: 14px;
  line-height: 46px;
  padding: 0 0 0 20px;
  margin-top: 0;
  font-weight: 500;
}

#style-switcher h6 a {
  background-color: #f8f8f8;
  display: block;
  height: 46px;
  position: absolute;
  right: -46px;
  top: 0;
  width: 46px;
  border-radius: 0 4px 4px 0;
  font-size: 22px;
  color: #fff;
  text-align: center;
}

#style-switcher h6 a i {
  position: relative;
  top: 4px;
  color: #333;
}

.colors {
  list-style: none;
  margin: 0 0px 10px 0;
  overflow: hidden;
}

.colors li {
  float: left;
  margin: 4px 2px 0 2px;
}

.colors li a {
  display: block;
  width: 17px;
  height: 17px;
  cursor: pointer;
}

.default {
  background: #004dda;
}

.aqua {
  background: #1cbbb4;
}

.green_switcher {
  background: #82ca9c;
}

.orange {
  background: #f7941d;
}

.beige {
  background: #bea882;
}

.gray {
  background: #555555;
}

.green-2 {
  background: #18a84c;
}

.navy {
  background: #4a5d7d;
}

.peach {
  background: #faaa5d;
}

.purple {
  background: #6449e7;
}

.red {
  background: #f0151f;
}

.violet {
  background: #990099;
}

.colors li a.active {
  box-shadow: 0 0 0 2px #fff inset;
}

.colors li a {
  border-radius: 3px;
}

@media only screen and (max-width: 1024px) {
  #style-switcher {
    display: none;
  }
}


/*-------- Blog --------*/
article.blog {
  margin-bottom: 30px;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}

article.blog figure {
  height: 260px;
  overflow: hidden;
  position: relative;
  margin-bottom: 0;
}

@media (max-width: 991px) {
  article.blog figure {
    height: 210px;
  }
}

@media (max-width: 767px) {
  article.blog figure {
    height: 220px;
  }
}

article.blog figure .preview {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -12px;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
  text-align: center;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  z-index: 2;
}

article.blog figure .preview span {
  background-color: #fcfcfc;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  border-radius: 20px;
  display: inline-block;
  color: #222;
  font-size: 0.75rem;
  padding: 5px 10px;
}

article.blog figure:hover .preview {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

article.blog figure img {
  -webkit-transform: translate(-50%, -50%) scale(1);
  -moz-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -o-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  -moz-transition: 0.3s;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: auto;
  height: 400px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}

article.blog figure img:hover {
  -webkit-transform: translate(-50%, -50%) scale(1.1);
  -moz-transform: translate(-50%, -50%) scale(1.1);
  -ms-transform: translate(-50%, -50%) scale(1.1);
  -o-transform: translate(-50%, -50%) scale(1.1);
  transform: translate(-50%, -50%) scale(1.1);
}

@media (max-width: 991px) {
  article.blog figure img {
    height: inherit;
    max-width: 100%;
  }
}

article.blog .post_info {
  padding: 20px 30px 30px 30px;
  position: relative;
  box-sizing: border-box;
}

article.blog .post_info small {
  font-weight: 600;
  color: #999;
  font-size: 13px;
  font-size: 0.8125rem;
}

article.blog .post_info h2 {
  font-size: 21px;
  font-size: 1.3125rem;
}

article.blog .post_info h2 a {
  color: #333;
}

article.blog .post_info h2 a:hover {
  color: #004dda;
}

article.blog .post_info ul {
  margin: 0 -30px 0 -30px;
  padding: 20px 30px 0 30px;
  width: 100%;
  box-sizing: content-box;
  border-top: 1px solid #ededed;
}

article.blog .post_info ul li {
  display: inline-block;
  position: relative;
  padding: 12px 0 0 50px;
  font-weight: 500;
  font-size: 12px;
  font-size: 0.75rem;
  color: #999;
}

article.blog .post_info ul li .thumb {
  width: 40px;
  height: 40px;
  overflow: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
}

article.blog .post_info ul li .thumb img {
  width: 40px;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

article.blog .post_info ul li:last-child {
  float: right;
  padding-left: 0;
  line-height: 1;
}

article.blog .post_info ul li:last-child i {
  font-size: 14px;
  font-size: 0.875rem;
  margin-right: 5px;
  position: relative;
  top: 3px;
}

@media (max-width: 991px) {
  article.blog .post_info ul {
    position: static;
    width: auto;
  }
}

/*Blog Sidebar*/
.alignleft {
  float: left;
  margin: 0 15px 10px 0;
  width: 80px;
  height: 80px;
  overflow: hidden;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  position: relative;
}

.alignleft img {
  width: auto;
  height: 80px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.comments-list {
  padding: 0;
  list-style: none;
}

.comments-list h3 {
  font-size: 14px;
  padding: 0 0 0;
  margin-bottom: 0;
  margin-top: 5px;
  text-transform: capitalize;
  letter-spacing: 0;
}

.comments-list h3 a {
  color: #555;
}

.comments-list h3 a:hover {
  color: #004dda;
}

.comments-list li {
  margin-bottom: 10px;
  display: table;
  width: 100%;
}

.comments-list li:last-child {
  margin-bottom: 0;
}

.comments-list small {
  color: #555;
}

.widget-title {
  padding: 10px 0;
  margin-bottom: 12px;
  border-bottom: 1px solid #dddddd;
}

.widget-title h4 {
  padding: 0;
  margin: 0;
  font-weight: 500;
  line-height: 1;
  font-size: 16px;
  font-size: 1rem;
}

.widget {
  position: relative;
  display: block;
  margin-bottom: 15px;
}

.widget ul.cats {
  list-style: none;
  padding: 0;
}

.widget ul.cats li {
  padding: 0 0 5px 2px;
  position: relative;
  font-weight: 500;
}

.widget ul.cats a {
  color: #555;
}

.widget ul.cats a:hover {
  color: #004dda;
}

.widget ul.cats a span {
  position: absolute;
  right: 0;
}

.tags a {
  background-color: #f0f0f0;
  padding: 3px 10px;
  font-size: 13px;
  margin: 0 0 4px;
  letter-spacing: 0.4px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  display: inline-block;
  color: #333;
}

.tags a:hover {
  background-color: #004dda;
  color: #fff;
}

.search_blog .form-group {
  position: relative;
}

.search_blog .form-group input[type="submit"] {
  border: 0;
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #004dda;
  color: #fff;
  font-weight: 500;
  height: 32px;
  padding: 0 10px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.search_blog .form-group input[type="submit"]:hover {
  background-color: #0054a6;
}

/*Blog Post*/
.singlepost {
  background-color: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  padding: 30px 30px 1px 30px;
  margin-bottom: 25px;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}

.singlepost figure {
  margin: -30px -30px 30px -30px;
}

.singlepost h1 {
  font-size: 28px;
  font-size: 1.75rem;
}

@media (max-width: 767px) {
  .singlepost h1 {
    font-size: 21px;
    font-size: 1.3125rem;
  }
}

.singlepost p {
  line-height: 1.8;
}

.postmeta {
  padding-bottom: 10px;
}

.postmeta ul {
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
}

.postmeta ul li {
  display: inline-block;
  margin: 0 15px 5px 0;
}

.postmeta ul li a {
  color: #777;
}

.postmeta ul li a:hover {
  color: #004dda;
}

.dropcaps p:first-child::first-letter {
  color: #fff;
  background-color: #004dda;
  float: left;
  font-size: 64px;
  font-size: 4rem;
  line-height: 1;
  margin: 10px 15px 0 0 !important;
  padding: 12px;
}

#comments {
  padding: 10px 0 0px 0;
  margin-bottom: 30px;
}

#comments ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

#comments ul li {
  padding: 25px 0 0 0;
  list-style: none;
}

#comments .replied-to {
  margin-left: 35px;
}

@media (max-width: 767px) {
  #comments .replied-to {
    margin-left: 20px;
  }
}

.avatar {
  float: left;
  margin-right: 25px;
  width: 68px;
  height: 68px;
  overflow: hidden;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  position: relative;
}

@media (max-width: 767px) {
  .avatar {
    float: none;
    margin: 0 0 5px 0;
  }
}

.avatar img {
  width: 68px;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.comment_right {
  display: table;
  background-color: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  padding: 20px 20px 0 20px;
  position: relative;
  border: 1px solid #ededed;
}

.comment_right:after,
.comment_right:before {
  right: 100%;
  top: 30px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

.comment_right:after {
  border-color: rgba(255, 255, 255, 0);
  border-right-color: #fff;
  border-width: 15px;
  margin-top: -15px;
}

.comment_right:before {
  border-color: rgba(0, 0, 0, 0);
  border-width: 16px;
  margin-top: -16px;
  border-right-color: #ededed;
}

.comment_info {
  padding-bottom: 7px;
}

.comment_info span {
  padding: 0 10px;
}



/* ------------------- 10 May 2023 ---------------- */

.dropdown-item i {
  font-size: 18px;
  margin-right: 10px;
}

.add_listing_card {
  position: relative;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 5px;
  box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 20%);
}

.add_listing_card_title {
  font-size: 18px;
  color: #444;
}

.add_listing_card:hover .add_listing_card_title {
  color: #fe900d;
}

.btn-outline-warning {
  color: #444;
  background-color: #fff;
  background-image: none;
  border-color: #444;
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
  box-shadow: none !important;
  outline: none;
  border: 1px solid #444;
}

.btn-outline-warning:hover {
  color: #444;
  background-color: #fff;
  background-image: none;
  border-color: #444;
  box-shadow: none;
}

.dropdown a:after {
  display: none;
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show>.btn-outline-warning.dropdown-toggle {
  background-color: transparent;
}

#mob_view {
  display: none;
}

.back_btn {
  text-decoration: underline;
  font-size: 16px;
}

.back_btn:hover {
  color: #000 !important;
}

.owl-theme .owl-dots .owl-dot {
  display: none !important;
}


/* --------------- Contact ------------ */

.contact_card {
  background-color: #fff;
  padding: 30px 20px;
  border-radius: 5px;
  width: 100%;
  height: 230px;
  box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 20%);

}

.contact_img_sec {
  display: block;
  margin: auto;
  width: 15%;
}

.phone_img {
  width: 55px;
  height: 55px;
}

.email_img {
  width: 65px;
  height: 50px;
}

.address_img {
  width: 50px;
  height: 55px;
}

.contact_img {
  width: 100%;
  height: 100%;
}

.contact_heading {
  font-size: 22px;
  text-align: center;
  margin-top: 20px;
  font-weight: 500;
  color: #000;
  text-transform: uppercase;
  /* letter-spacing: 1px; */
}

.contact_text,
.contact_text a {
  font-size: 14px;
  color: #404041;
  text-align: center;
  margin-top: 10px;
}

.contact_form {
  /* background-color: #444; */
  padding: 30px 30px;
  position: relative;
}

.contact_form_btn {
  background-color: #fff;
  color: #404041;
  padding: 10px 15px;
  border-radius: 25px !important;
  margin-left: 12px;
}




.vission_img {
  width: 120px;
  display: block;
  margin: auto;
}


.listing_img {
  width: 30px;
  height: 35px;
  margin-right: 10px;
}

.enq_pop {
  max-width: 600px !important;
}

.user_type {
  font-size: 22px;
  margin-bottom: 15px;
}

.user_type input[type="radio"] {
  width: 16px;
  height: 29px;
  margin-left: -22px;
}



.profile-section {
  width: 120px;
  height: 40px;
  margin: auto;
  color: transparent;
  transition: all 0.3s ease;
  display: flex;
  
  bottom: 40px;
  position: relative;
  transition: all 0.3s ease;
  margin-bottom: 100px;
  z-index: 0;
}

.profile-section input {
  display: none;
}

.profile-section img {
  position: absolute;
  width: 120px;
  height: 120px;
  z-index: -1;
  border-radius: 100%;
  margin-top: 50px;
  /* margin-left: -47px; */
}

.profile-section .-label {
  cursor: pointer;
  height: 120px;
  width: 120px;
  margin: auto;
  margin-top: 50px;
  /* margin-left: 47px; */
  border: 1px solid gray;
  border-radius: 50%;
}

.profile-section:hover .-label {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10000;
  color: #fafafa;
  transition: background-color 0.2s ease-in-out;
  border-radius: 100px;
  margin-bottom: 0;
}

.profile-section span {
  display: inline-flex;
  font-size: 12px;
  margin: 0 2px;
}

.profile_customer_name {
  font-size: 14px;
  color: #555555;
}

.upload_img_sec {
  position: relative;
  width: 100%;
  height: 100%;
}

.upload_imagesbanner {
  width: 100%;
  height: 220px;
  object-fit: cover !important;
}

.upload_imagesgallery {
  
  height: 100%;
}

.upload_images {
  width: 100%;
  height: 100%;
}


.delete_img {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 5px;
  right: 5px;
  cursor: pointer;
  z-index: 10000;
}

.img_title {
  font-size: 16px;
}


.profile-sidebar-content .hide {
  display: none;
}


.enquiry_list_heading span {
  float: right;
}

.enquiry_list_heading {
  font-size: 16px;
  color: gray;
}

.enquiry_list_txt {
  font-size: 16px;
  color: #000;
}

.enquiry_date {
  position: absolute;
  right: 10px;
  color: #000;
  top: 5px;
}

.chat_img_sec {
  display: block;
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.chat_img_sec img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.chat_heading {
  font-size: 20px;
  color: #000
}

.chat_text {
  font-size: 14px;
  color: gray;
  text-align: justify;
}

.admin_chat {
  position: relative;
  background-color: #F1EFEF;
  padding: 10px 10px 15px 10px;
  border-radius: 10px;
}

.admin_chat .chat_text {
  color: #000;
}

.user_chat {
  background-color: #fb830d;
  padding: 10px;
  border-radius: 10px;
}

.user_chat .chat_text {
  color: #fff;
}

.chat_body {
  height: 400px;
  overflow: auto;
  padding: 20px 30px;
}

.user_chat .chat_time {
  color: #fff;
}

.chat_time {
  position: absolute;
  right: 10px;
  bottom: 3px;
  font-size: 10px;
}

::-webkit-scrollbar {
  width: 5px;
}

/* ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 20px;
} */
::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

.upload_btn {
  margin: 5px 5px 0 5px;
  border: none;
  color: #222222;
  background: #fb830d !important;
  outline: none;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  padding: 15px 30px;
  font-weight: 600;
  text-align: center;
  line-height: 1;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  font-family: "PoppinsSemiBold";
  font-size: 16px;
}

.select {
  color: #fff;
  background: #fb830d;
}

.verify_email {
  cursor: pointer;
}

.year_gst {
  display: flex;
  justify-content: space-between;
  /* margin-top: 5px; */
}

.btn-guotes {
  background-color: orange;
  color: #fff;
  /* padding: 10px 15px !important; */ /*remove the padding of btn quotes*/
}

.user_logo_sec {
  display: block;
  margin: auto;
  width: 109px;
  height: 100px;
}

.user_log_img {
  width: 100%;
  height: 100%;
}

.review_row {
  height: 350px;
  overflow: auto;
}

.mob_reating {
  display: none;
}

.mob_reating i {
  color: #fe900d;
}

.upload_banner_img_sec {
  width: auto;
  height: 250px;
}

.certificate_img_sec {
  width: 100%;
  height: 240px;
}

.certificate_img {
  width: 100%;
  height: 100%;
}

.cleints_img_sec {
  width: 100%;
  height: 220px;
}

.cleints_img {
  width: 100%;
  height: 100%;
}

.notification .notification-list {
  position: relative;
  padding: 2px 11px 6px 22px;
  margin-bottom: -4px;
}

.notification .nitify_dot {
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #fe900d;
  left: 7px;
  top: 14px;
}

.notify_heading {
  font-size: 14px;
  color: #fe900d;
  margin-bottom: 7px;
}

.notify_txt {
  font-size: 10px;
}

.notify_body {
  height: 300px;
  padding: 0 5px;
  overflow: auto;
  margin-top: 10px;
}

.notification .small {
  font-size: 9px;
  margin-top: -3px;
}

.all_notifications .notify_heading {
  font-size: 22px;
  margin-bottom: 5px;
}

.all_notifications .notify_txt {
  font-size: 14px;
}

.all_notifications .small {
  margin-top: -6px;
}

.mobile_listing_btn {
  display: none;
  position: fixed;
  bottom: 50px;
  right: 12px;
  z-index: 1000000;
}

.mobile_listing_btn a {
  text-align: center;
  width: 60px;
  border-radius: 50%;
  padding: 18px 0px !important;
  letter-spacing: 1px;
  background-color: #fe900d;
}

.this_count {
  display: block;
  position: absolute;
  right: 18px;
  font-size: 20px;
  color: #fe900d;
}

.add_listing_card:hover .this_count {
  color: #fe900d;
}

.vd_main {
  height: 85vh;
}

.owner_reply {
  /* margin: -24px 0 0 60px; */
  border-left: 2px solid #dedddd;
  padding: 0 20px;
  margin-left: 58px;
    /* margin-top: -20px; */
}

.review_img_sec {
  width: 65px;
  height: 65px;
  display: block;
  margin: auto;
}

.btn_3 {
  border: none;
  color: #fff;
  background: #fb830d;
  outline: none;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  padding: 10px 20px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  line-height: 1;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  font-family: "PoppinsSemiBold";
  font-size: 16px;
}

a.btn_3:hover,
.btn_3:hover {
  background-color: #ffc107;
  color: #222222 !important;
}

.px_remove {
  padding: 0;
}

#create_listing span.select2-selection.select2-selection--single {
  height: calc(1.8125rem + 2px);
}

#create_listing .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #495057;
  line-height: 16px;
}

#create_listing .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 30px !important;
}

#create_listing_container {
  max-width: 950px;
}

.select_category_sec {
  padding: 42px 0;
}

.select_category_img_sec {
  display: block;
  /* margin: auto; */
  width: 170px;
  height: 140px;
}

.select_category_img {
  width: 100%;
  height: 100%;
}

@media (max-width:767px) {
  .select_category_img {
    width: 95%;
    height: 95%;
  }

  .select_category_sec {
    padding: 0px 0;
  }
}

.select_category_info h5 {
  font-size: 20px;
  color: #fb830d;
}

.select_category_info p {
  font-size: 12px;
}

.listing_icons {
  width: 45px;
  height: 45px;
  margin: 0 15px 0 0;
}

.client_first_letter {
  background-color: #d4d4d4;
  display: block;
  text-align: center;
  font-size: 100px;
  /* margin-top: -45px; */
  height: 150px;
  color: orange;
}

.chat_user_name {
  position: relative;
}

#online_indicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fe900d;
}

.chat_user_img_sec {
  display: block;
  width: 45px;
  height: 45px;
  margin-right: 10px;
  border: 1px solid lightgray;
  border-radius: 50%;
}

.chat_user_img_sec img {
  width: 100%;
  height: 100%;
}

.Count_review {
  font-style: 14px;
  color: #000;
}

.about_img_sec {
  width: 100%;
  height: 50vh;
}

.about_span {
  font-size: 16px;
  color: #fe900d;
  font-weight: 600;
}

.about_us_text {
  font-size: 14px;
  text-align: justify;
  line-height: 25px;
  letter-spacing: 0.5px;
}

.about_heading {
  color: #fe900d;
  text-transform: uppercase;
}

.founder_img_sec img {
  width: 100%;
  height: 100%;
}

.abou_ul {
  position: relative;
}

.abou_ul li:before {
  content: '✓';
  position: absolute;
  font-weight: 600;
}

.abou_ul_right li:before {
  content: '✓';
  position: absolute;
  font-weight: 600;
  right: 15px;
}

.about_card {
  padding: 22px 30px !important;
}

.bg_img {
  background-image: url('../img/banner/focus.png');
  background-repeat: no-repeat;
  background-position: 58% 50%;
  background-size: 230px;
}

#home_main .home_category_card {
  background-color: #fff;
  padding: 20px 10px;
  width: 100%;
  box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 20%);
}

#home_main .home_category_name {
  font-size: 16px;
  color: #444;
  text-align: center;
    margin-top: 5px;
}



#home_main .home_category_img {
  width: 40px;
  height: 45px;
  display: block;
  margin: auto;
  
}

#home_main .home_category_link:hover .home_category_name {
  color: #fe900d;
}

#home_main .carousel-item {
  
  transition-duration: 3s !important;
}

#home_main .carousel-item {
  /* height: 110px; */
}

#home_main .carousel-item img {
  /* height: 100%; */
  width: 100vw;
}




.contact_us_img_sec img {
  width: 55%;
  display: block;
  margin: auto;
}

#location_contact {
  background-color: #f8f8f8;
}

.price_sec {
  position: absolute;
  right: 0;
  top: 32%;
}

.desk_hide {
  display: none;
}

.desk_mrg {
  margin-left: 0.8rem;
}

.review-form {
  /* margin-left: 126px; */
  font-size: 22px;
}

.ad_img {
  width: 100%;
  height: 250px;
}

.ad_img img {
  width: 100%;
  height: 100%;
}

.buy_slide .carousel-inner .carousel-item img {
  width: 100%;
  height: 230px;
}

.share_img_sec {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
}

.share_img_sec img {
  width: 100%;
  height: 100%;
}

.select2-search__field::-webkit-input-placeholder {
  color: #fff;
}

#communication_tab .select2-selection__clear {
  display: none;
}

.cat_box {
  padding: 0 !important;
}

#otp_form_group {
  text-align: center;
  width: 80%;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
}

.otp {
  margin-right: 12px;
  text-align: center;
  font-size: 18px;
  padding: 4px;
}

.otp:focus, .otp:active {
  outline: none;
  box-shadow: none;
  border-color: #fb830d;
}

.flag-text { margin-left: 10px; }

@media (max-width: 767px) {

  .listing-btn {
    padding: 3px 5px !important;
    font-size: 12px !important;
    margin-top: 11px !important;
  }


  a.notification {
    padding: 2px 10px !important;
    font-size: 12px !important;
  }

  #logo img {
    width: auto;
    height: 39px;
    margin-left: -30px !important;
    margin-top: 12px !important;
  }

  .add_listing_card {
    padding: 10px;
    background-color: #f8f8f8;
    border-radius: 5px;
    box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 20%);
  }

  .add_listing_card_title {
    font-size: 12px;
    color: #444;
  }

  #mob_view {
    display: block;
  }

  #desktop_view {
    display: none;
  }

  .contact_card {
    margin: 15px 0px;
    padding: 15px;
    height: 200px;
  }

  .contact_form {
    padding: 30px;
    margin-top: 0;
  }

  .chat_img_sec {
    display: block;
    width: 80px;
    height: 80px;
    margin: auto;
  }

  .upload_btn {
    padding: 10px;
    font-size: 10px;
    margin: 5px 0 0 0;
  }

  .year_gst {
    display: block;
  }

  .strip.map_view .wrapper p {
    margin-bottom: 0;
    font-size: 11px;
  }

  .strip.map_view .wrapper p a.address {
    font-size: 12px;
  }

  .mob_reating {
    display: block;
    position: absolute;
    right: 10px;
    top: 30px;
  }

  a.grid_item {
    margin-bottom: 0;
  }

  .all_notifications .notification-list {
    margin: 5px 10px;
  }

  .all_notifications .notify_heading {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .all_notifications .notify_txt {
    font-size: 10px;
  }

  .all_notifications .small {
    margin-top: -8px;
    font-size: 9px;
  }

  .mobile_listing_btn {
    display: block;
  }

  #hide_in_mobile {
    display: none;
  }

  .notification .notification-list {
    position: relative;
    padding: 8px 73px 8px 15px;
    margin-bottom: 5px;
  }

  .notify_heading {
    font-size: 12px;
    color: #fe900d;
    margin-bottom: 5px;
  }

  .notification .nitify_dot {
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #fe900d;
    left: 5px;
    top: 12px;
  }

  .notification .small {
    font-size: 7px;
    margin-top: -3px;
  }

  .notify_txt {
    font-size: 7px;
  }

  .notify_body {
    height: 1000px;
    padding: 0;
    /* overflow: hidden; */
    margin-top: 0px;
    overflow-y: auto;
  }

  .this_count {
    right: 14px;
  }

  .vd_main {
    height: 100vh;
  }

  .owner_reply {
    margin: 20px 0 0 0;
    border-left: 2px solid #dedddd;
    padding: 0 20px;
  }

  .review-user p {
    font-size: 10px;
  }

  .review_img_sec {
    width: 55px;
    height: 55px;
    display: block;
    margin: auto;
  }

  

  .px_remove {
    padding: 0 15px;
  }

  .select_category_img_sec {
    display: block;
    margin: auto;
    width: 220px;
    height: 65px;
    margin-top: 4px;
  }

  .select_category_info h5 {
    font-size: 16px;
  }

  .select_category_info p {
    font-size: 10px;
  }

  .client_first_letter {
    font-size: 80px;
    /* margin-top: -18px; */
    color: orange;
  }

  .Count_review {
    font-size: 10px;
  }

  .abou_ul_right li:before {
    content: '✓';
    position: absolute;
    font-weight: 600;
    left: 15px;
  }

  .about_card {
    padding: 22px 15px !important;
  }

  .about_img_sec {
    width: 100%;
    height: 20vh;
  }

  .bg_img {
    background: none;
  }

  #home_main .home_category_row .col-md-2 {
    margin: 5px 0px;
  }

  #home_main .home_category_name {
    font-size: 14px;
  }

  .price_sec {
    position: absolute;
    left: 60%;
    top: 56%;
  }
  .desk_hide {
    display: block;
  }

  .desk_mrg {
    margin-left: 0rem;
  }

  .review-form {
    margin-left: 5px;
  }

  .btn_1 {
    padding: 15px 20px;
    font-size: 14px;
  }

  #otp_form_group {
    text-align: center;
    width: 80%;
    margin: auto;
    margin-top: 30px;
}

.otp {
  margin-right: 6px;
}

}

@media (max-width: 1100px) {
  .about_img_sec {
    width: 100%;
    height: 20vh;
  }

  .bg_img {
    background: none;
  }

  #home_main .home_category_name {
    font-size: 13px;
    
  }

  #home_main .home_category_img {
    width: 30px;
    height: 35px;
  }

  .desk-m {
    margin-top: 0px;
    height: 35px;
  }

  .review-form {
    margin-left: 5px;
  }


}

.tab, .tab1, .tab2, .tab3 {
  display: none;
}


/* .swiper-container .swiper-button-prev,
.swiper-container .swiper-button-next {
  display: none !important;
} */
