.link-group {
    display: flex;
    justify-content: space-between;
}

.link-group a {
    flex-grow: 1;
    margin-right: 10px; /* Adjust as needed */
}
.link-box {
    border: 1px solid #ccc;
    padding: 10px;
    /* width: 200px; 
    height: 65px; */
    width: 250px;
    height: 70px;
    margin-right: 10px; 
    margin-bottom: 10px; 
    /* text-align: center; */
    background-color:rgb(243, 242, 237);
     border-radius: 10px; 
    
}
.link-box a:hover {
    color: orange; /* Change the color to orange when hovering */
}
.link-large {
    font-size: 18px;
    color: black;
}

.tabm{
    
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 180px;
}

.selectdrp {
  /* border: 1px solid #ccc; */
  border-radius: 4px; 
  width: 330px;
  height: 50px;
}

.managelistingimg{
    width: 30px;
    height: 35px;
    margin-right: 10px;
}
.link-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

.link-box {
    flex: 0 1 30%; /* Approximately 30% width to fit three items per row */
    margin-bottom: 20px; /* Add margin for spacing */
    padding-top: 17px;
  }
  
  @media (max-width: 768px) {
    .link-box {
      flex: 0 1 48%; /* Approximately 45% width to fit two items per row on mobile */
      text-align: left;
    }
  }

.box{
    height: 50px;
    padding-left: 1px;


}

/*css for company dropdown*/
@media (max-width: 768px) {
    .selectdrp {
      width: 100%; /* Set to 100% to make it responsive */
      max-width: 411px; /* Ensure it does not exceed 330px */
    }
  }

.textareaabout {
 margin-right: 0px;
 width: 100%;
   
  }
  @media (max-width:767px) {
    .textareaabout {
      margin-right: 0px;
       width: 94%; 
       }

  }

  .labelabout{
    margin-right: 0px;
  }

  @media (max-width:767px) {
    .labelabout{
      margin-right: 11px;
    }

  }



/*css for address dropdown*/
  @media (max-width: 768px) {
    .form-group {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  
    .form-group label {
      margin-bottom: 5px;
    }
  
    .form-group select{
      width: 100%;
    }
    .form-group textarea {
      width: 100%;
      
     
    }
  
    .text-left.col-12.mt-3 {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .btn_1 {
      width: 94%;
      margin-bottom: 10px;
      /* margin-right: -57px;
      margin-left: -39px; */
      
    }
  
    .text-left.col-12.mt-3 > div {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  
    .text-left.col-12.mt-3 img {
      height: 24px;
    }
  }

  @media (max-width: 768px) {
  .form-group .localAddress-textarea {
    width: 100%;
  }
}




.popup1 {
  position: fixed;
  bottom: -100px; /* Start position outside the viewport */
  right: 20px;
  padding: 20px;
  background-color: white;

  border-radius: 5px;
  animation: slideUp 0.5s forwards; /* Slide up animation */
  z-index: 1000;
  height: 100px;
  width: 400px;
  text-align: center;
  font-size: 18px;
 
}

.popup1-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  position: relative;
}

.popup1.success {
 background-color: green;  /* Green */
 color: white;
}

.popup1.error {
  background-color: #f44336; /* Red */
  color: white;
}

@keyframes slideUp {
  to {
    bottom: 20px; /* Final position inside the viewport */
  }
}

  
.selectcompnay{
  width: 250px;
  height: 50px;
}

@media (max-width:768px) {
  .selectcompnay{
    width: 411px;
  }
}

.selectaddress{
  width: 250px;
  height: 50px;
}


@media (max-width:768px) {
  .selectaddress{
    width: 300px;
  }
}
  

/*Address custom css*/





