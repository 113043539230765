.ui-autocomplete {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 160px;
	padding: 5px 0;
	margin: 2px 0 0;
	list-style: none;
	font-size: 14px;
	text-align: left;
	background-color: #ffffff;
	border: 1px solid #cccccc;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	background-clip: padding-box;
}

.ui-autocomplete>li>div {
	display: block;
	padding: 3px 20px;
	clear: both;
	font-size: 17px;
	font-family: Arial;
	font-weight: normal;
	line-height: 1.9;
	color: #000000;
	white-space: nowrap;
}


@media (min-width:320px)  {

	.social-details.desktop{
		display: none;
	}
	.social-details.mobile{
		display: flex;
		flex-wrap: nowrap;
	}
	.social-details.mobile > .btn{
		margin: 0;
		padding: 0px 6px;
	}

	.listinggetclaim > .getclaimbtn{
		background-color: white !important;
        color: orange;
	}


	.dropdown.usericon.listing-btn.mobile-freelisting.profileimgm{
		float: right;
		right: 20px;
	}

	 /* smartphones, Android phones, landscape iPhone */ 
}
@media (min-width:600px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ }
@media (min-width:801px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media (min-width:1025px) { 
	
	.social-details.desktop{
		display: block;
	}
	
	.social-details.mobile{
		display: none;
	}
 }


.ui-state-active,
.ui-state-focus {
	text-decoration: none;
	color: #000000;
	font-family: Arial;
	font-size: 16px;
	font-weight: normal;
	background-color: #f5f5f5;
	cursor: pointer;
}

.ui-helper-hidden-accessible {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.notification img {
	height: 24px;
}

a.notification {
	display: block;
	
}

/* .usericon .usericon-btn img {
	height: 23px;
} */

.usericon .usericon-btn {
	display: block;
}

.usericon .dropdown-menu .dropdown-item:after {
	content: "" !important;
}


.usericon-btn.dropdown-toggle.buttonActive:after {
	transform: rotate(225deg) !important;
}

.usericon-btn.dropdown-toggle:after {
    border: 0px;
    border-bottom: 2px solid #999;
    border-right: 2px solid #999;
    content: '';
    display: block;
    height: 8px;
    margin-top: -5px;
    pointer-events: none;
    position: absolute;
    right: 4px;
    top: 50%;
    transform-origin: 66% 66%;
    transform: rotate(45deg); /* Downward arrow by default */
    transition: all 0.15s ease-in-out;
    width: 8px;
}

@media (max-width:767px) {
	.usericon-btn.dropdown-toggle:after {
		border: 0px;
		border-bottom: 2px solid #999;
		border-right: 2px solid #999;
		content: '';
		display: block;
		height: 8px;
		margin-top: -5px;
		pointer-events: none;
		position: absolute;
		right: -9px;
		top: 50%;
		transform-origin: 66% 66%;
		transform: rotate(45deg); /* Downward arrow by default */
		transition: all 0.15s ease-in-out;
		width: 8px;
	}
}
.usericon-btn.dropdown-toggle.open:after {
    transform: rotate(-135deg); /* Upward arrow when dropdown is open */
}


.usericon {
	padding: 7px 2px 0 0px;
	height: 25px;
}

#top_menu a.btn_add {
	background-image: linear-gradient(to right, #fe900d, #f6a816);
}

.banner-block {
	padding: 5px 0px;
	margin: 0px -5px;
}

.search {
	width: 100%;
	position: relative;
	display: flex;
}

.searchTerm {
	width: 100%;
	border: 1px solid #a4a4a4;
	border-right: none;
	padding: 5px 12px;
	height: 36px;
	border-radius: 30px 0px 0px 30px;
	outline: none;
	color: #444;
}

.searchTerm:focus {
	color: #444;
}

.searchButton {
	width: 50px;
	height: 36px;
	border: 1px solid #fe900d;
	background: #fe900d;
	text-align: center;
	color: #fff;
	border-radius: 0 25px 25px 0;
	cursor: pointer;
	font-size: 20px;
}

.banner-space {
	padding-left: 5px;
	padding-right: 5px;
	margin-bottom: 10px;
}

.usericon .dropdown-menu {
	padding: 15px 15px;
	width: 100%;
	left: -81px!important;
}

.notification .dropdown-menu {
	padding: 15px 15px;
	width: 320px;
	left: -85px!important;
	
}

.usericon .dropdown-menu .dropdown-item {
	padding: 4px 0px;
}

.login {
	display: none!important;
}

footer ul {
	margin: 0px;
}




.mim-Box {
	background-color: #fff;
	padding: 0px;
	margin: 0px;
}

.mim-HomeSideMenu {
	color: #373737;
	width: 100%;
}

.mim-HomeSideMenu ul li {
	bottom: 0;
	background-color: #fff;
	padding-left: 15px;
	font-size: 14px;
	padding-top: 10px;
	border-right: 1px solid #eeeeee;
	padding-right: 15px;
}

.mim-Heading {
	padding: 8px;
	margin-bottom: 10px;
	font-size: 22px;
	color: #fff;
	text-transform: uppercase;
	font-weight: normal;
	display: block;
	float: left;
	font-family: 'Arial Narrow', arial;
	width: 100%;
	background-color: #fe900d;
}

.banner-block .grid_item.small {
	margin: 0;
}

.mim-BoxTopMenu {
	background-color: #fff;
	margin-top: 20px;
	margin-bottom: -20px;
	height: 53px;
}

.mim-BoxTopMenu-Button {
	padding: 12px;
	font-size: 16px;
	display: inline-block;
}

.mim-Padding-0 {
	padding: 0px;
}

.mim-Margin-0 {
	margin: 0px;
}

.mim-NoDecoration {
	background-color: #f8f8f8;
	border: none;
}




.mim-BoxCatTopMost {
	border: 1px solid rgba(0, 0, 0, 0.1);
	padding-top: 10px;
	padding-bottom: 5px;
	padding-left: 5px;
	margin: 1px;
}

.mim-BoxCatTopMost li {
	line-height: 30px;
	border-bottom: 1px dotted rgba(0, 0, 0, 0.1);
}

.mim-Padding-Top-15 {
	padding-top: 15px;
}




.mim-box-list img {
	margin-right: 6px;
	margin-left: -10px;
	width: 25px;
}

.mim-box-list a {
	color: #495057;
	display: block;
}

.mim-Box-item {
	border: 1px solid #f6f6f6;
	padding: 0;
	text-align: center;
}

.mim-Box-item a {
	color: #495057;
	display: block;
	padding: 13px;
}

.mim-Box-item a p {
	margin-bottom: 15px;
}

.mim-Box-item a:hover,
.mim-Box-item a:active {
	color: #fe900d
}

.mim-Box-img {
	overflow: hidden;
	position: relative;
}

.mim-Box-img img {
	margin: auto;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	bottom: 0;
	right: 0;
}

.mim-Box-item img {
	width: 40px;
	margin-bottom: 15px;
	margin-top: 20px;
}
@media (max-width:767px) {
	.mim-Box-item img {
		width: 37px;
		margin-bottom: 10px;
		margin-top: 11px;
	}
}

.main-Box-item p {
	margin-bottom: 24px;
}

.category-featured {
	margin-bottom: 5px;
}

.category-featured .navbar {
	margin-bottom: 0;
	position: relative;
	padding: 0px;
	background-color: #fff;
	width: 100%;
}

.navbar-brand {
	width: 100%;
	position: relative;
	padding-left: 10px;
	font-size: 22px;
	font-weight: normal;
	background: #fe900d;
	margin-left: 0!important;
	text-transform: uppercase;
	margin: 0px;
}

.navbar-brand-btn {
	color: #fff;
	display: block;
	text-align: left;
	width: 100%;
}

.navbar-brand-btn:hover,
.navbar-brand-btn:active,
.navbar-brand-offer-btn:hover,
.navbar-brand-offer-btn:active {
	text-decoration: none;
	color: white;
}

.category-featured .navbar .container {
	padding: 0;
	background-color: #fff!important;
}

.navbar-brand-offer {
	position: relative;
}

.navbar-brand-offer-btn {
	display: none;
}

.navbar-brand-offer-btn:after {
	content: '';
	border: 0px;
	border-top: 2px solid #000;
	border-right: 2px solid #000;
	content: '';
	display: block;
	height: 16px;
	margin-top: -5px;
	pointer-events: none;
	position: absolute;
	right: 21px;
	top: 45%;
	transform-origin: 66% 66%;
	transform: rotate( 44deg);
	transition: all 0.15s ease-in-out;
	width: 16px;
}

.show-brand .navbar-brand-btn:after {
	content: '';
	border: 0px;
	border-top: 2px solid #fff;
	border-right: 2px solid #fff;
	content: '';
	display: block;
	height: 16px;
	margin-top: -5px;
	pointer-events: none;
	position: absolute;
	right: 16px;
	top: 19px;
	transform-origin: 66% 66%;
	transform: rotate( 44deg);
	transition: all 0.15s ease-in-out;
	width: 16px;
}
.show-brand .navbar-brand-btn.button-active:after {
    transform: rotate(135deg) !important; /* Rotate to down arrow */
}

/* show-brand .navbar-brand-btn.buttonActive:after{
	transform: rotate(225deg) !important;
} */

.navbar-brand-offer>.navbar-collapse .nav-link {
	padding: 10px 20px;
	color: #495057;
	font-size: 16px;
}

.navbar-brand-offer>.navbar-toggler {
	padding: 0px;
}

.category-list {
	background-color: #fff;
	padding: 0;
}

.brand-category-list {
	padding: 0;
	background-color: #fff;
}

.category-featured .show-brand {
	position: relative;
}

.navbar-brand-offer-list .nav-link {
	padding: 10px 16px;
	border: 0px;
	border-radius: 0;
	border-top: 2px solid transparent;
	color: #495057;
	font-size: 16px
}

.navbar-brand-offer-list .nav-link:hover,
.navbar-brand-offer-list .nav-link:active {
	border-top: 2px solid #fb830d;
}

.navbar-brand-offer-list .nav-link.active {
	border-top: 2px solid #fb830d;
}

.footer-link .links a {
	color: #fff;
	font-size: 16px;
	line-height: 22px;
}

.footer-link .links {
	margin-bottom: 12px;
	padding-top: 5px;
}

#sign-in-dialog .step .nav-tabs .nav-item {
	width: 50%;
	text-align: center;
}

#sign-in-dialog .step .nav-tabs .nav-item .nav-link {
	background-color: #e6e6e6;
	border-radius: 0px;
	padding: 7px 15px;
	height: auto;
}

#forgot_pw {
	padding: 80px 15px;
}

#sign-in-dialog .step {
	margin-bottom: 0px;
}

#sign-in-dialog .step .nav-tabs .nav-item .nav-link.active {
	background-color: #fff;
	border-bottom: 2px solid #fb830d;
	color: #444;
}

#sign-in:before {
	content: " ";
}

.bottom-link-sticky {
	display: none;
}

.sider-card {
	margin-bottom: 15px;
}

.strip ul li .business-year {
	margin-bottom: 0;
	font-weight: 600;
	font-size: 18px;
	line-height: 20px;
	font-family: "PoppinsSemiBold";
	color: #666 !important;
}

.categories-list .item {
	color: #444;
	margin-bottom: 15px;
	position: relative;
	padding: 7px 7px;
}

.categories-list .item .detail a {
	display: block;
	font-size: 16px;
	line-height: 18px;
	font-weight: 500;
	font-family: 'PoppinsMedium';
}

.categories-list .item .icon {
	width: 65px;
	text-align: center;
	background-color: #f9f9f9;
	display: block;
	/* border: 1px solid #fe900d; */
	float: left;
	margin-right: 12px;
	padding: 7px;
}

.categories-list .item .icon img {
	width: 100%;
	display: block;
}

.cust-profile {
	text-align: center;
	padding: 16px;
}

.cust-profile img {
	/* border: 1px solid #fb830d;
	border-radius: 50%; */
	margin-bottom: 12px;
	width: 103px;
}

.cust-profile .cust-name {
	margin-bottom: 0px;
	text-transform: capitalize;
}

.individual-listing {
	padding-top: 50px;
	padding-bottom: 50px;
}

.gallery a img {
	float: left;
	border: 4px solid #fff;
	-webkit-transition: -webkit-transform .15s ease;
	-moz-transition: -moz-transform .15s ease;
	-o-transition: -o-transform .15s ease;
	-ms-transition: -ms-transform .15s ease;
	transition: transform .15s ease;
	margin: auto;
	object-fit: cover;
	left: 0;
	top: 0;
	height: 110px;
	width: 42%;
	bottom: 0;
	right: 0;
}

.gallery a:hover img {
	-webkit-transform: scale(1.05);
	-moz-transform: scale(1.05);
	-o-transform: scale(1.05);
	-ms-transform: scale(1.05);
	transform: scale(1.05);
	z-index: 5;
}

.gallery a.big {
	overflow: hidden;
	position: relative;
}

.gallery a:first-child {
	overflow: hidden;
	position: relative;
}

.gallery a:nth-child(n+6) {
	overflow: hidden;
	position: relative;
	display: none;
}

.gallery a:first-child img {
	margin: auto;
	object-fit: cover;
	left: 0;
	top: 0;
	height: 220px;
	width: 16%;
	bottom: 0;
	right: 0;
}

.listing-specialisat {
	border: 1px solid #e4e4e4;
}

.listing-specialisat h6 {
	color: #333333;
	font-weight: 500;
	font-family: 'PoppinsMedium';
	padding: 14px;
	border-bottom: 1px solid #e4e4e4;
	margin-bottom: 0px;
	text-transform: uppercase;
}

.box_detail_cus {
	background-color: #fff;
	box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 10%);
	margin-bottom: 7px;
}

.listing-specialisat-list {
	padding: 14px 14px 14px 14px;
	/* height: 317px;
	height: 230px; */
	overflow: hidden;
	overflow-y: scroll;
}

.listing-specialisat-list i {
	color: #fe900d;
	font-size: 16px;
	line-height: 18px;
}

.listing-specialisat-list li {
	margin-bottom: 5px;
	text-transform: capitalize;
}
.listing-details {
	border: 3px solid #fff;
	background-color: #fff;
	display: inline-flex;
	width: 100%;
	margin-top: 3px;
}
.padding-5 {
	padding: 0px 5px;
}

.padding-all-5 {
	padding: 5px 10px;
}

.listing-details .company-details {
	display: flex;
}

.listing-details .company-details h5 {
	padding-right: 10px;
	font-weight: 600;
	font-family: 'PoppinsSemiBold';
	width: 100%;
}
/* .company-addes .company-rating {  show extra space before top rating
	margin: 0 30px;	
} */
.company-rating .cat_star {
	display: inline-block;
}
.cat_star i.active {
	color: #fe900d;
}
.cat_star i {
	color: #d0d0d0;
}

.company-addes {
	/* border-bottom: 1px solid #e4e4e4; */
	padding-bottom: 5px;
}
.mim-Address {
	padding: 0px;
    display: block;
    margin-top: 8px;
}

#ViewNumber,
#HideNumber {
 min-width: 137px;
}

.mim-Address p {
	margin-bottom: 5px;
}

.company-time {
    padding: 0px;
    margin-bottom: 7px;
}

.company-time .company-time-title {
    color: #555555;
    font-weight: 400;
    font-family: 'Poppins';
	padding: 0px;
}

.company-time .company-time-title b {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    font-family: 'PoppinsMedium';
}

.company-time .company-time-title span {
	font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    font-family: 'Poppins';
}

.btn-link:hover,
.btn-link:active {
	text-decoration: none;
}

.company-time #companytime .card-body {
	padding: 0px 10px;
}

.company-time-title.collapsed:after {
    transform: rotate(45deg);  
}

.company-time-title span {
	padding-right: 7px;
}

.company-time-title:after {
	content: '';
	transform: rotate(228deg);
	border: 0px;
    border-bottom: 2px solid #999;
    border-right: 2px solid #999;
    display: inline-block;
    height: 10px;
    pointer-events: none;
    transform-origin: 66% 66%;
    transition: all 0.15s ease-in-out;
    width: 10px;
}

.social-details a {
	font-size: 14px;
    line-height: 16px;
    padding: 4px 6px;
	margin-right: 5px;
    border: 1px solid #f7f7f7cc;
}

.listing-details .social-share {
	border-top: 1px solid #e4e4e4;
}

.social-details {
    margin-bottom: 15px;
}

.listing-details .social-share a {
    color: #c0c0c0;
    font-size: 19px;
    line-height: 18px;
    display: inline-block;
    padding: 5px 0px;
}

.company-website a {
	color: #0043ff;	
}

.company-website,
.company-establishment {
	font-size: 0.875rem;
    line-height: 14px;
}

.company-establishment p {
	margin-bottom: 0px;
}

.review-form .label.star {
	float: right;
    padding: 5px;
    font-size: 20px;
    color: #808080;
    transition: all .2s;
}

.review-form input.star {
	display: none;
}

.review-form label.icon_star {
	color: #555555;
	font-size: 18px;
    line-height: 20px;
    padding-right: 5px;
}

.review-form .stars {
	direction: rtl;
}

.review-form .btn-link {
	color: #fe900d;
	padding: 0px;
    line-height: 16px;
	font-size: 22px;
}



.company-listing-main {
	background-color: #fff;
}

.company-listing-main .banner-block {
	padding: 0;
    margin: 0px 10px;
}

.company-listing-main .company-listing-tab {
    margin: 15px 10px 0px 10px;
	padding-bottom: 15px;
}


.review-form label.icon_star:hover,
.review-form label.icon_star:hover ~ label,
.review-form input[type=radio]:checked ~ label {
    color: #fe900d;
}

.company-rating > h4 {
	font-weight: 600;
}

#RatingBar .company-progress {
	height: 5px;
	margin-top: 10px;
	width: 85%;
    margin-left: 6px;
}

#RatingBar .company-progress .progress-bar {
 background-color: #fe900d;
}

div#RatingBar {
    border-right: 2px solid #e4e4e4;
}

.review-user h6 {
	font-size: 14px;
    line-height: 16px;
    margin-bottom: 12px;
    font-weight: 400;
    font-family: 'Poppins';
}

.company-progress-star {
	min-width: 32px;
    display: block;
}


.company-listing-tab .nav-tabs .nav-item {
    background-color: #fff;
    margin-right: 2px;
	margin-bottom: 0px;
	/* border: 1px solid #fe900d; */
	color: #fe900d;
}

.company-listing-tab .nav-tabs .nav-item a {
    color: #fe900d;
    font-size: 16px;
    font-weight: 500;
    font-family: 'PoppinsMedium';
    padding: 5px 16px;
    line-height: normal;
    display: block;
    height: auto;
	border-radius: 0px;	
}

.company-listing-tab .step .nav-tabs{
	border-bottom: 2px solid #fe900d;
}

.company-listing-tab .nav-tabs .nav-item a.active {
    /* background-color: #fe900d; */
	color: #fe900d;
	border-bottom: 0px solid #fe900d;
}

.enquiry-sticky {
    position: fixed;
    top: 40%;
    right: 0px;
    writing-mode: tb-rl;
    background-color: #fe900d;
    padding: 10px 10px;
    color: #fff;
    border-bottom-left-radius: 9px;
    border-top-left-radius: 9px;
	cursor: pointer;
	text-orientation: upright;
	z-index: 99;
	font-weight: 500;
    font-family: 'PoppinsMedium';
    font-size: 14px;
	text-transform: uppercase;
}

option[value=""][disabled] {
	display: none;
  }

.listing-gallery {
	height: 234px;
}

.review-user {
	margin: 15px 0px;
}

@media (max-width: 767px) {
	.listing-btn,
	a.notification {
		/* display: none; */
	}
	.usericon {
		padding: 7px 2px 0 0px;
		height: 23px;
	}
	.footer-link .links a {
		font-size: 16px;
		line-height: 19px;
	}
	.navbar-brand-btn {
		font-size: 14px!important;
		line-height: 21px!important;
	}
	.navbar-brand-offer-btn {
		font-size: 14px!important;
		line-height: 18px!important;
		padding: 12px 10px;
	}
	.container_check {
		font-size: 12px;
		line-height: 21px;
	}
	a#forgot {
		font-size: 12px;
		line-height: 21px;
	}
	#sign-in-dialog .step .float-right {
		padding-top: 4px;
	}
	.mim-Box-item a {
		padding: 0px;
	}
	.strip.map_view .wrapper>.loc_open {
		display: none;
	}
	.banner-block {
		margin: 0px;
		padding: 15px 5px;
		margin-right: -14px !important;
	}
	.one-block {
		padding: 0px 0px!important;
	}
	.company-website {
		margin-bottom: 14px;
	}
}

@media (min-width: 576px) {
	.login-block {
		display: none;
	}
}

@media (max-width: 576px) {
	.mim-Box-item a p {
		font-size: 9px;
		padding: 3px 2px;
		display: block;
		word-break: break-word;
		margin-top: -3px;
	}
	ul#additional_links li a {
		font-size: 12px;
	}
	.bottom-link-sticky-list a {
		padding: 10px 5px;
		display: block;
		color: #444;
		font-size: 11px!important;
		text-align: center;
		font-weight: 500;
		font-family: 'PoppinsSemiBold';
	}
	.strip .wrapper h3 a {
		font-size: 15px;
		line-height: 16px;
	}
	.strip.map_view .wrapper {
		padding: 5px;
	}
	.strip.map_view .wrapper small {
		font-size: 12px;
		line-height: 14px;
		margin-top: 0px;
		margin-bottom: 6px;
	}
	.strip.map_view .listing-bottom-list {
		padding: 0px;
	}
	.listing-bottom-list .loc_open {
		font-size: 12.9px;
		line-height: 14px;
	}
	.mb-space-remove {
		padding: 0px;
	}
	.strip.map_view figure a img {
		height: 95px;
	}
	.strip.map_view figure {
		height: 92px;
	}
	.strip.map_view .listing-bottom-list li {
		padding: 0px;
		margin-right: -5px;
	}
	.strip.map_view .listing-bottom-list {
		position: absolute;
		top: 3px;
		padding: 10px 10px;
		border-top: 0px;
		/* left: calc(100% - 69%); */
		left: 141px;
		top: -39px;
	}
	.categories-list .item {
		text-align: center;
		display: block;
		padding: 0px;
	}
	.categories-list .item .icon img {
		text-align: center;
	}
	.categories-list .item .icon {
		display: block;
		/* margin: auto; */
		float: inherit;
		margin-bottom: 10px;
	}
	.categories-list .item .detail small.des {
		display: block;
	}
	.categories-list .item .detail a {
		margin-bottom: 10px;
	}
	.categories-list .item .detail {
		display: inline-block;
	}
	.main_title_2 {
		margin-bottom: 20px;
	}
	.company-details {
		display: block!important;
	}
	.listing-details .company-details h5 {
		width: 100%;
		margin-bottom: 4px;
	}
	.listing-details .company-details small {
		padding: 6px 0px;
		display: block;
	}	
	iframe#gmap_canvas {
		height: 250px!important;
	}
	.social-details a {
		margin-bottom: 5px;
	}
	.company-listing-tab .nav-tabs .nav-item a {
		font-size: 12px;
		padding: 5px;
	}
	.review-user {
		margin-top: 20px;
	}
	.review-user img.rounded-circle {
		margin-bottom: 10px;
	}
	.company-addes .company-rating {
		float: initial;
		margin: 0 15px;
	}

}

/*today*/
@media (max-width: 767px) {
	.mim-Box-item a p {
		font-size: 9px;
		padding: 3px 2px;
		display: block;
		word-break: break-word;
	}
	ul#additional_links li a {
		font-size: 12px;
	}
	.bottom-link-sticky-list a {
		padding: 10px 5px;
		display: block;
		color: #444;
		font-size: 11px!important;
		text-align: center;
		font-weight: 500;
		font-family: 'PoppinsSemiBold';
	}
	.strip .wrapper h3 a {
		font-size: 15px;
		line-height: 16px;
	}
	.strip.map_view .wrapper {
		padding: 5px;
	}
	.strip.map_view .wrapper small {
		font-size: 12px;
		line-height: 14px;
		margin-top: 0px;
		margin-bottom: 6px;
	}
	.strip.map_view .listing-bottom-list {
		padding: 0px;
	}
	.listing-bottom-list .loc_open {
		font-size: 12.9px;
		line-height: 22px;
		top:1px;
		left: 7px;
		height: 31px;
		padding-top: 7px;
	}
	.mb-space-remove {
		padding: 0px;
	}
	.strip.map_view figure a img {
		height: 95px;
	}
	.strip.map_view figure {
		height: 92px;
	}
	.strip.map_view .listing-bottom-list li {
		padding: 0px;
		margin-right: -5px;
	}
	.strip.map_view .listing-bottom-list {
		position: absolute;
		top: 3px;
		padding: 20px 1px 0px 0px;
		border-top: 0px;
		/* left: calc(100% - 69%); */
		left: 128px;
		right: 19px;
		top: -40px;
		margin-left: -138px;
		margin-right: -29px;
		bottom: 18px;
	}
	.categories-list .item {
		text-align: center;
		display: block;
		padding: 0px;
	}
	.categories-list .item .icon img {
		text-align: center;
	}
	.categories-list .item .icon {
		display: block;
		/* margin: auto; */
		float: inherit;
		margin-bottom: 10px;
	}
	.categories-list .item .detail small.des {
		display: block;
	}
	.categories-list .item .detail a {
		margin-bottom: 10px;
	}
	.categories-list .item .detail {
		display: inline-block;
	}
	.main_title_2 {
		margin-bottom: 20px;
	}
	.company-details {
		display: block!important;
	}
	.listing-details .company-details h5 {
		width: 100%;
		margin-bottom: 4px;
	}
	.listing-details .company-details small {
		padding: 6px 0px;
		display: block;
	}	
	iframe#gmap_canvas {
		height: 250px!important;
	}
	.social-details a {
		margin-bottom: 5px;
	}
	.company-listing-tab .nav-tabs .nav-item a {
		font-size: 12px;
		padding: 5px;
	}
	.review-user {
		margin-top: -6px;
	}
	.review-user img.rounded-circle {
		margin-bottom: 10px;
	}
	.company-addes .company-rating {
		float: initial;
		margin: 0 15px;
	}

}


@media (max-width: 1024px) {
	.navbar-brand-offer .navbar-toggler {
		position: absolute;
		top: 5px;
		right: 5px;
	}
	.navbar-brand-offer .navbar-collapse.show {
		position: absolute;
		top: 10px;
	}
	footer hr {
		margin: 5px 0 5px 0;
	}	
}

@media (max-width: 991px) {
	.navbar-brand-offer-list {
		display: none;
		position: absolute;
		top: 0px;
		z-index: 9;
		width: 100%;
		background-color: #fff;
	}
	.navbar-brand-offer-list.show {
		display: block;
	}
	.navbar-brand-offer-list .nav-tabs {
		display: block;
	}
	.category-list {
		/* display: inline-flex; */
	}
	.navbar-brand {
		width: 50%!important;
	}

	.navbar-brand.contractor{
		width: 60%!important;
	
	}

	.navbar-brand-offer-menu {
		width: 50%!important;
	}
	.mim-HomeSideMenu.show {
		top: 45px;
		display: block;
		position: absolute;
		z-index: 9;
	}
	.navbar-brand-offer-btn {
		display: block!important;
		color: #444;
		padding-left: 10px;
		font-size: 17px;
		line-height: 34px;
		width: 100%;
		text-align: left;
	}
	.mim-HomeSideMenu {
		display: none;
	}
	.navbar-brand-btn.active:after {
		transform: rotate( 133deg);
		top: 34%;
	}
	.navbar-brand-offer-btn.active:after {
		transform: rotate( 133deg);
		top: 34%;
	}
	.mim-HomeSideMenu ul li {
		padding-left: 21px;
	}
	.navbar-brand-offer-list .nav-link {
		padding: 5px 16px;
	}
	.block-center-footer {
		display: flex;
		justify-content: center;
	}
	ul#additional_links li a {
		font-size: 14px;
	}
	#sign-in {
		padding: 0px!important;
		background-image: linear-gradient(to right, #ffffff, #ffffff)!important;
		width: 20px;
	}
	#sign-in:before {
		content: '\0042'!important;
		font-family: 'Glyphter';
		font-size: 21px;
		font-size: 1.3125rem;
		text-indent: 0;
		position: absolute;
		left: 0;
		top: 5px;
		font-weight: normal;
		line-height: 1;
		color: #444;
	}
	.bottom-link-sticky {
		display: block;
		position: fixed;
		bottom: 0;
		background-color: #fff;
		width: 100%;
		box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 10%);
		z-index: 100000;
	}

	
	.bottom-link-sticky-list a {
		padding: 10px 5px;
		display: block;
		color: #444;
		font-size: 14px;
		text-align: center;
		font-weight: 500;
		font-family: 'PoppinsSemiBold';
	}
	.bottom-link-sticky-list a :hover,
	.bottom-link-sticky-list a :active {
		color: #fb830d;
	}
	.col-3.bottom-link-sticky-list {
		/* padding: 0px; */
	}
	footer {
		padding: -6px 0px 47px 0px;
		padding-bottom: 55px;
	}
	.listing-sidebar {
		display: none;
	}
	.listing-list {
		margin: 0px -8px;
	}
	.individual-listing-main {
		order: 1;
	}
	.individual-listing-sidebar {
		order: 2;
	}
	.gallery a:first-child img {
		width: 100%;
		height: 100%;
	}
	.gallery a:nth-child(n+2) {
		overflow: hidden;
		position: relative;
		display: none;
	}
	.listing-details {
    	width: 100%;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.company-details-list {
		order: 1;
	}
	.company-map {
		order: 2;
	}
	.individual-listing-sidebar {
		margin-top: 15px;
		margin-bottom: -53px;
	}
	.company-progress-star {
		min-width: 40px;
	}
	.review-user img.rounded-circle {
		width: 102px;
	}
	.company-listing-tab .step {
		margin-bottom: 0;
	}
	.sl-wrapper .sl-image .sl-caption {
		display: none!important;
	}
	.listing-gallery {
		height: auto!important;
	}
	.enquiry-sticky {
		display: none;
	}
}

@media screen and (max-width: 1199px) and (min-width: 992px) {

	#gmap_canvas {
		height: 100%;
	}
	.gmap_canvas {
		display: inline;
	}

	.company-progress-star {
		width: 38px;
	}
	.company-rating > h4 {
		font-size: 20px;
		padding-top: 3px;
	}
	
}

.gmap_canvas {
    display: inline;
}

iframe#gmap_canvas {
    height: 100%;
}

.card-shadow {
    padding: 22px 15px;
	box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 10%);
	background-color: #fff;
}

.card-shadow h2 {
	margin-bottom: 16px;
}

.card-shadow-sidebar {
	padding: 10px 10px;
	box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 10%);
	background-color: #fff;
}

.widget-title-list-item li a {
	color: #555555;
	font-size: 15px;
	font-weight: 500;
	font-family: "PoppinsMedium";

}

.widget-title-list-item li a:hover {
	color: #fe900d;
}

.widget-title-list-item li {
    list-style: disc;
	border-bottom: 1px solid #dddddd;	
	list-style-position: inside;
	padding: 3px 8px;
}

.widget-title-list-item li:last-child {
	border-bottom: 0px;	
}

.widget-title-list-item {
	margin: 0px;
}

.roman-list {
	list-style: lower-roman;
	list-style-position: inside;
}

.profile-side-menu {
	background: #fff;
    border: 1px solid #ededed;
    margin-bottom: 25px;
}

.profile-side-menu a {
	border-bottom: 1px solid #ededed;
	display: block;
    padding: 8px 16px;
	color: #444;
	border-radius: 0px!important;
	font-size: 15px;
}

.profile-side-menu.nav-pills .nav-link.active, .profile-side-menu.nav-pills .show>.nav-link {
	background-color: #fe900d;
	color: #fff;
	font-weight: 500;
	font-family: 'PoppinsMedium';
}

.profile-sidebar-content {
    padding: 25px;
	box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 10%);
	background-color: #fff;
}
 
.icon-form-group .form-group input{
	padding-left: 40px;
}

.icon-form-group .form-group i {
    font-size: 21px;
    font-size: 1.3125rem;
    position: absolute;
    left: 26px;
    top: 34px;
    color: #ccc;
    width: 25px;
    height: 25px;
    display: block;
    font-weight: 400 !important;
}

.add-review h5 {
    margin-bottom: 20px;
}

.offer-block .row:first-child {
    padding-top: 0px;
}

.offer-block .row:last-child {
    border-bottom: 0px solid #cdcdcd;
}

.offer-block .row {
    padding-bottom: 15px;
    border-bottom: 1px solid #cdcdcd;
    padding-top: 15px;
}

.list_general > ul > li figure img {
    width: 70px;
    height: 70px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.list_general > ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.list_general > ul > li > .li {
    margin: 0 -25px 0 -25px;
    position: relative;
    padding: 30px 30px 5px 120px;
    border-top: 1px solid #ededed;
}

.list_general > ul > li figure {
    width: 70px;
    height: 70px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    left: 30px;
    top: 30px;
}

.list_general > ul h4 {
    font-size: 21px;
    font-size: 1.3125rem;
}

.list_general ul.buttons {
    list-style: none;
    margin: 0 0 30px 0;
    padding: 0;
    position: absolute;
    top: 15px;
    right: 30px;
}

.list_general ul.buttons li {
    display: inline-block;
    margin-right: 3px;
}

.list_general a.btn_1.gray, .list_general .btn_1.gray {
    background: #e9ecef;
    color: #868e96;
}

.list_general a.btn_1,.list_general .btn_1 {
    border: none;
    color: #fff;
    background: #004dda;
    cursor: pointer;
    padding: 10px 15px;
    display: inline-block;
    outline: none;
    font-size: 13px;
    font-size: 0.8125rem;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    border-radius: 25px;
    line-height: 1;
    font-weight: 500;
}

.list_general > ul > li:hover {
    background-color: #f8f9fa;
}

.notification-list {
    background-color: #efefef;
    padding: 15px;
    margin-bottom: 15px;
    border: 1px solid gainsboro;
	/* border-radius: 12px; */
}

.notification-list p {
	margin-bottom: 0px;
}

.primary-btn-color {
    background-color: #fe900d!important;
    color: #fff!important;
}

.listing-step-item h4 {
	padding-bottom: 16px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	margin-bottom: 18px;
}

.select2-container {
	width: 100%!important;
	display: block;
}

span.select2-selection.select2-selection--single {
    border: 1px solid #d2d8dd;
	height: 45px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	padding: 7px 15px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 45px!important;
}

.add-lidting-title-from {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding-bottom: 16px;
	margin-bottom: 18px;
} 

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
	width: 100%;
    border-top: 1px solid rgb(241, 241, 241);
}


@media (max-width: 767px) {
	.notification .dropdown-menu {
		padding: 15px 15px;
		width: 230px;
		left: 0px!important;
	}

	.usericon .dropdown-menu {
		left: 0px!important;
	}
}
@media (max-width:767px) {
	.notification-list {
		background-color: #fff;
		padding: 15px;
		margin-bottom: 15px;
		border: 1px solid gainsboro;
		border-radius: 12px;
	}
}