.popup2-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .popup2-content {
  position:relative;
    background: rgb(255, 254, 254);
    padding: 20px;
    border-radius: 5px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    height: 327px;
  }
    .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
  }

  .popup2-content .success-icon {
    color: green;
    font-size: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid green;
    border-radius: 50%;
    width: 60px;
    height: 60px;
  }

  .popup2-content.success-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .popup2-content .success-message {
    color: green;
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  

  .popupbutton-container {
    display: flex;
    justify-content: center;
    margin-top: 17px;
    margin-left: 54px;
    background-color:orange;
    color: white;
    height: 50px;
    width: 347px;
  }

  @media (max-width:767px) {
    .popupbutton-container {
      display: flex;
      justify-content: center;
    
      margin-left: 24px;
      background-color:orange;
      color: white;
      height: 46px;
      width: 297px;
      margin-top: 2px;
    }
  }

  .paymentimage{
    margin-left: 190px;
  }
  @media (max-width:767px) {
    .paymentimage{
      margin-left: 153px;
    }
  }
