.icon-wrapper{
    position: absolute;
    top: 42%;
    left: 15px; /* Adjust this value to position the icon as per your design */
  transform: translateY(-50%);
  z-index: 1; /* Ensure icon is above input */
  }

  .form-control {
  padding-left: 30px; /* Ensure enough padding to not overlap with the icon */
  position: relative;
}